import React, {useState, useEffect} from "react";
import ChartExample from "../../components/ChartExample";
import DatePicker from "../../components/DatePicker";
import Header from "../../components/Header";
import API from "../../api/base_url";
import moment from 'moment-timezone';
import Swal from 'sweetalert2'
import BreadCumb from "../../components/BreadCumb";
import Footer from "../../components/Footer";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import ExportListPowerMeter from "../../components/ExportExcelPowerMeter/ExportListPowerMeter";
import ChartMultiLine from "../../components/ChartMultiLine";
import ReactSpinner from "../../components/ReactSpinner";
import Moment from 'react-moment';


  
  const BackWash = () => {
    const token = localStorage.token;
  const headers = {
    Authorization: `Bearer ${token}`,
  };


  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    background:"#c51a1a",
    color:"#ffffff",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  let start =  moment().startOf('day').utc().format("YYYY-MM-DD HH:mm:ss")
  let end = moment().endOf('day').utc().format("YYYY-MM-DD HH:mm:ss")


    const [site, setSite] = useState([]);
    const [pending, setPending] = useState(true);
    const [dateRangePickerValue, setDateRangePickerValue] = useState(`${start}/${end}`);
    const [dateRangePickerDisplay, setDateRangePickerDisplay] = useState(`${start}/${end}`);
  
    const [data, setData] = useState([])
    const [chartBackwashData, setChartBackwashData] = useState([])
    const [chartVoltage_r, setChartVoltage_r] = useState([])
    const [chartVoltage_s, setChartVoltage_s] = useState([])
    const [chartVoltage_t, setChartVoltage_t] = useState([])
    const [chartCurrent_r, setChartCurrent_r] = useState([])
    const [chartCurrent_s, setChartCurrent_s] = useState([])
    const [chartCurrent_t, setChartCurrent_t] = useState([])
    const [frequency, setFrequency] = useState([])
    const [powerFactor, setPowerFactor] = useState([])
    const [activeEnergy, setActiveEnergy] = useState([])
    const [reactiveEnergy, setReactiveEnergy] = useState([])
    const [activePower, setActivePower] = useState([])
    const [reactivePower, setReactivePower] = useState([])
    const [apparantPower, setApparantPower] = useState([])
    const [isLoading, setIsLoading] = useState(true)


    const getSite = () => {
    API.get("utility/master/data/site", { headers })
      .then(({ data: content }) => {
        setSite(content.data);
      })
      .catch((err) => {
        console.log(err);
      });
    };

    const getListData = () => {
      API.get(`report/powermeter/2/${dateRangePickerValue}`, { headers })
        .then(({ data: content }) => {
         setData(content.data.list_data)
  
         let voltage_r_Tmps = []
         let voltage_s_Tmps = []
         let voltage_t_Tmps = []
         let current_r_Tmps = []
         let current_s_Tmps = []
         let current_t_Tmps = []
         let frequencyTmps = []
         let powerFactorTmps = []
         let activeEnergyTmps = []
         let reactiveEnergyTmps = []
         let activePowerTmps = []
         let reactivePowerTmps = []
         let apparantPowerTmps = []
  
         content.data.voltage_r.report_device_value_trend.forEach(el => {
          voltage_r_Tmps.push({x:moment.utc(el.report_device_value_trend_time).local().format('DD/MM/YYYY HH:mm:ss'), y:el.report_device_value_trend_value})
         });

         content.data.voltage_s.report_device_value_trend.forEach(el => {
          voltage_s_Tmps.push({x:moment.utc(el.report_device_value_trend_time).local().format('DD/MM/YYYY HH:mm:ss'), y:el.report_device_value_trend_value})
         });

         content.data.voltage_t.report_device_value_trend.forEach(el => {
          voltage_t_Tmps.push({x:moment.utc(el.report_device_value_trend_time).local().format('DD/MM/YYYY HH:mm:ss'), y:el.report_device_value_trend_value})
         });

        //  current
        content.data.current_r.report_device_value_trend.forEach(el => {
          current_r_Tmps.push({x:moment.utc(el.report_device_value_trend_time).local().format('DD/MM/YYYY HH:mm:ss'), y:el.report_device_value_trend_value})
         });

         content.data.current_s.report_device_value_trend.forEach(el => {
          current_s_Tmps.push({x:moment.utc(el.report_device_value_trend_time).local().format('DD/MM/YYYY HH:mm:ss'), y:el.report_device_value_trend_value})
         });

         content.data.current_t.report_device_value_trend.forEach(el => {
          current_t_Tmps.push({x:moment.utc(el.report_device_value_trend_time).local().format('DD/MM/YYYY HH:mm:ss'), y:el.report_device_value_trend_value})
         });

        //  frequency
        content.data.frequency.report_device_value_trend.forEach(el => {
          frequencyTmps.push({x:moment.utc(el.report_device_value_trend_time).local().format('DD/MM/YYYY HH:mm:ss'), y:el.report_device_value_trend_value})
        });

        //  power factor
        content.data.power_factor.report_device_value_trend.forEach(el => {
          powerFactorTmps.push({x:moment.utc(el.report_device_value_trend_time).local().format('DD/MM/YYYY HH:mm:ss'), y:el.report_device_value_trend_value})
        });

        //  active_energy
        content.data.active_enegy.report_device_value_trend.forEach(el => {
          activeEnergyTmps.push({x:moment.utc(el.report_device_value_trend_time).local().format('DD/MM/YYYY HH:mm:ss'), y:el.report_device_value_trend_value})
        });

        //  reactive_energy
        content.data.reactive_enegy.report_device_value_trend.forEach(el => {
          reactiveEnergyTmps.push({x:moment.utc(el.report_device_value_trend_time).local().format('DD/MM/YYYY HH:mm:ss'), y:el.report_device_value_trend_value})
        });

        //  active_power
        content.data.active_power.report_device_value_trend.forEach(el => {
          activePowerTmps.push({x:moment.utc(el.report_device_value_trend_time).local().format('DD/MM/YYYY HH:mm:ss'), y:el.report_device_value_trend_value})
        });

        //  reactive_power
        content.data.reactive_power.report_device_value_trend.forEach(el => {
          reactivePowerTmps.push({x:moment.utc(el.report_device_value_trend_time).local().format('DD/MM/YYYY HH:mm:ss'), y:el.report_device_value_trend_value})
        });

        //  apparent_power
        content.data.apparent_power.report_device_value_trend.forEach(el => {
          apparantPowerTmps.push({x:moment.utc(el.report_device_value_trend_time).local().format('DD/MM/YYYY HH:mm:ss'), y:el.report_device_value_trend_value})
        });

        
  
        setChartVoltage_r(voltage_r_Tmps);
        setChartVoltage_s(voltage_s_Tmps);
        setChartVoltage_t(voltage_t_Tmps);
        setChartCurrent_r(current_r_Tmps);
        setChartCurrent_s(current_s_Tmps);
        setChartCurrent_t(current_t_Tmps);

        setFrequency(frequencyTmps);
        setPowerFactor(powerFactorTmps);
        setActiveEnergy(activeEnergyTmps);
        setReactiveEnergy(reactiveEnergyTmps);
        setActivePower(activePowerTmps);
        setReactivePower(reactivePowerTmps);
        setApparantPower(apparantPowerTmps);
        setPending(false)
        setIsLoading(false)
        })
        .catch((err) => {
          console.log(err);
        });
    }

    const handleFilterSubmit = () => {
      setChartVoltage_r([]);
      setChartVoltage_s([]);
      setChartVoltage_t([]);
      setChartCurrent_r([]);
      setChartCurrent_s([]);
      setChartCurrent_t([]);
      setFrequency([]);
      setPowerFactor([]);
      setActiveEnergy([]);
      setReactiveEnergy([]);
      setActivePower([]);
      setReactivePower([]);
      setApparantPower([]);
      setData([])
      getListData()
    Toast.fire({
      icon: "success",
      title: "Filter Data Request",
    });
    }

    const setDateRangePickerValueFunc = (val) => {
    setDateRangePickerValue(val)
    }

    const setDateRangePickerValueExcelFunc = (val) => {
      setDateRangePickerDisplay(val)
     }

    
    useEffect(() => {
      getSite()
      getListData()
    }, [])

  return (
    <div id="wrap">
      {/* {isLoading ? <ReactSpinner/>: */}
        <div>
          <Header/>
          <BreadCumb pageRoot="Report & Analisis" name="Power Meter" parent="/dashboard" subParent="/dashboard" underPage="Report Analisis" />
          
          <div className="container-fluid mt--6">
            <div className="card shadowTailwind">
              <div className="card-body">
                <div className="row">
                <div className="col-lg-12 d-flex">
                  <div className="col-lg-6">
                    <label className="form-control-label textPallete">Site</label>
                    <select
                      className="form-control text-darker"
                      id="channel"
                      name="channel"
                      data-toggle="select"
                      disabled
                      value={1}
                    >
                      {site.length === 0 ? (
                        <></>
                      ) : (
                        site.map((el, idx) => (
                          <option value={el.id} key={idx}>
                            {el.name}
                          </option>
                        ))
                      )}
                    </select>
                  </div>
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-lg-9">
                        <label className="form-control-label textPallete">Date Range</label>
                        <DatePicker take={(val) => setDateRangePickerValueFunc(val)} takeTimeExcel={(val) => setDateRangePickerValueExcelFunc(val)} />
                      </div>
                      <div className="col-lg-3">
                        <label className="form-control-label textPallete">&nbsp;</label> <br />
                        <button className="btn bg-pallete text-white" onClick={() => handleFilterSubmit()}>
                          <span className="fa fa-search mr-2" /> Filter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>

          {/* filter */}
          {/* <div className="container-fluid mt--3">
            <div className="card shadowTailwind">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-8">
                    <label className="form-control-label textPallete">Date Range</label>
                    <DatePicker take={(val)=> setDateRangePickerValueFunc(val)} takeTimeExcel={(val) => setDateRangePickerValueExcelFunc(val)} />
                  </div>
                  <div className="col-lg-4">
                    <label className="form-control-label textPallete">&nbsp;</label> <br />
                    <button className="btn bg-pallete text-white" onClick={()=> handleFilterSubmit()}>
                      <span className="fa fa-search mr-2" /> Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* Page content */}
          <div className="container-fluid">
            <div className="row mt-3">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="mb-0 text-red">Voltage</h4>
                    <ChartMultiLine r={["Voltage R",chartVoltage_r]} s={["Voltage S",chartVoltage_s]} t={["Voltage T",chartVoltage_t]} />
                  </div>
                  <div className="card-body">
                    <div className="mchart chartcss" />
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="mb-0 text-red">Current</h4>
                    <ChartMultiLine r={["Current R",chartCurrent_r]} s={["Current S",chartCurrent_s]} t={["Current T",chartCurrent_t]} />
                  </div>
                  <div className="card-body">
                    <div className="mchart chartcss" />
                  </div>
                </div>
              </div>

              {/* frequency */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="mb-0 text-red">Frequency</h4>
                    <ChartExample streaming={frequency} />
                  </div>
                  <div className="card-body">
                    <div className="mchart chartcss" />
                  </div>
                </div>
              </div>

              {/* power factor */}
              {/* <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="mb-0 text-red">Power Factor</h4>
                    <ChartExample streaming={powerFactor} />
                  </div>
                  <div className="card-body">
                    <div className="mchart chartcss" />
                  </div>
                </div>
              </div> */}

              {/* active_enegy */}
              {/* <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="mb-0 text-red">Active Energy</h4>
                    <ChartExample streaming={activeEnergy} />
                  </div>
                  <div className="card-body">
                    <div className="mchart chartcss" />
                  </div>
                </div>
              </div> */}

              {/* reactive_enegy */}
              {/* <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="mb-0 text-red">Re Active Energy</h4>
                    <ChartExample streaming={reactiveEnergy} />
                  </div>
                  <div className="card-body">
                    <div className="mchart chartcss" />
                  </div>
                </div>
              </div> */}

              {/* active_power */}
              {/* <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="mb-0 text-red">Active Power</h4>
                    <ChartExample streaming={activePower} />
                  </div>
                  <div className="card-body">
                    <div className="mchart chartcss" />
                  </div>
                </div>
              </div> */}

              {/* reactive_power */}
              {/* <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="mb-0 text-red">Re Active Power</h4>
                    <ChartExample streaming={reactivePower} />
                  </div>
                  <div className="card-body">
                    <div className="mchart chartcss" />
                  </div>
                </div>
              </div> */}

              {/* apparent_power */}
              {/* <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="mb-0 text-red">Apparent Power</h4>
                    <ChartExample streaming={apparantPower} />
                  </div>
                  <div className="card-body">
                    <div className="mchart chartcss" />
                  </div>
                </div>
              </div> */}

            </div>
          </div>
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                <div className="col-lg-12 mt-5">
                  <div className="d-flex justify-content-between">
                    <h4 className="text-darker">List Total Data Power Meter</h4>
                    <ExportListPowerMeter time={dateRangePickerDisplay} location={"Power Meter"} data={data}/>
                  </div>
                  {/* tes */}
                  <div className="table-responsive py-4 container-fluid">
                    <table className="table table-bordered datatables">
                      <thead className="thead-light">
                        <tr>
                          <th colSpan={1} rowSpan={2}>No</th>
                          <th colSpan={1} rowSpan={2}>Day / Date</th>
                          <th colSpan={1} rowSpan={2}>Time (Hour)</th>
                          <th colSpan={3} rowSpan={1} className="text-center "><b>Voltage</b></th>
                          <th colSpan={3} rowSpan={1} className="text-center "><b>Current</b></th>
                          <th colSpan={1} rowSpan={2} className="text-center">Frequency (Hz)</th>
                          {/* <th colSpan={1} rowSpan={2} className="text-center">Daya (Watt)</th> */}
                          <th colSpan={1} rowSpan={2} className="text-center">KWH (Watt)</th>
                          {/* <th colSpan={1} rowSpan={2} className="text-center">KVArH (Watt)</th> */}
                          <th colSpan={1} rowSpan={2} className="text-center">Keterangan</th>
                        </tr>
                        <tr>
                          <th className="">R-N</th>
                          <th className="">S-N</th>
                          <th className="">T-N</th>
                          <th className="">R-N</th>
                          <th className="">S-N</th>
                          <th className="">T-N</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data !== 0 && data.map((el, idx) => (
                          <tr>
                            <td>{idx + 1}</td>
                            <td>{moment.utc(el.date_time).local().format('DD/MM/YYYY')}</td>
                            <td>{moment.utc(el.date_time).local().format('HH:mm:ss')}</td>
                            <td className=" text-center">{el.voltage_r}</td>
                            <td className=" text-center">{el.voltage_s}</td>
                            <td className=" text-center">{el.voltage_t}</td>
                            <td className=" text-center">{el.current_r}</td>
                            <td className=" text-center">{el.current_s}</td>
                            <td className=" text-center">{el.current_t}</td>
                            <td className="text-center">{el.frequency}</td>
                            {/* <td className="text-center">{el.power_factor}</td> */}
                            <td className="text-center">{el.active_enegy}</td>
                            {/* <td className="text-center">{el.active_power}</td> */}
                            <td>-</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {/* end test */}
                </div>
              </div>
            </div>
          </div>
        </div>
      {/* } */}
    </div>
  );
};

export default BackWash;
