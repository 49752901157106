import { useState, CSSProperties } from "react";
import PuffLoader from "react-spinners/PuffLoader";



function ReactSpinner() {
  let [color, setColor] = useState("#ff0000");

  return (
    <div className="main-content" id="panel">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 loadingScreen d-flex align-items-center justify-content-center">
            <PuffLoader color={color}   size={150} />
            <h1 className="ml-5 text-red">LOADING ...</h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReactSpinner;