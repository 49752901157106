import React from "react";
import Moment from 'react-moment';
import { useStopwatch } from 'react-timer-hook';
import moment from 'moment'
import Helper from "../../utility/helper.js"



let dummy = ["Control Room", "Control Room", "Intake"];

const CardLTE = ({
  powerMeter,
  productionStatus,
  productionTime,
  IntakeArea,
  drainRinse,
  overflow,
  backwashArea,
  clearatorArea,
}) => {

  let timeStarted = new Date(productionTime);
  let getSecond = Math.floor(timeStarted.getTime() / 1000)


  let stopwatchOffset = new Date(); stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + 600);

  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: true, offsetTimestamp: stopwatchOffset });


  let isOverTime = false
  var now = moment().format("DD/MM/YYYY HH:mm:ss");

  const productionTimeCount = (timeFromDevice) => {
    var startFrom = moment.utc(timeFromDevice).local().format("DD/MM/YYYY HH:mm:ss");
    var ms = moment(now, "DD/MM/YYYY HH:mm:ss").diff(moment(startFrom, "DD/MM/YYYY HH:mm:ss"));
    let timeRunning = ms / 1000;
    isOverTime = timeRunning > 86400;
    var d = moment.duration(ms);
    var countUp = d.format("YY [years]. MM [months], DD [days] HH [hours] mm [minutes] ss [second]");
    return [countUp, isOverTime]
  }

  return (
    <>
      <div >

        <div className="row  pb-10 ">
          {/* <div className="col-lg-1">
          <div className="pt-2">
          <div className={productionStatus ? "card shadowTailwind bg-success" : "card shadowTailwind bg-danger"}>
          <div className="card-content d-flex align-items-center" style={{ minHeight: '10vh' }}>
          <div className="card-body">
          <div className="media">
          <div className="media-body text-center  p-1">
          <span className=" font-weight-bold text-center  text-white">{productionStatus ? "ON" : "OFF"}</span>
          </div>
          </div>
          </div>
          </div>
            </div>
          </div>
        </div> */}
          <div className="col-lg-3 mt--4 mt-md-0" style={{ marginBottom: -20 }}>
            <div className="pt-2">
              <div className="bg-white shadowTailwind text-primary">
                <div className="card-content" style={{ minHeight: '10vh' }}>
                  <div>
                    <div className="media">
                      <div className="media-body px-2 py-3 text-center fontTV" style={{ marginBottom: -19 }} >
                        <div className="py-3" style={{ display: 'flex', flexDirection: 'column' }}>
                          {/* <span className={productionTimeCount(productionTime)[1] ? "text-red font-weight-bold fontTV" : "fontTV text-black"}>
                          {productionTimeCount(productionTime)[0]}
                        </span> */}
                          <span className="fontTV font-weight-bold " >Production Time</span>
                          <span className={productionTimeCount(productionTime)[1] ? "text-black font-weight-bold fontTV" : "fontTV font-weight-bold text-black"}>
                            {productionTimeCount(productionTime)[0]}
                          </span>
                          {/* <span className="fontTV font-weight-bold text-red">{moment.utc(productionTime).local().format("DD/MM/YYYY HH:mm:ss")}</span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 mt--4 mt-md-0">
            <div className="pt-2">
              <div className=" bg-white shadowTailwind text-primary ">
                <div className="card-content" style={{ minHeight: '10vh' }}>
                  <div>
                    <div className="media">
                      <div className="media-body px-4 py-3 text-center fontTv">
                        {/* <h5 className={productionTimeCount(intakeArea.last_update)[1] ? "text-red font-weight-bold" : "text-black font-weight-bold"}>
                        {intakeArea.debit} m<sup>3</sup> 
                      </h5> */}
                        <div className="py-3" style={{ display: 'flex', flexDirection: 'column' }}>
                          <span className="font-weight-bold  fontTV" >Intake Area</span>
                           <span className={productionTimeCount(IntakeArea.last_update)[1] ? "" : "fontTV font-weight-bold"}>
                                                    {IntakeArea.debit} m<sup>3</sup>
                                                </span>
                          {/* <span className={productionTimeCount(intakeArea.last_update)[1] ? "text-red font-weight-bold fontTV" : "fontTV font-weight-bold text-black"}>
                            <span>{moment.utc(intakeArea.last_update).local().format("DD/MM/YYYY HH:mm:ss")}</span>
                          </span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 ">
            <div className="pt-2">
              <div className="bg-white shadowTailwind text-primary">
                <div className="card-content" style={{ minHeight: '10vh' }}>
                  <div>
                    <div className="media">
                      <div className="media-body px-2 py-3 text-center">
                        <div className="py-3" style={{ display: 'flex', flexDirection: 'column' }}>
                          <span className="font-weight-bold  fontTV ">Drain / Rinse</span>
                          <span className="fontTV font-weight-bold ">
                            {drainRinse.debit} m<sup>3</sup>
                          </span>
                          {/* <span className={productionTimeCount(drainRinse.last_update)[1] ? "text-red font-weight-bold fontTV" : "fontTV font-weight-bold text-black"}>
                            <span>{moment.utc(drainRinse.last_update).local().format("DD/MM/YYYY HH:mm:ss")}</span>
                          </span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 ">
            <div className="pt-2">
              <div className="bg-white shadowTailwind text-primary">
                <div className="card-content" style={{ minHeight: '10vh' }}>
                  <div>
                    <div className="media">
                      <div className="media-body px-3 py-3 text-center">
                        <div className="py-3" style={{ display: 'flex', flexDirection: 'column' }}>
                          <span className="font-weight-bold  fontTV ">BackWash Area</span>
                       <span className="fontTV font-weight-bold ">
                            {backwashArea.debit} m<sup>3</sup>
                          </span>
                          {/* <span className={productionTimeCount(backwashArea.last_update)[1] ? "text-red font-weight-bold fontTV" : "fontTV font-weight-bold text-black"}>
                            <span>{moment.utc(backwashArea.last_update).local().format("DD/MM/YYYY HH:mm:ss")}</span>
                          </span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="pt-2">
              <div className="bg-white shadowTailwind text-primary">
                <div className="card-content" style={{ minHeight: '10vh' }}>
                  <div>
                    <div className="media">
                      <div className="media-body px-2 py-3 text-center">
                        <div className="py-3" style={{ display: 'flex', flexDirection: 'column' }}>
                          <span className="text-black fontTV font-weight-bold  ">Over Sludge Drain</span>
                     <span className="fontTV font-weight-bold ">
                            {overflow.debit} m<sup>3</sup>
                          </span>
                          {/* <span className={productionTimeCount(overflow.last_update)[1] ? "text-red font-weight-bold fontTV" : "fontTV text-red font-weight-bold"}>
                            <span>{moment.utc(overflow.last_update).local().format("DD/MM/YYYY HH:mm:ss")}</span>
                          </span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default CardLTE;
