import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Gauge } from '@ant-design/plots';

const MeasureMeter = ({percentage}) => {
  let value = percentage / 100

  const config = {
    percent: value,
    range: {
      ticks: [0, 1 / 3, 2 / 3, 1],
      color: ['#F4664A', '#FAAD14', '#30BF78'],
    },
    indicator: {
      pointer: {
        style: {
          stroke: '#D0D0D0',
        },
      },
      pin: {
        style: {
          stroke: '#D0D0D0',
        },
      },
    },
    statistic: {
      content: {
        style: {
          fontSize: '20px',
          lineHeight: '36px',
        },
      },
    },
  };
  return <Gauge {...config}  width={200} height={200} />;
};


export default  MeasureMeter;