import React, { useState, useEffect } from "react";
import API from "../../api/base_url";
import DatePicker from "../../components/DatePicker";
import Swal from 'sweetalert2'
import BreadCumb from "../../components/BreadCumb";
import Header from "../../components/Header";
import moment from 'moment-timezone';
import ExportListAllSystem from "../../components/ExportExcelAllSystem/ExportListAllSystem";
import ReactSpinner from "../../components/ReactSpinner";




const AllSystem = () => {
  const token = localStorage.token;
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    background: "#c51a1a",
    color: "#ffffff",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  let start = moment().startOf('month').utc().format("YYYY-MM-01 00:00:00")
  let end = moment().endOf('day').utc().format("YYYY-MM-DD HH:mm:ss")
  const [site, setSite] = useState([]);
  const [dateRangePickerValue, setDateRangePickerValue] = useState(`${start}/${end}`);
  const [dateRangePickerDisplay, setDateRangePickerDisplay] = useState(`${start}/${end}`);

  const [pending, setPending] = useState(true);

  const [data, setData] = useState([])
  const [detailData, setDetailData] = useState([])
  const [isLoading, setIsLoading] = useState(true)




  const getSite = () => {
    API.get("utility/master/data/site", { headers })
      .then(({ data: content }) => {
        setSite(content.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getListData = () => {
    const [startDate, endDate] = dateRangePickerValue.split('/');
    API.get(`report/production/time/day/all?date_start=${startDate}&date_finish=${endDate}`, { headers })
      .then(({ data: content }) => {
        setData(content.data)
        setPending(false)
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getListDetailData = (id) => {
    API.get(`report/production/time/hour/${id}`, { headers })
      .then(({ data: content }) => {
        setDetailData(content.data.production_time)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleFilterSubmit = () => {
    setData([])
    getListData()
    Toast.fire({
      icon: "success",
      title: "Filter Data Request",
    });
  }

  const setDateRangePickerValueFunc = (val) => {
    setDateRangePickerValue(val)
  }

  const setDateRangePickerValueExcelFunc = (val) => {
    setDateRangePickerDisplay(val)
  }

  const getDetailHours = (id) => {
    getListDetailData(id)
  }

  useEffect(() => {
    getSite()
    getListData()
  }, [])

  return (
    <div id="wrap">
      {/* {isLoading ? <ReactSpinner/>: */}
      <div>
        <Header />
        <BreadCumb pageRoot="Report & Analisis" name="All System" parent="/dashboard" subParent="/dashboard" underPage="Report Analisis" />
        <div className="container-fluid mt--6">
          <div className="card shadowTailwind">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-12 d-flex">
                  <div className="col-lg-6">
                    <label className="form-control-label textPallete">Site</label>
                    <select
                      className="form-control text-darker"
                      id="channel"
                      name="channel"
                      data-toggle="select"
                      disabled
                      value={1}
                    >
                      {site.length === 0 ? (
                        <></>
                      ) : (
                        site.map((el, idx) => (
                          <option value={el.id} key={idx}>
                            {el.name}
                          </option>
                        ))
                      )}
                    </select>
                  </div>
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-lg-9">
                        <label className="form-control-label textPallete">Date Range</label>
                        <DatePicker take={(val) => setDateRangePickerValueFunc(val)} takeTimeExcel={(val) => setDateRangePickerValueExcelFunc(val)} />
                      </div>
                      <div className="col-lg-3">
                        <label className="form-control-label textPallete">&nbsp;</label> <br />
                        <button className="btn bg-pallete text-white" onClick={() => handleFilterSubmit()}>
                          <span className="fa fa-search mr-2" /> Filter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



        {/* page comtent */}
        <div className="row container-fluid mt-3">
          <div className="col-lg-12">
            <div className="card">
              <div className="d-flex justify-content-between card-header">
                <h4 className="text-darker">
                  All System
                </h4>
                <ExportListAllSystem time={dateRangePickerDisplay} location={"All System"} data={data} />
              </div>
              <div className="table-responsive py-4 container-fluid">
                <table className="table table-bordered datatables">
                  <thead className="thead-light">
                    <tr>
                      <th colSpan={1} rowSpan={2}  style={{ textAlign: "center", verticalAlign: "middle" }}>No</th>
                      <th colSpan={1} rowSpan={2}  style={{ textAlign: "center", verticalAlign: "middle" }}>Date </th>
                      <th colSpan={1} rowSpan={2}>Production Time<br />(Hours)</th>
                      <th colSpan={6} rowSpan={1} className="text-center "><b>Chemical Consumption</b></th>
                      <th colSpan={1} rowSpan={2} className="text-center "  style={{ textAlign: "center", verticalAlign: "middle" }}><b>Intake (m<sup>3</sup>)</b></th>
                      <th colSpan={4} rowSpan={1} className="text-center "><b>Rinse/Drain</b></th>
                      <th colSpan={2} rowSpan={1} className="text-center "><b>Distribution Of Treated Water</b></th>
                      <th colSpan={1} rowSpan={2} className="text-center" style={{ textAlign: "center", verticalAlign: "middle" }}><b>Loses Production (m3)</b></th>
                      <th colSpan={1} rowSpan={2} className="text-center "  style={{ textAlign: "center", verticalAlign: "middle" }}><b>REMARKS	</b></th>
                      {/* <th colSpan={1} rowSpan={2} className="text-center" style={{ textAlign: "center", verticalAlign: "middle" }}><b>Action</b></th> */}
                    </tr>
                    <tr>
                      <th className="text-center ">PAC<br />(Kg)</th>
                      <th className="text-center ">PPM PAC<br />(Kg)</th>
                      <th className="text-center ">Polymer<br />(KG)</th>
                      <th className="text-center ">PPM Polymer<br />(KG)</th>
                      <th className="text-center ">Na0CI<br />(KG)</th>
                      <th className="text-center ">PPM Na0CI<br />(KG)</th>
                      <th className="text-center ">Drain/Rinse<br />(m3)</th>
                      <th className="text-center ">Over Sludge Drain<br />(m3)</th>
                      <th className="text-center ">Tower I<br />(m3)</th>
                      <th className="text-center ">Tower II<br />(m3)</th>
                      <th className="text-center ">Backwash<br />(m3)</th>
                      <th className="text-center ">Reservoir<br />(m3)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data !== 0 && data.map((el, idx) => (
                      <tr key={idx}>
                        <td>{idx + 1}</td>
                        <td>{moment.utc(el.datetime).local().format('DD/MM/YYYY ')}</td>
                        <td><span className="font-weight-bold">{el.production_time}</span> </td>
                        <td className="text-left ">{el.chemical_pac_mas}</td>
                        <td className="text-left ">{el.chemical_pac_ppm}</td>
                        <td className="text-left ">{el.chemical_polymer_mas}</td>
                        <td className="text-left ">{el.chemical_polymer_ppm}</td>
                        <td className="text-left ">{el.chemical_naocl_mas}</td>
                        <td className="text-left ">{el.chemical_naocl_ppm}</td>
                        <td className="text-left ">{el.intake}</td>
                        <td className="text-left ">{el.drain}</td>
                        <td className="text-left ">{el.clearator}</td>
                        <td className="text-left ">{el.tower_1}</td>
                        <td className="text-left ">{el.tower_2}</td>
                        <td className="text-left ">{el.backwash}</td>
                        <td className="text-left ">{el.reservoir}</td>
                        <td>{el.loses}</td>
                        <td>-</td>
                        {/* <td style={{ display: "flex", justifyContent: "center" }}>
                          <button id="btn_edit" type="button" data-toggle="modal" data-target="#exampleModalUpdate" data-action="tambah" className="ml-3 btn btn-sm btn-danger" >Edit Remaks</button>
                          <button id="btn_hours" className="ml-3 btn btn-sm bg-orange  text-white " ><a href={`/reportAnalysis/productionTime/${el.id}`} className="text-white">Update</a></button>
                          <button id="btn_hours" className="ml-3 btn btn-sm bg-green  text-white " ><a href="/reportAnalysis/gethours/allSystem" className="text-white">See Detail</a></button>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* Modal Update */}
        <div className="modal my-4 fade" id="exampleModalUpdate" aria-hidden="true">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12">
                      <h4 className="modal-title mb-5" id="exampleModalLabel">
                        Update Production Time
                      </h4>
                    </div>
                    <div className="col-lg-12">
                      <form>
                        <div >
                          <div className="row no-gutters d-flex flex-column">
                            <div className="col-lg-12">
                              <form>
                                <div className="form-row fontTV ">
                                  {/*  */}
                                  <div className="col-12 d-flex my-4 p-4">
                                    <div class="col-1 fontTV">
                                      <label for="exampleInputtext1">DRAIN/RINSE</label>
                                    </div>
                                    <div className="col-11">
                                      <input type="text" class="form-control" id="exampleInputtext1" placeholder="DRAIN/RINSE" />
                                    </div>
                                  </div>
                                  {/*  */}
                                  <div className="col-12 d-flex my-4 p-4">
                                    <div class="col-1">
                                      <label for="exampleInputtext1">OVER SLUDGE DRAIN</label>
                                    </div>
                                    <br />
                                    <div className="col-11 ">
                                      <input type="text" class="form-control" id="exampleInputtext1" placeholder="OVER SLUDGE DRAIN" />
                                    </div>
                                  </div>
                                  <div className="col-12 d-flex my-4 p-4">
                                    <div class="col-1">
                                      <label for="exampleInputtext1">BACKWASH</label>
                                    </div>
                                    <div className="col-11 ">
                                      <input type="text" class="form-control" id="exampleInputtext1" placeholder="BACKWASH" />
                                    </div>
                                  </div>
                                  {/*  */}
                                  <div className="col-12 d-flex my-4 p-4">
                                  <div class="col-1">
                                    <label for="exampleInputtext1">Remarks</label>
                                  </div>
                                  <div className="col-11 ">
                                    <textarea type="text" class="form-control" id="exampleInputtext1" placeholder="Remarks" />
                                  </div>
                                </div>
                                </div>
                                <br />
                                <button type="submit" class="btn w-100 btn-success">Simpan</button>
                              </form>
                            </div>

                          </div>
                        </div>

                      </form>
                    </div>
                  </div>
                  {/* <button
                    className="btn btn-primary mt-5"
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    Close
                  </button> */}
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div id="dynamicForm" />
              </div>
            </div>
          </div>
        </div>
        {/* End of Modal Update */}
      </div>
      {/* } */}
    </div>
  )
};


export default AllSystem;
