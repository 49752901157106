import { useRef } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import moment from 'moment';
import Swal from 'sweetalert2'




export default function ExportElectricall({ time, location, data }) {
    const tableRef = useRef(null);

    const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end",
        background: "#c51a1a",
        color: "#ffffff",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });

    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: `Electrical - ${new Date().toLocaleDateString('id-ID')}`,
        sheet: "Electical"
    });

    let handleSubmit = () => {
        onDownload()

        Toast.fire({
            icon: "success",
            title: "Data has exported",
        });
    }


    return (
        <div>
            <button hidden={data.length == 0} onClick={() => handleSubmit()} className="btn bg-pallete text-white"><span className="fa fa-download mr-2" /> Export excel </button>

            <table className="table table-bordered datatables" ref={tableRef} style={{ display: 'none' }}>
                <thead className="thead-light">
                    <tr>
                        <th colSpan="17" align="center">DAILY REPORT OF WTP PRODUCTION PT. MALIGI PERMATA INDUSTRIAL ESTATE</th>
                    </tr>
                    <tr>
                        <th colSpan="17" align="center">PERIODE {time}</th>
                    </tr>
                    <tr>

                    </tr>
                    <tr>
                        <th colSpan={1} rowSpan={2}>No</th>
                        <th colSpan={1} rowSpan={2}>Day / Date</th>
                        <th colSpan={1} rowSpan={3} className="text-center "><b>Intake</b></th>
                        <th colSpan={4} rowSpan={1} className="text-center "><b>KWH</b></th>
                        <th colSpan={1} rowSpan={2} className="text-center "><b>REMARKS	</b></th>
                        <th colSpan={1} rowSpan={2} className="text-center">Nilai Ekonomis</th>
                    </tr>
                    <tr>
                        {/* <th className="text-red">Input<br />(KG)</th>
                      <th className="text-red">Output<br />(KG)</th>
                      <th className="text-red">Balance<br />(KG)</th> */}
                        <th className="">Stake 1</th>
                        <th className="">Stake 2</th>
                        <th className="">Instake</th>
                        <th className="">Total</th>
                    </tr>
                </thead>
                <tbody>
                    {data !== 0 && data.map((el, idx) => (
                        <tr>
                            <td>{idx + 1}</td>
                            {/* <td>{moment.utc(el.date_time).local().format('DD/MM/YYYY HH:mm:ss')} <button id="btn_add" type="button"  data-toggle="modal" data-target="#exampleModalUpdate" data-action="tambah" className="ml-3 btn btn-sm btn-primary" onClick={()=> getDetailHours(el.id)}>See Detail</button> </td> */}
                            <td>{moment.utc(el.date_time).local().format('DD/MM/YYYY ')} </td>
                            <td>8,1828</td>
                            <td className="">{el.chemical_polymer_input}</td>
                            <td className="">{el.chemical_polymer_output}</td>
                            <td className="">{el.chemical_polymer_balance}</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                        </tr>
                    ))}
                </tbody>
                {/* <tfoot>
            <tr>

            </tr>
            <tr>
                
            </tr>
            <tr>
            <th colSpan={2} className="text-center">Total</th>
            <th>23.548</th>
            <th>23.548</th>
            <th>23.548</th>
            <th>23.548</th>
            <th>23.548</th>
            <th>23.548</th>
            <th>23.548</th>
            <th>23.548</th>
            <th>23.548</th>
            <th>23.548</th>
            <th>23.548</th>
            </tr>
            <tr>
            <th colSpan={2} className="text-center">Average</th>
            <th>23.548</th>
            <th>23.548</th>
            <th>23.548</th>
            <th>23.548</th>
            <th>23.548</th>
            <th>23.548</th>
            <th>23.548</th>
            <th>23.548</th>
            <th>23.548</th>
            <th>23.548</th>
            <th>23.548</th>
            </tr>
        </tfoot> */}
            </table>
        </div>
    );
}
