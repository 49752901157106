import React from "react";
import ChartExample from "../../components/ChartExample";
import DatePicker from "../../components/DatePicker";
import Sidebar from "../../components/Sidebar";
import Swal from 'sweetalert2'
import BreadCumb from "../../components/BreadCumb";
import Footer from "../../components/Footer";
import Header from "../../components/Header";



const Clearator = () => {
 
  return (
    <div id="wrap">
      {/* Sidenav */}
      {/* Main content */}
      <div >
        {/* Topnav */}
        <Header/>
        {/* ENDSIDEBAR */}
        <BreadCumb pageRoot="Early Warning System" name="Clearator" parent="/dashboard" subParent="/dashboard" underPage="Early Warning" />
        
        {/* DEBUG-VIEW ENDED 2 APPPATH/Views/template/sidebar_v.php */}
        
        {/* Page content */}
        
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12 mt-5">
                <h4 className="text-red">Clearator</h4>
                <table className="table table-flush datatables">
                  <thead className="thead-light">
                    <tr>
                      <th>No</th>
                      <th>Date &amp; Time</th>
                      <th>WTP</th>
                      <th>Area</th>
                      <th>Parameter</th>
                      <th>Value</th>
                      <th>Message</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>2022-05-09 03:00</td>
                      <td>WTP 1</td>
                      <td>Clearator A</td>
                      <td>m <sup>3</sup></td>
                      <td>50</td>
                      <td>melebihi batas normal</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clearator;
