import React from "react";

const Footer = () => {
  return (
    <div className="container-fluid">
    <footer className="footer pt-0 ">
      <div className="row align-items-center justify-content-lg-between">
        <div className="col-lg-6">
          <div className="copyright text-center text-lg-left text-muted">
            © 2022
            <a href="#" className="font-weight-bold ml-1 textPallete" target="_blank">KIIC - Karawang International Industrial City</a>
          </div>
        </div>
      </div>
    </footer>
  </div>
  );
};

export default Footer;
