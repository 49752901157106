import React, { useState, useEffect } from "react";
import ChartExample from "../../components/ChartExample";
import DatePicker from "../../components/DatePicker";
import Swal from 'sweetalert2'
import BreadCumb from "../../components/BreadCumb";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import API from "../../api/base_url";
import moment from 'moment-timezone';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import ExportListIntake from "../../components/ExportExcelInatke/ExportListIntake";
import ReactSpinner from "../../components/ReactSpinner";
import Moment from 'react-moment';



const CanalIntake = () => {
  const token = localStorage.token;
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    background: "#c51a1a",
    color: "#ffffff",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  let start = moment().startOf('day').utc().format("YYYY-MM-DD HH:mm:ss")
  let end = moment().endOf('day').utc().format("YYYY-MM-DD HH:mm:ss")

  const columnsListTotalData = [
    {
      name: "No",
      cell: (row, index) => index + 1,
      width: "50px",
      // sortable: true,
    },
    {
      name: "Date & Time",
      cell: (row) => <Moment format="DD-MM-YYYY HH:mm:ss">{row.recorded_at}</Moment>,
      // sortable: true,
    },
    {
      name: "Canal Intake Name",
      cell: (row) => row.device_name,
    },
    {
      name: "Debit Value",
      cell: (row) => `${row.value} m3`,
    },
    {
      name: "Device Parameter",
      cell: (row) => row.device_parameter,
    },
  ];

  const [site, setSite] = useState([]);
  const [area, setArea] = useState([]);
  const [location, setLocation] = useState([]);
  const [dateRangePickerValue, setDateRangePickerValue] = useState(`${start}/${end}`);
  const [dateRangePickerDisplay, setDateRangePickerDisplay] = useState(`${start}/${end}`);
  const [pending, setPending] = useState(true);
  // above is header
  const [locationSelected, setLocationSelected] = useState("");

  // data
  const [data, setData] = useState([])
  const [chartClearatorData, setChartClearatorData] = useState([])
  const [isLoading, setIsLoading] = useState(true)




  // function to populated data needed
  const getSite = () => {
    API.get("utility/master/data/site", { headers })
      .then(({ data: content }) => {
        setSite(content.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getArea = () => {
    API.get("utility/master/data/area/1", { headers })
      .then(({ data: content }) => {
        setArea(content.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLocation = () => {
    API.get("utility/master/data/location/7", { headers })
      .then(({ data: content }) => {
        setLocation(content.data);
        setLocationSelected(content.data[0].id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleGetDevice = (id) => {
    if (!id) {
      setLocationSelected("");
    } else {
      setLocationSelected(id);
    }
  };

  const getListData = () => {
    API.get(`report/intake/${dateRangePickerValue}`, { headers })
      .then(({ data: content }) => {
        setData(content.data.intake_1.report_device_value_history)

        let intakeTmps = []

        content.data.intake_1.report_device_value_trend.forEach(el => {
          intakeTmps.push({ x: moment.utc(el.report_device_value_trend_time).local().format('DD/MM/YYYY HH:mm:ss'), y: el.report_device_value_trend_value })
        });


        setChartClearatorData(intakeTmps);

        setPending(false)
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleFilterSubmit = () => {
    setData([])
    setChartClearatorData([])
    getListData()
    Toast.fire({
      icon: "success",
      title: "Filter Data Request",
    });
  }

  const setDateRangePickerValueFunc = (val) => {
    setDateRangePickerValue(val)
  }

  const setDateRangePickerValueExcelFunc = (val) => {
    setDateRangePickerDisplay(val)
  }


  useEffect(() => {
    getSite();
    getArea();
    getLocation();
    getListData();
  }, []);


  return (
    <div id="wrap">
      {/* {isLoading ? <ReactSpinner /> : */}
        <div>
          <Header />
          <BreadCumb pageRoot="Report & Analisis" name="Canal Intake" parent="/dashboard" subParent="/dashboard" underPage="Report Analisis" />

          <div className="container-fluid mt--6 ">
            <div className="card shadowTailwind">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-3">
                    <label className="form-control-label textPallete">Site</label>
                    <select
                      className="form-control text-darker"
                      id="channel"
                      name="channel"
                      data-toggle="select"
                      disabled
                      value={1}
                    >
                      {site.length === 0 ? (
                        <></>
                      ) : (
                        site.map((el, idx) => (
                          <option value={el.id} key={idx}>
                            {el.name}
                          </option>
                        ))
                      )}
                    </select>
                  </div>
                  <div className="col-lg-3">
                    <label className="form-control-label textPallete">Area</label>
                    <select
                      className="form-control text-darker"
                      disabled
                      id="devices"
                      name="devices"
                      value={7}
                      data-toggle="select"
                    >
                      {area.length === 0 ? (
                        <></>
                      ) : (
                        area.map((el, idx) => (
                          <option value={el.id} key={idx}>
                            {el.name}
                          </option>
                        ))
                      )}
                    </select>
                  </div>
                  <div className="col-lg-3">
                    <label className="form-control-label textPallete">
                      Location
                    </label>
                    <select
                      className="form-control text-darker"
                      id="devices"
                      name="devices"
                      data-toggle="select"
                      value={locationSelected}
                      disabled
                      onChange={(e) => handleGetDevice(e.target.value)}
                    >
                      {location.length === 0 ? (
                        <></>
                      ) : (
                        location.map((el, idx) => (
                          <option value={el.id} key={idx}>
                            {el.name}
                          </option>
                        ))
                      )}
                    </select>
                  </div>
                <div className="col-lg-2">
                  <label className="form-control-label textPallete">Date Range</label>
                  <DatePicker take={(val) => setDateRangePickerValueFunc(val)} takeTimeExcel={(val) => setDateRangePickerValueExcelFunc(val)} />
                </div>
                <div className="col-lg-1 p-1">
                  <label className="form-control-label textPallete">&nbsp;</label> <br />
                  <button className="btn bg-pallete text-white" onClick={() => handleFilterSubmit()}>
                    <span className="fa fa-search mr-2" /> Filter
                  </button>
                </div>
                </div>
              </div>
            </div>
          </div>

          {/* filter */}
          {/* <div className="container-fluid mt--3">
            <div className="card shadowTailwind">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-8">
                    <label className="form-control-label textPallete">Date Range</label>
                    <DatePicker take={(val) => setDateRangePickerValueFunc(val)} takeTimeExcel={(val) => setDateRangePickerValueExcelFunc(val)} />
                  </div>
                  <div className="col-lg-4">
                    <label className="form-control-label textPallete">&nbsp;</label> <br />
                    <button className="btn bg-pallete text-white" onClick={() => handleFilterSubmit()}>
                      <span className="fa fa-search mr-2" /> Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* Page content */}
          <div className="container-fluid">
            <div className="row mt-3">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="mb-0 text-red">Canal Intake (Stage 1)</h4>
                    <ChartExample streaming={chartClearatorData} />
                  </div>
                  <div className="card-body">
                    <div className="mchart chartcss" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                <div className="col-lg-12 mt-5">
                  <div className="d-flex justify-content-between">
                    <h4 className="text-darker fontTv">
                      List Total Data
                    </h4>
                    <ExportListIntake time={dateRangePickerDisplay} location={locationSelected} data={data} />
                  </div>
                  <DataTableExtensions
                    exportHeaders
                    print={false}
                    export={false}
                    columns={columnsListTotalData}
                    data={data}
                    filter={false}
                  >
                    <DataTable pagination progressPending={pending} />
                  </DataTableExtensions>
                </div>
              </div>
            </div>
          </div>
        </div>
      {/* } */}
    </div>
  );
};

export default CanalIntake;
