import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom"
import API from "../api/base_url"

  


const Login = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [wrong, setWrong] = useState(false)
  const [show, setShow] = useState("password")
  const navigate = useNavigate();

  const handleShowPassword = () => {
    if (show === "password") {
      setShow("text")
    } else {
      setShow("password")
    }
  }


  const submit = () => {
    const data = {
      email,
      password
    }

    API.post('dashboard/auth/login',  data)
    .then(({data:api}) => {
      localStorage.setItem("token" ,api.data.token )
      setEmail("")
      setPassword("")
      navigate("/utility/dashboard")
    })
    .catch((err) => {
      setWrong(true)
      console.log(err)
    })
  }
  
  return (
    <>
      <div className="main-content kiic" style={{minHeight: "100vh", }}>
        {/* Header */}
        <div className="header  py-7 py-lg-8 pt-lg-9">
          <div className="container">
            <div className="header-body text-center">
              <div className="row justify-content-center">
                <div className="col-xl-5 col-lg-6 col-md-8 px-5">
                  <h1 className="text-white">Telkomsel <span style={{color: "rgb(255,0,0)", fontWeight:"bold"}}>IoT</span></h1>
                  <p className="text-lead text-white">
                    Indonesia Karawang Industrial Park
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Page content */}
        <div className="container mt-md--8 pb-5">
          <div className="row justify-content-center">
            <div className="col-lg-5 col-md-7">
              <div className="card bg-secondary border-0 mb-0">
                <div className="card-body px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <small className="textPallete">sign in with credentials</small>
                  </div>
                  <form>
                    <div className="form-group mb-3">
                      <div className="input-group input-group-merge input-group-alternative">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="ni ni-email-83" />
                          </span>
                        </div>
                        <input
                          className="form-control "
                          name="email"
                          placeholder="Email"
                          type="email"
                          value={email}
                          required
                          autoFocus
                          autoComplete=""
                          onChange={(e)=> setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="input-group input-group-merge input-group-alternative">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="ni ni-lock-circle-open" />
                          </span>
                        </div>
                        <input
                          className="form-control "
                          name="password"
                          placeholder="Password"
                          type={show}
                          autoComplete=""
                          value={password}
                          required
                          autoFocus
                          onChange={(e)=> setPassword(e.target.value)}
                        />
                      </div>
                    </div>
                  </form>
                  {
                    wrong ? <p className="text-danger"> Username atau password salah</p> : <></>
                  }
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id=" customCheckLogin"
                      type="checkbox"
                      onChange={()=> handleShowPassword()}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor=" customCheckLogin"
                    >
                      <span className="text-muted">Show Password</span>
                    </label>
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn bg-pallete text-white my-4" onClick={()=> submit()}>
                      Sign in
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
