import React from "react";
import TankCylincerSquare from "../TankCylinderSquare";
import moment from 'moment'
import Helper from "../../utility/helper.js"

const CardReservoir = ({ reservoirArea }) => {
    return (
        <div className="bg-white p-5 p-md-0 paddingTV shadowTailwind  rounded  border border-muted container-fluid ">
            <div className="row">
                <div className="col-lg-12 col-md-12">
                    <div style={{ marginTop: 12, marginBottom: 20 }} className="d-flex justify-content-between">
                        {/* <span style={{fontSize:16}}  className=" text-red font-weight-bold ">
              Reservoir
            </span> */}
                        <span style={{ fontSize: 16, position: "absolute", right: 10 }} className=" text-black font-weight-bold ">
                            {/* Total : 0 m<sup>3</sup>{" "} */}
                        </span>
                        <span style={{ fontSize: 16, position: "absolute", right: 10 }} className=" text-black font-weight-bold ">
                            Total : {reservoirArea.balance} m<sup>3</sup>{" "}
                        </span>
                    </div>

                    <div className="row ">
                        <div className="pb-5 pt-3" style={{ minHeight: '8vh' }}>
                            {reservoirArea.length !== 0 &&
                                reservoirArea.tank.map((el, idx) => (
                                    <div className="row  ">

                                        <div className="col-6 ">
                                            <div className="tv px-4  " key={idx}>
                                                <table className="table   table-borderless table-sm">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className="text-black">
                                                                <b>{el.name}</b>
                                                            </th>
                                                            <th scope="col"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {/* <tr>
                                                            <th scope="row">Averange</th>
                                                            <th>: {el.average} m<sup>3</sup> </th>
                                                        </tr> */}
                                                        <tr>
                                                            <th scope="row">Volume</th>
                                                            {/* <th>: 0 m<sup>3</sup> </th> */}
                                                            <th>: {el.volume} m<sup>3</sup> </th>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Level</th>
                                                            {/* <th>: 0 cm </th> */}
                                                                         <th>: {el.level.toLocaleString('id-ID', { minimumFractionDigits: 2 })} cm</th>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardReservoir;
