import React, { useState, useEffect } from "react";
import { Liquid } from "@ant-design/plots";


const TankCylincerSquare = ({percentage}) => {
  let value = percentage / 100

  const config = {
    percent: value,
    shape: "rect",
    outline: {
      border: 2,
      distance: 4,
    },
    wave: {
      length: 128,
    },
    pattern: {
        type: 'line',
      },
    color: '#1433e01f'
  };
  return <Liquid {...config}  width={200} height={200}/>;
};

export default TankCylincerSquare;
