import React, { useState } from "react";
import Moment from 'react-moment';
import moment from 'moment'
import { useStopwatch } from 'react-timer-hook';





const CardLTE = ({ lastUpdate, productionTime, intakeArea, drainRinse, overflow, backwashArea, clearatorArea, productionStatus }) => {
    const {
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        reset,
    } = useStopwatch({ autoStart: true });



    let isOverTime = false
    var now = moment().format('DD/MM/YYYY HH:mm:ss');

    const productionTimeCount = (timeFromDevice) => {
        var startFrom = moment.utc(timeFromDevice).local().format('DD/MM/YYYY HH:mm:ss');
        var ms = moment(now, "DD/MM/YYYY HH:mm:ss").diff(moment(startFrom, "DD/MM/YYYY HH:mm:ss"));
        let timeRunning = ms / 1000;
        isOverTime = timeRunning > 86400;
        var d = moment.duration(ms);
        var countUp = d.format('YY [Years]. MM [Months], DD [Days] HH [Hours] mm [Minutes] ss [Second]');
        return [countUp, isOverTime]
    }



    return (
        <>
            <div className="row">

                {/* <div className="col-lg-1">
          <div className="pt-2 mt-30">
            <div className={productionStatus ? "card shadowTailwind bg-success" : "card shadowTailwind bg-danger"}>
              <div className="card-content d-flex align-items-center" style={{ minHeight: '10vh' }}>
                <div className="card-body">
                  <div className="media">
                    <div className="media-body text-center  p-1">
                      <span className=" font-weight-bold text-center  text-white">{productionStatus ? "ON" : "OFF"}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

                <div className="col-lg-3 mt--4 mt-md-0">
                    <div className="pt-2">
                        <div className="card shadowTailwind text-primary">
                            <div className="card-content" style={{ minHeight: '10vh' }}>
                                <div>
                                    <div className="media">
                                        <div className="media-body px-2 py-5 text-center fontTV">
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                {/* <span className={productionTimeCount(productionTime)[1] ? "text-red font-weight-bold fontTV" : "fontTV text-black"}>
                          {productionTimeCount(productionTime)[0]}
                        </span> */}
                                                <span className="fontTV font-weight-bold " >Production Time</span>
                                                <span className={productionTimeCount(productionTime)[1] ? "text-black font-weight-bold fontTV" : "fontTV font-weight-bold text-black"}>
                                                    {productionTimeCount(productionTime)[0]}
                                                </span>
                                                {/* <span className="fontTV font-weight-bold text-red">{moment.utc(productionTime).local().format("DD/MM/YYYY HH:mm:ss")}</span> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 mt--4 mt-md-0">
                    <div className="pt-2">
                        <div className="card shadowTailwind text-primary">
                            <div className="card-content" style={{ minHeight: '10vh' }}>
                                <div>
                                    <div className="media">
                                        <div className="media-body px-2 py-5 text-center fontTV">
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                {/* <span className={productionTimeCount(productionTime)[1] ? "text-red font-weight-bold fontTV" : "fontTV text-black"}>
                          {productionTimeCount(productionTime)[0]}
                        </span> */}
                                                <span className="fontTV font-weight-bold " >Intake Area</span>
                                                <span className={productionTimeCount(intakeArea.last_update)[1] ? "fontTV font-weight-bold " : "fontTV font-weight-bold"}>
                                                    {intakeArea.debit} m<sup>3</sup>
                                                </span>
                                                {/* <span className="fontTV font-weight-bold text-red">{moment.utc(productionTime).local().format("DD/MM/YYYY HH:mm:ss")}</span> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 mt--4 mt-md-0">
                    <div className="pt-2">
                        <div className="card shadowTailwind  bg-secondary text-primary">
                            <div className="card-content" style={{ minHeight: '10vh' }}>
                                <div>
                                    <div className="media">
                                        <div className="media-body px-2 py-5 text-center">
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <br />
                                                <span className="font-weight-bold fontTV  mt--4">Drain / Rinse</span>
                                                <span className={productionTimeCount(drainRinse.last_update)[1] ? "fontTV font-weight-bold text-black" : "fontTV font-weight-bold text-black"}>
                                                    {drainRinse.debit} m<sup>3</sup>
                                                 {/* 0 m<sup>3</sup> */}
                                                </span>

                                                {/* <span className={productionTimeCount(drainRinse.last_update)[1] ? "text-red font-weight-bold fontTV" : "fontTV font-weight-bold text-black"}>
                                                    <span>{moment.utc(drainRinse.last_update).local().format("DD/MM/YYYY HH:mm:ss")}</span>
                                                </span> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 mt--4 mt-md-0">
                    <div className="pt-2">
                        <div className="card shadowTailwind bg-secondary  text-primary">
                            <div className="card-content" style={{ minHeight: '10vh' }}>
                                <div>
                                    <div className="media">
                                        <div className="media-body px-3 py-5 text-center">
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <br />
                                                <span className="font-weight-bold fontTV  mt--4">BackWash Area</span>
                                                <span className={productionTimeCount(backwashArea.last_update)[1] ? "fontTV font-weight-bold " : "fontTV font-weight-bold text-black"}>
                                                    {backwashArea.debit} m<sup>3</sup>
                                                </span>
                                                {/* <span className={productionTimeCount(backwashArea.last_update)[1] ? "text-red font-weight-bold fontTV" : "fontTV font-weight-bold text-black"}>
                                                    <span>{moment.utc(backwashArea.last_update).local().format("DD/MM/YYYY HH:mm:ss")}</span>
                                                </span> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 mt--4 mt-md-0">
                    <div className="pt-2">
                        <div className="card shadowTailwind bg-secondary  text-primary">
                            <div className="card-content" style={{ minHeight: '10vh' }}>
                                <div>
                                    <div className="media">
                                        <div className="media-body px-3 py-5 text-center">
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <br />
                                                <span className="font-weight-bold fontTV  mt--4">Overflow</span>
                                                <span className={productionTimeCount(backwashArea.last_update)[1] ? "fontTV font-weight-bold text-black" : "fontTV font-weight-bold text-black"}>
                                                    {overflow.debit} m<sup>3</sup>
                                                </span>
                                                {/* <span className={productionTimeCount(backwashArea.last_update)[1] ? "text-red font-weight-bold fontTV" : "fontTV font-weight-bold text-black"}>
                                                    <span>{moment.utc(backwashArea.last_update).local().format("DD/MM/YYYY HH:mm:ss")}</span>
                                                </span> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>
    );
};

export default CardLTE;
