import React, { useEffect, useState, useRef } from "react";
import CardTank from "../components/ComponentCard/cardTank";
import CardLTE from "../components/ComponentCard/cardLte";
import CardReservoir from "../components/ComponentCard/reservoir";
import CardElevated from "../components/ComponentCard/elaved";
import API from "../api/base_url";
import moment from 'moment'
import HeaderDashboard from "../components/HeaderDashboard";
import ReactSpinner from "../components/ReactSpinner";
import { useStopwatch } from 'react-timer-hook';



const Dashboard = () => {
    // Card Info

    const [productionTime, setProductionTime] = useState("00:00:00");
    const [intakeArea, setIntakeArea] = useState(0);
    const [drainRinse, setDrainRinse] = useState(0);
    const [overflow, setoverFlow] = useState(0);
    const [backwashArea, setBackWashArea] = useState(0);
    const [clearatorArea, setClearatorArea] = useState(0);
    //  Interface Info
    const [chemicalArea, setChemicalArea] = useState([]);
    const [reservoirArea, setReservoirArea] = useState({ balance: "", tank: [] });
    const [elevatedArea, setElevatedArea] = useState([]);
    const [success, setSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [powermeter, setpowermeter] = useState([]);
    const [lastUpdate, setlastUpdate] = useState("00:00:00");

 const totalKwh = (  powermeter.length >= 3) ? 
    (parseFloat(  powermeter[0].kwh) + parseFloat( powermeter[1].kwh) + parseFloat( powermeter[2].kwh)) : 0;
    let token = localStorage.token;
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const getData = () => {
        API.get("/utility/dashboard-usage", { headers })
            .then(({ data: content }) => {
                setProductionTime(content.data.production_time);
                setIntakeArea(content.data.intake_area);
                setBackWashArea(content.data.backwash_area);
                setDrainRinse(content.data.rinse_drain_area);
                setClearatorArea(content.data.clearator_area);
                setoverFlow(content.data.overflow);
                // for card
                setChemicalArea(content.data.chemical_area);
                setReservoirArea(content.data.reservoir_area)
                setElevatedArea(content.data.elevated_area)
                setIsLoading(false)
                setlastUpdate(content.data.last_update)
                setpowermeter(content.data.power_meter_area)
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        getData()
    }, [])


    useEffect(() => {
        const interval = setInterval(() => {
            getData()
            setSuccess(true)
            setTimeout(() => { setSuccess(false) }, 1000)
        }, 5000);


        return () => {
            clearInterval(interval)
        }
    }, [powermeter])


    let timeStarted = new Date(lastUpdate);
    let getSecond = Math.floor(timeStarted.getTime() / 1000)


    let stopwatchOffset = new Date(); stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + 600);

    const {
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        reset,
    } = useStopwatch({ autoStart: true, offsetTimestamp: stopwatchOffset });


    let isOverTime = false
    var now = moment().format("DD/MM/YYYY HH:mm:ss");

    const productionTimeCount = (timeFromDevice) => {
        var startFrom = moment.utc(timeFromDevice).local().format("DD/MM/YYYY HH:mm:ss");
        var ms = moment(now, "DD/MM/YYYY HH:mm:ss").diff(moment(startFrom, "DD/MM/YYYY HH:mm:ss"));
        let timeRunning = ms / 1000;
        isOverTime = timeRunning > 86400;
        var d = moment.duration(ms);
        var countUp = d.format("YY [years]. MM [months], DD [days] HH [hours] mm [minutes] ss [second]");
        return [countUp, isOverTime]
    }

    return (
        <div id="wrap">
            {/* {isLoading ? <ReactSpinner /> : */}
            <div className="main-content" id="panel" style={{ marginTop: '90px' }}>
                <HeaderDashboard success={success} />
                <div className="bg-primary" style={{ minHeight: 350 }}>  </div>
                <div style={{ marginTop: '-340px' }}>
                    <div className="container-fluid">
                        <CardLTE
                            productionTime={productionTime}
                            intakeArea={intakeArea}
                            drainRinse={drainRinse}
                            overflow={overflow}
                            backwashArea={backwashArea}
                        />
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 mb-2">
                            <div className=" bg-white pb-4 shadowTailwind rounded">
                                <div className="pt-2 px-4">
                                    <div>
                                        <div className="row g-2">
                                            <div className=" col-lg-4 ">

                                                {/* Last Update */}
                                                <h4 className="text-black jarakjudul">Last Update</h4>
                                                <div className="bg-white shadowTailwind py-2  mb-2 text-primary">
                                                    <div className="card-content " style={{ minHeight: '6vh' }}>
                                                        <div>
                                                            <div className="media py-4">
                                                                <div className="media-body  text-center">
                                                                    <span className="text-black fontTV font-weight-bold">
                                                                        Last Update
                                                                    </span>
                                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                                             <span className="fontTV">{moment.utc(lastUpdate).local().format('DD/MM/YYYY HH:mm:ss')}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h4 className="text-black jarakjudul">Electrical Area</h4>
                                                {/* WTP stage 1 */}
                                                {
                                                    powermeter.length === 0 ? <>No Data</> : (
                                                        powermeter.map((el, idx) => (

                                                            <div className="bg-white shadowTailwind mt-2 mb-3 py-2  text-primary">
                                                                <div className="card-content " style={{ minHeight: '10vh' }}>
                                                                    <div key={idx}>
                                                                        <div className="media">
                                                                            <div className="media-body px-2 pt-4 text-center">
                                                                                <span className="text-black fontTV font-weight-bold">
                                                                                    {el.name}
                                                                                </span>
                                                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                                    <span className="fontTV font-weight-bold text-black" >
                                                                                        {el.kwh} Kwh 
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    )
                                                }
                                                 <div className="bg-white shadowTailwind mt-2 mb-3 py-2  text-primary">
                                                                <div className="card-content " style={{ minHeight: '10vh' }}>
                                                                    <div>
                                                                        <div className="media">
                                                                            <div className="media-body px-2 pt-4 text-center">
                                                                                <span className="text-black fontTV font-weight-bold">
                                                                                    Total
                                                                                </span>
                                                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                                    <span className="fontTV font-weight-bold text-black" >
                                                                                        {totalKwh} Kwh 
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                {/* Wtp Stage 2 */}
                                                {/* <div className="bg-white  shadowTailwind my-2  text-primary">
                                                    <div className="card-content pb-1" style={{ minHeight: '8vh' }}>
                                                        <div>
                                                            <div className="media">
                                                                <div className="media-body px-2 py-4 text-center">
                                                                    <span className="text-black fontTV font-weight-bold">
                                                                        Stage 2
                                                                    </span>
                                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                        <span className="fontTV font-weight-bold text-black" >
                                                                            212  kWh
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                {/* Intake */}
                                                {/* <div className="bg-white shadowTailwind my-2  text-primary">
                                                    <div className="card-content pb-1" style={{ minHeight: '8vh' }}>
                                                        <div>
                                                            <div className="media ">
                                                                <div className="media-body px-2 pt-4  text-center">
                                                                    <span className="text-black fontTV font-weight-bold">
                                                                        Intake
                                                                    </span>
                                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                        <span className="fontTV font-weight-bold text-black" >
                                                                            212  kWh
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="bg-white shadowTailwind my-2   text-primary">
                                                    <div className="card-content p-4 " style={{ minHeight: '12vh' }}>
                                                        <div>
                                                            <div className="media  ">
                                                                <div className="media-body px-2 pt-4  text-center">
                                                                    <span className="text-black fontTV font-weight-bold">
                                                                        Total
                                                                    </span>
                                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                        <span className="fontTV font-weight-bold text-black" >
                                                                            212  kWh
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                            <div className="col-lg-4">
                                                <h4 className="text-black jarakjudul">Chemical Area</h4>
                                                {
                                                    chemicalArea.length === 0 ? (
                                                        <p>No Data</p>
                                                    ) : (
                                                        chemicalArea.map((el, idx) => (
                                                            <div key={idx}>
                                                                <CardTank
                                                                    name={el.chemical}
                                                                    balance={el.balance}
                                                                    tank={el.tank}
                                                                />
                                                            </div>
                                                        ))
                                                    )
                                                }
                                                {/* <CardTank
                                                    chemicalArea={chemicalArea}
                                                /> */}
                                            </div>
                                            {/* dummy */}
                                            <div className="col-lg-4">
                                                <h4 className="text-black jarakjudul">Elevated Area</h4>
                                                <CardElevated elevatedArea={elevatedArea} />
                                                <h4 className="text-black jarakjudul">Reservoir Area</h4>
                                                <CardReservoir reservoirArea={reservoirArea} />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* 
            } */}
        </div>
    );
};

export default Dashboard;
