import React from 'react';
import { useState, useEffect } from 'react';
import Chart from "react-apexcharts";
import ReactSpinner from './ReactSpinner'; 

const ChartExample = ({ streaming }) => {
  const [isLoading, setIsLoading] = useState(true);
  const isDataEmpty = streaming.length === 0; 

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500); 

    return () => clearTimeout(timer);
  }, []);

  let series = [{ data: streaming }];
  let options = {
    noData: {
      text: 'NO DATA TO DISPLAY',
      align: 'center',
      verticalAlign: 'middle',
      style: {
        color: "red",
        fontSize: '24px',
        fontFamily: 'Arial, sans-serif'
      },
    },
    colors: ['#3f285e', '#E91E63', '#3f285e'],
    chart: {
      id: "basic-bar",
      stacked: false,
      zoom: false,
      height: 750,
      type: "line",
      xaxis: {
        type: 'datetime',
      },
    },
    xaxis: {
      type: 'category'
    },
    stroke: {
      curve: 'smooth',
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: {
        fontSize: "12px",
        colors: [
          function({ seriesIndex, dataPointIndex, w }) {
            if (streaming.length < 2) {
              return "green";
            } else {
              if (w.config.series[seriesIndex].data[dataPointIndex].y > 0) {
                return "green";
              } else {
                return "#ff0014";
              }
            }
          },
        ]
      }
    },
  };

  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart" style={{ minWidth: '98%' }}>
          {isLoading ? (
            <ReactSpinner />
          ) : (
            <Chart options={options} series={series} type="line" width="100%" height={300} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ChartExample;