import React, {useState, useEffect} from 'react'
import { Link, useNavigate } from "react-router-dom"
import API from "../api/base_url";


const HeaderSearch = () => {
  const history = useNavigate();
  const [displayName, setDisplayName] = useState("") 
  const [displayPhoto, setDisplayPhoto] = useState("") 

  
    let token = localStorage.token;
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    useEffect(() => {
      API.get("dashboard/account/profile", { headers })
      .then(({ data:content }) => {
        setDisplayName(content.data.name)
        setDisplayPhoto(content.data.photo)
      })
      .catch((err) => {
        console.log(err);
      });
    }, []);

  const logout = () => {
    localStorage.removeItem('allow')
      history('/login');
  }
  return (
    <nav className="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
      <div className="container-fluid">
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          {/* Search form */}
          <form className="navbar-search navbar-search-light form-inline mr-sm-3" id="navbar-search-main">
            <div className="form-group mb-0">
              <div className="input-group input-group-alternative input-group-merge">
                <div className="input-group-prepend">
                  <span className="input-group-text"><i className="fas fa-search" /></span>
                </div>
                <input className="form-control" placeholder="Search" type="text" />
              </div>
            </div>
            <button type="button" className="close" data-action="search-close" data-target="#navbar-search-main" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </form>
          {/* Navbar links */}
          <ul className="navbar-nav align-items-center ml-md-auto">
            <li className="nav-item d-xl-none">
              {/* Sidenav toggler */}
              <div className="pr-3 sidenav-toggler sidenav-toggler-dark" data-action="sidenav-pin" data-target="#sidenav-main">
                <div className="sidenav-toggler-inner">
                  <i className="sidenav-toggler-line" />
                  <i className="sidenav-toggler-line" />
                  <i className="sidenav-toggler-line" />
                </div>
              </div>
            </li>
          </ul>
          <ul className="navbar-nav align-items-center ml-auto ml-md-0">
            <li className="nav-item dropdown">
              <a className="nav-link pr-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <div className="media align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img alt="i" src={displayPhoto} />
                    {/* <img alt="i" src="/assets/img/theme/team-4.jpg" /> */}
                    {/* <img alt="Image placeholder" src="http://localhost:3000/assets/img/theme/team-4.jpg" /> */}
                  </span>
                  <div className="media-body ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">Superadmin</span>
                  </div>
                </div>
              </a>
              <div className="dropdown-menu dropdown-menu-right">
                <div className="dropdown-header noti-title">
                  <h6 className="text-overflow m-0">Welcome! {displayName}</h6>
                </div>
                <Link to="/account/myProfile" className="dropdown-item">
                  <i className="ni ni-single-02" />
                  <span>My profile</span>
                </Link>
                <div className="dropdown-divider" />
                <a onClick={()=> logout()} className="dropdown-item">
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default HeaderSearch