import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import BreadCumb from "../../components/BreadCumb";
import Footer from "../../components/Footer";
import TankGaugeVolume from "../../components/TankGaugeVolume";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import API from "../../api/base_url";
import Swal from "sweetalert2";
import MeasureMeter from "../../components/MeasureMeter";
import TankCylincerSquare from "../../components/TankCylinderSquare";
import Moment from "react-moment";
import Helper from "../../utility/helper.js"
import moment from 'moment'
import ReactSpinner from "../../components/ReactSpinner";


const ElevatedTank = () => {
  const [site, setSite] = useState([]);
  const [area, setArea] = useState([]);
  const [location, setLocation] = useState([]);
  // above is tmp for each filter
  const [dailyData, setDailyData] = useState([]);
  const [listTotalData, setListTotalData] = useState([]);
  const [tank, setTank] = useState([]);
  const [isLoading, setIsLoading] = useState(true)


  const token = localStorage.token;
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  // function to populated data needed
  const getSite = () => {
    API.get("utility/master/data/site", { headers })
      .then(({ data: content }) => {
        setSite(content.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getArea = () => {
    API.get("utility/master/data/area/1", { headers })
      .then(({ data: content }) => {
        setArea(content.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLocation = () => {
    API.get(`utility/master/data/location/2`, { headers })
      .then(({ data: content }) => {
        setLocation(content.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };



  const getDailyData = () => {
    API.get("utility/elevated", { headers })
      .then(({ data: content }) => {
        setDailyData(content.data.list_data);
        setTank(content.data.tank);
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getListTotalData = () => {
    API.get("utility/canal/intake/report", { headers })
      .then(({ data: content }) => {
        setListTotalData(content.data);
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // end of function to populated data needed

  // Location Result in Filter


  useEffect(() => {
    getSite();
    getArea();
    getLocation();
    getDailyData();
    getListTotalData();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getDailyData();
      getListTotalData();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [dailyData]);



  const columnsListDataLevelAndPump = [
    {
      name: "No",
      cell: (row, index) => index + 1,
      width: "50px",
    },
    {
      name: "Name Elevated Tower",
      cell: (row) => row.name,
    },
    {
      name: "Data & Time",
      cell: (row) => <Moment format="DD-MM-YYYY HH:mm:ss">{row.recorded_at}</Moment>,
    },
    {
      name: "Level",
      cell: (row) => `${row.level} cm`,
    },
    {
      name: "Status Pump",
      cell: (row) => (row.status_pump ? "ON" : "OFF"),
    },
  ];

  return (
    <div id="wrap">
      {/* {isLoading ? <ReactSpinner /> : */}
      <div>
        <Header />
        <BreadCumb
          pageRoot="Dashboard"
          name="Elevated Tank"
          parent="/dashboard"
          subParent="/dashboard"
          underPage="Utility"
        />
        <div className="container-fluid mt--6">
          {/* <div className="card shadowTailwind"> */}
          <div className="row d-flex flex-row">

            <div className="col-lg-12 bg-white shadowTailwind my-4">
              <div className="card-body">
                <label className="form-control-label textPallete">
                  Area{" "}
                </label>
                <select
                  className="form-control text-darker"
                  id="channel"
                  name="channel"
                  data-toggle="select"
                  disabled
                >
                  <option value={"WTP 1"}>
                    WTP 1
                  </option>
                </select>
              </div>

            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card shadowTailwind">
                {/* Card header */}
                <div className="card-header">
                  <h4 className="mb-0 text-darker">Level of Elevated Tower</h4>
                </div>
                <div className="card-body">
                  <div className="row">
                    {tank.length === 0
                      ? ""
                      : tank.map((el, idx) => (
                        <div className="col-lg-6" key={idx}>
                          <div className="card card-stats">
                            <div className="d-flex flex-column" key={idx}>
                              <div
                                className="d-flex flex-row"
                                style={{ justifyContent: "space-around" }}
                              >
                                <div className="position-relative">
                                  <TankCylincerSquare
                                    percentage={el.percentage}
                                  />
                                  <div className="position-absolute top-0 right-0">
                                    <span className="font-weight-bold">
                                      <small>MAX  <br /> {el.level_max} </small>
                                    </span>
                                  </div>
                                  <div className="position-absolute bottom-0 right-0">
                                    <span className="font-weight-bold">
                                      <small>MIN</small>
                                    </span>
                                  </div>
                                </div>

                                <div className="p-2">
                                  <table className="table table-borderless table-sm">
                                    <thead>
                                      <tr>
                                        <th scope="col" className="text-red">
                                          <b>{el.name}</b>
                                        </th>
                                        <th scope="col"></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <th scope="row">Flowrate</th>
                                        <th>{el.flow_rate} m<sup>3</sup>/h </th>
                                      </tr>
                                      <tr>
                                        <th scope="row">Totalizer</th>
                                        <th>{el.totalizer} m<sup>3</sup> </th>
                                      </tr>
                                      <tr>
                                        <th scope="row">Level</th>
                                        <th>{el.level} cm</th>
                                      </tr>
                                      <tr>
                                        <th scope="row">Pump Elevated</th>
                                        <th className={el.status_pump ? "text-success font-weight-bold" : "text-red font-weight-bold"}> {el.status_pump ? "ON" : "OFF"} </th>
                                      </tr>
                                      <tr>
                                        <th scope="row">Last Update</th>
                                        <th>
                                          <span className={Helper(el.last_update)[1] ? "text-red font-weight-bold" : "text-success"}>
                                            {moment.utc(el.last_update).local().format('DD/MM/YYYY HH:mm:ss')}
                                          </span>
                                        </th>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}



                    <div className="col-lg">
                      <h4 className="text-darker">List Data Level and Pump </h4>
                      <DataTableExtensions
                        print={false}
                        export={false}
                        exportHeaders
                        columns={columnsListDataLevelAndPump}
                        data={dailyData}
                        filter={false}
                      >
                        <DataTable pagination />
                      </DataTableExtensions>
                      {dailyData.length !== 0 ? (
                        <span className="badge badge-warning mt-3">
                          NB : Data ini akan direset 24 jam
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* } */}
    </div>
  );
};

export default ElevatedTank;
