import React, { useState, useEffect } from "react";
import { Liquid } from "@ant-design/plots";


const TankCylincerSquare = ({ percentage, name }) => {
  let value = percentage / 100;
  let labelTank = name

  const config = {
    percent: value,
    shape: "rect",
    outline: {
      border: 1,
      distance: 1,
    },
    wave: {
      length: 128,
    },
    pattern: {
      type: 'line',
    },
    color: '#1433e01f',
    label: {
      style: {
        fill: 'black',
        opacity: 0.6,
        fontSize: 12,
        fontWeight: 900,
      },
      // display: 'absolute',
      position: 'bottom',
      // top: 0,
      // padding:2,
      content: labelTank
    },
  };
  return <Liquid {...config} autoFit />;
};

export default TankCylincerSquare;
