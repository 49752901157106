import React, { useState, useEffect } from "react";
import API from "../../../api/base_url";
import DatePicker from "../../../components/DatePicker";
import Footer from "../../../components/Footer";
import Swal from 'sweetalert2'
import BreadCumb from "../../../components/BreadCumb";
import Header from "../../../components/Header";
import moment from 'moment-timezone';
import ExportListAllSystem from "../../../components/ExportExcelAllSystem/ExportListAllSystem";
import ExportListStockOpname from "../../../components/ExportExcelStockopname/ExportListStockOpname";
import ReactSpinner from "../../../components/ReactSpinner";



const Elektrik = () => {
    const token = localStorage.token;
    const headers = {
        Authorization: `Bearer ${token}`
    };

    const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end",
        background: "#c51a1a",
        color: "#ffffff",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });

    let start = moment().startOf('day').utc().format("YYYY-MM-DD HH:mm:ss")
    let end = moment().endOf('day').utc().format("YYYY-MM-DD HH:mm:ss")

    const [site, setSite] = useState([]);
    const [dateRangePickerValue, setDateRangePickerValue] = useState(`${start}/${end}`);
    const [dateRangePickerDisplay, setDateRangePickerDisplay] = useState(`${start}/${end}`);
    const [pending, setPending] = useState(true);
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState([])
    const [detailData, setDetailData] = useState([])

    const getDetailHours = (id) => {
        getListDetailData(id)
    }
    const setDateRangePickerValueFunc = (val) => {
        setDateRangePickerValue(val)
    }

    const handleFilterSubmit = () => {
        setData([])
        getListData()
        Toast.fire({
            icon: "success",
            title: "Filter Data Request",
        });
    }
    const getListData = () => {
        API.get(`report/stock/opname/day/${dateRangePickerValue}`, { headers })
            .then(({ data: content }) => {
                setData(content.data.stock_opname)
                setPending(false)
                setIsLoading(false)
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const setDateRangePickerValueExcelFunc = (val) => {
        setDateRangePickerDisplay(val)
    }
    const getListDetailData = (id) => {
        API.get(`report/stock/opname/hour/${id}`, { headers })
            .then(({ data: content }) => {
                setDetailData(content.data.production_time)
            })
            .catch((err) => {
                console.log(err);
            });
    }
    const getSite = () => {
        API.get("utility/master/data/site", { headers })
            .then(({ data: content }) => {
                setSite(content.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };


    useEffect(() => {
        getSite()
        getListData()
    }, [])
    const email = "adminkiic@universal-iot.com";
    const shouldDisplayAction = token === "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdXRob3JpemVkIjp0cnVlLCJ1c2VyX2lkIjoyMX0.OHGPn64e3ZuZjuS7Cab6OA4-J4InZvtbalrTlEeP52w"
        && email === "adminkiic@universal-iot.com";
    return (
        <div id="wrap">
            {/* {isLoading ? <ReactSpinner/>: */}
            <div>
                <Header />
                <BreadCumb pageRoot="Elektrik" name="Electrical" parent="/dashboard" subParent="/dashboard" underPage="Electrical" />
                {/* <div className="container-fluid mt--6">
                    <div className="card shadowTailwind">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-12 d-flex">
                                    <div className="col-lg-6">
                                        <label className="form-control-label textPallete">Site</label>
                                        <select
                                            className="form-control text-darker"
                                            id="channel"
                                            name="channel"
                                            data-toggle="select"
                                            disabled
                                            value={1}
                                        >
                                            {site.length === 0 ? (
                                                <></>
                                            ) : (
                                                site.map((el, idx) => (
                                                    <option value={el.id} key={idx}>
                                                        {el.name}
                                                    </option>
                                                ))
                                            )}
                                        </select>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="row">
                                            <div className="col-lg-9">
                                                <label className="form-control-label textPallete">Date Range</label>
                                                <DatePicker take={(val) => setDateRangePickerValueFunc(val)} takeTimeExcel={(val) => setDateRangePickerValueExcelFunc(val)} />
                                            </div>
                                            <div className="col-lg-3">
                                                <label className="form-control-label textPallete">&nbsp;</label> <br />
                                                <button className="btn bg-pallete text-white" onClick={() => handleFilterSubmit()}>
                                                    <span className="fa fa-search mr-2" /> Filter
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
 */}


                <div className="row container-fluid mt-3">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="d-flex justify-content-between card-header">
                                <h4 className="text-darker">
                                    Electrical
                                </h4>
                                {/* <ExportListStockOpname time={dateRangePickerDisplay} location={"Stock Opname"} data={data} /> */}
                            </div>
                            <div className="table-responsive py-4 container-fluid">
                                <table className="table table-bordered datatables">
                                    <thead className="thead-light">
                                        <tr>
                                            <th colSpan={1} rowSpan={2}>No</th>
                                            <th colSpan={1} rowSpan={2}>Day / Date</th>
                                            <th colSpan={1} rowSpan={3} className="text-center "><b>Intake</b></th>
                                            <th colSpan={4} rowSpan={1} className="text-center "><b>KWH</b></th>
                                            <th colSpan={1} rowSpan={2} className="text-center "><b>REMARKS	</b></th>
                                            <th colSpan={1} rowSpan={2} className="text-center">Nilai Ekonomis</th>
                                            {shouldDisplayAction && (
                                                <th colSpan={1} rowSpan={2} className="text-center">
                                                    <b>Action</b>
                                                </th>
                                            )}
                                        </tr>
                                        <tr>
                                            {/* <th className="text-red">Input<br />(KG)</th>
                      <th className="text-red">Output<br />(KG)</th>
                      <th className="text-red">Balance<br />(KG)</th> */}
                                            <th className="">Stake 1</th>
                                            <th className="">Stake 2</th>
                                            <th className="">Instake</th>
                                            <th className="">Total</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data !== 0 && data.map((el, idx) => (
                                            <tr>
                                                <td>{idx + 1}</td>
                                                {/* <td>{moment.utc(el.date_time).local().format('DD/MM/YYYY HH:mm:ss')} <button id="btn_add" type="button"  data-toggle="modal" data-target="#exampleModalUpdate" data-action="tambah" className="ml-3 btn btn-sm btn-primary" onClick={()=> getDetailHours(el.id)}>See Detail</button> </td> */}
                                                <td>{moment.utc(el.date_time).local().format('DD/MM/YYYY ')} </td>
                                                <td>8,1828</td>
                                                <td className="">{el.chemical_polymer_input}</td>
                                                <td className="">{el.chemical_polymer_output}</td>
                                                <td className="">{el.chemical_polymer_balance}</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>

                                                {shouldDisplayAction && (
                                                    <td>
                                                        <button id="btn_edit" type="button" data-toggle="modal" data-target="#exampleModalUpdate" data-action="tambah" className="ml-3 btn btn-sm btn-danger" ><a href="./gethours/stockopname.jsx"></a>Update Data</button>
                                                        {/* <button id="btn_hours" className="ml-3 btn btn-sm bg-green text-white" >See Detail</button> */}
                                                    </td>
                                                )}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Modal Update */}
                <div className="modal my-4 fade" id="exampleModalUpdate" aria-hidden="true">
                    <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <h4 className="modal-title mb-5" id="exampleModalLabel">
                                                Update Data
                                            </h4>
                                        </div>
                                        <div className="col-lg-12">
                                            <form>
                                                <div >
                                                    <div className="row no-gutters d-flex fontTV">
                                                        <div class="col-1">
                                                            <label for="exampleFormControlSelect1">REMARKS	</label>
                                                        </div>
                                                        <div class="col-11">
                                                            <textarea type="text" class="form-control" placeholder="REMARKS	" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <br />
                                                <button type="submit" class="btn btn-success w-100">Simpan</button>
                                            </form>
                                        </div>
                                    </div>
                                    {/* <button
                    className="btn btn-primary mt-5"
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    Close
                  </button> */}
                                </div>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div id="dynamicForm" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End of Modal Update */}
            </div>
            {/* } */}
        </div>

    )
};
export default Elektrik;
