import React from "react";
import Moment from 'react-moment';
import { useStopwatch } from 'react-timer-hook';
import moment from 'moment'




let dummy = ["Control Room", "Control Room", "Intake"];

const CardLTE = ({
  lastUpdate,
  productionTime,
  intakeArea,
  drainRinse,
  overflow,
  backwashArea,
  clearatorArea,
}) => {

  let timeStarted = new Date(productionTime);
  let getSecond = Math.floor(timeStarted.getTime() / 1000)


  let stopwatchOffset = new Date(); stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + 600);

  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: true, offsetTimestamp: stopwatchOffset });

  let isOverTime = false
  var now = moment().format('DD/MM/YYYY HH:mm:ss');

  const productionTimeCount = (timeFromDevice) => {
    var startFrom = moment.utc(timeFromDevice).local().format('DD/MM/YYYY HH:mm:ss');
    var ms = moment(now, "DD/MM/YYYY HH:mm:ss").diff(moment(startFrom, "DD/MM/YYYY HH:mm:ss"));
    let timeRunning = ms / 1000;
    isOverTime = timeRunning > 600;
    var d = moment.duration(ms);
    var countUp = d.format('YY [years]. MM [months], DD [days] HH [hours] mm [minutes] ss [second]');
    return [countUp, isOverTime]
  }

  return (
    <>
      <div className="row">
        {/* <div className="col-lg-1">
          <div className="pt-2">
            <div className="card  bg-success ">
              <div className="card-content d-flex align-items-center" style={{minHeight:'10vh'}}>
                <div className="card-body">
                  <div className="media">
                    <div className="media-body text-center   p-1">
                      <span className=" font-weight-bold text-white">ON</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="col-lg-6 mt--4 mt-md-0">
          <div className="pt-2">
            <div className="bg-secondary shadowTailwind    text-primary">
              <div className="card-content" style={{ minHeight: '10vh' }}>
                <div>
                  <div className="media">
                    <div className="media-body pr-3 py-2 text-center">
                      <div className="py-4" style={{ display: 'flex', flexDirection: 'column' }}>
                        <span className="text-black font-weight-bold fontTV" >Production Time</span>
                        {/* <span className="fontTV">{moment.utc(productionTime).local().format('DD/MM/YYYY HH:mm:ss')}</span> */}
                        <span className={productionTimeCount(productionTime)[1] ? "fontTV font-weight-bold" : "fontTV text-black"}>
                          {productionTimeCount(productionTime)[0]}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="pt-2">
            <div className="bg-secondary shadowTailwind   text-primary">
              <div className="card-content " style={{ minHeight: '10vh' }}>
                <div>
                  <div className="media">
                    <div className="media-body px-6 py-2 text-center">
                      <div className="py-4 " style={{ display: 'flex', flexDirection: 'column' }}>
                        <span className="text-black font-weight-bold fontTV" >Last Update</span>
                        <span className="fontTV">{moment.utc(lastUpdate).local().format('DD/MM/YYYY HH:mm:ss')}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  );
};

export default CardLTE;
