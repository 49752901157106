import React, { useState, useEffect  } from "react";
import Header from "../../components/Header";
import BreadCumb from "../../components/BreadCumb";
import API from "../../api/base_url";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import ReactSpinner from "../../components/ReactSpinner";
import Moment from 'react-moment';




const token = localStorage.token;
const headers = {
  Authorization: `Bearer ${token}`,
};

const ChemicalArea = () => {
  const [site, setSite] = useState([]);
  const [area, setArea] = useState([]);
  const [location, setLocation] = useState([]);
  const [idRender, setIdRender] = useState(4)
  const [locationSelected, setLocationSelected] = useState(""); 
  const [listData, setListData] = useState([]);
  const [pending, setPending] = useState(true);
  const [isLoading, setIsLoading] = useState(true)


  const columnsListData = [
    {
      name: "No",
      cell: (row, index) => index + 1,
      width: "50px",
    },
    {
      name: "Date & Time",
      cell: (row) => <Moment format="DD-MM-YYYY HH:mm:ss">{row.date_time}</Moment>,
    },
    {
      name: "WTP",
      cell: (row) => row.site_name,
    },
    {
      name: "Area",
      cell: (row) => row.area_name,
    },
    {
      name: "Location",
      cell: (row) => row.location_name,
    },
    {
      name: "Value",
      cell: (row) => `${row.value} m3`,
    },
    {
      name: "Message",
      cell: (row) => row.message,
      conditionalCellStyles: [
        {
            when: row => row.message === "Melebihi Batas Tanki",
            style: {
                backgroundColor: 'rgba(242, 38, 19, 0.9)',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
          when: row => row.message !== "Melebihi Batas Tanki",
          style: {
              backgroundColor: 'rgba(63, 195, 128, 0.9)',
              color: 'white',
              '&:hover': {
                  cursor: 'not-allowed',
              },
          },
      },
      ]
    },
  ];

  const handleGetLocation = (id) => {
    setIdRender(id)
    API.get(`early/warning/system/chemicals/${id}`, { headers })
    .then(({ data: content }) => {
      setListData(content.data);
      setPending(false)
    })
    .catch((err) => {
      console.log(err);
    });
   }

  const getSite = () => {
    API.get("utility/master/data/site", { headers })
      .then(({ data: content }) => {
        setSite(content.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getArea = () => {
    API.get("utility/master/data/area/1", { headers })
      .then(({ data: content }) => {
        setArea(content.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLocation = () => {
    API.get(`utility/master/data/location/2`, { headers })
      .then(({ data: content }) => {
        setLocation(content.data);
        setLocationSelected(content.data[0].name)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getData = () => {
    API.get("early/warning/system/chemicals/2", { headers })
      .then(({ data: content }) => {
        setListData(content.data);
        setPending(false)
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err);
      });
  };


  useEffect(() => {
    getSite();
    getArea();
    getLocation();
    getData()
  }, []);


  return (
    <div id="wrap">
      {isLoading ? <ReactSpinner/>:
        <div style={{minHeight:'90vh'}}>
          <Header/>
          <BreadCumb pageRoot="Early Warning System" name="Chemical Area" parent="/dashboard" subParent="/dashboard" underPage="Early Warning" />

          <div className="container-fluid mt--6">
            <div className="card shadowTailwind">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-4">
                    <label className="form-control-label textPallete">Site</label>
                    <select
                      className="form-control text-darker"
                      id="channel"
                      name="channel"
                      data-toggle="select"
                      disabled
                      value={1}
                    >
                      {site.length !== 0 && site.map((el, idx) => (
                        <option value={el.id}  key={idx}>
                          {el.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-lg-4">
                    <label className="form-control-label textPallete">Area</label>
                    <select
                      className="form-control text-darker"
                      disabled
                      id="devices"
                      name="devices"
                      value={2}
                      data-toggle="select"
                    >
                      {area.length !== 0 && area.map((el, idx) => (
                        <option value={el.id}  key={idx}>
                          {el.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  
                  <div className="col-lg-4">
                    <label className="form-control-label textPallete">
                      Location
                    </label>
                    <select
                      className="form-control text-darker"
                      id="devices"
                      name="devices"
                      data-toggle="select"
                      value={idRender}
                      onChange={(e) => handleGetLocation(e.target.value)}
                    >
                      {location.length !== 0 && location.map((el, idx) => (
                        <option value={el.id} key={idx}>
                          {el.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
          {/* Page content */}
          <div className="container-fluid">
            <div className="row mt-3">
              <div className="col-lg-12">
              <div className="card">
                  <div className="card-body">
                  <div className="col-lg-12">
                        <div className="row mb-5">
                          <div className="col-lg-6">
                            <h3 className=" mb-0 text-red">Chemical Area</h3>
                          </div>
                        </div>
                        {/* table */}
                        <DataTableExtensions
                          print={false}
                          export={false}
                          exportHeaders
                          columns={columnsListData}
                          data={listData}
                          filter={false}
                        >
                          <DataTable pagination progressPending={pending} />
                        </DataTableExtensions>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default ChemicalArea;
