import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import BreadCumb from "../../components/BreadCumb";
import Footer from "../../components/Footer";
import API from "../../api/base_url";
import DataTable from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions';
import Swal from "sweetalert2";
import Moment from 'react-moment';
import Helper from "../../utility/helper.js"
import moment from 'moment'
import ReactSpinner from "../../components/ReactSpinner";





const Clearator = () => {
  const [site, setSite] = useState([]);
  const [area, setArea] = useState([]);
  const [location, setLocation] = useState([]);
  const [locationSelected, setLocationSelected] = useState(""); // <-- get id Location selected to determine name Canal Intake Form
  // above is tmp for each filter
  const [dailyData, setDailyData] = useState([])
  const [listTotalData, setListTotalData] = useState([])
  // above is table data
  const [formWtp, setFormWtp] = useState("1")              // <-- Form WTP
  const [value, onChange] = useState(new Date());          // <-- Form Date and Time
  const [formNameDevice, setFormNameDevice] = useState("") // <-- Form Name Canal Intake
  const [formDebit, setFormDebit] = useState("")           // <-- Form Debit Value
  const [formSatuan, setFormSatuan] = useState("m3")       // <-- Form Satuan
  // above is tmp for two way binding in form to create purpose
  const [selectedDevice, setSelectedDevice] = useState("")
  const [cardInfo, setCardInfo] = useState([0, 0, new Date().toDateString()])
  // above is device selected
  const [isLoading, setIsLoading] = useState(true)


  const token = localStorage.token;
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const columnsListDataClearator = [
    {
      name: 'No',
      cell: (row, index) => index + 1,
      width: "50px"
    },
    {
      name: 'Sludge Drain Name',
      cell: (row) => row.name,
    },
    {
      name: 'Data & Time',
      cell: (row) => <Moment format="DD-MM-YYYY HH:mm:ss">{row.recorded_at}</Moment>,
    },
    {
      name: 'Flow Rate',
      cell: (row) => `${row.flow_rate} m3/s`,
    },
    {
      name: 'Total Debit',
      cell: (row) => `${row.totalizer} m3`,
    },
  ];



  // function to populated data needed
  const getSite = () => {
    API.get("utility/master/data/site", { headers })
      .then(({ data: content }) => {
        setSite(content.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getArea = () => {
    API.get("utility/master/data/area/1", { headers })
      .then(({ data: content }) => {
        setArea(content.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLocation = () => {
    API.get(`utility/master/data/location/8`, { headers })
      .then(({ data: content }) => {
        setLocation(content.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDevice = (id) => {
    API.get(`utility/master/data/device/${id}`, { headers })
      .then(({ data: content }) => {
        if (content.data.length !== 0) {
          setSelectedDevice(content.data[0].id)
          setFormNameDevice(content.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDailyData = () => {
    API.get("utility/clearator", { headers })
      .then(({ data: content }) => {
        setCardInfo([content.data.flow_meter.flow_rate, content.data.flow_meter.totalizer, content.data.flow_meter.last_update])
        setDailyData(content.data.list_data);
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getListTotalData = () => {
    API.get("utility/canal/intake/report", { headers })
      .then(({ data: content }) => {
        setListTotalData(content.data);
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err);
      });
  }
  // end of function to populated data needed


  // Location Result in Filter 
  const handleGetDevice = (id) => {
    if (!id) {
      setFormWtp("1")
      setFormNameDevice("")
      setFormDebit("")
      setFormSatuan("m3")
      setLocationSelected("")
    } else {
      setLocationSelected(id)
      setFormNameDevice(id);
      getDevice(id)
    }
  }

  useEffect(() => {
    getSite()
    getArea()
    getLocation()
    getDailyData()
    getListTotalData()
  }, [])


  useEffect(() => {
    const interval = setInterval(() => {
      getDailyData()
      getListTotalData()
    }, 5000);

    return () => {
      clearInterval(interval)
    }
  }, [dailyData])



  return (
    <div id="wrap">
      {/* {isLoading ? <ReactSpinner /> : */}
        <div>
          <Header />
          <BreadCumb pageRoot="Dashboard" name="Sludge Drain" parent="/dashboard" subParent="/dashboard" underPage="Utility" />
          <div className="container-fluid mt--6">
            <div className="card shadowTailwind">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-4">
                    <label className="form-control-label textPallete">Site</label>
                    <select
                      className="form-control text-darker"
                      id="channel"
                      name="channel"
                      data-toggle="select"
                      disabled
                      value={1}
                    >
                      {
                        site.length === 0 ? (<></>) : (
                          site.map((el, idx) => (
                            <option value={el.id} key={idx}>{el.name}</option>
                          ))
                        )
                      }
                    </select>
                  </div>
                  <div className="col-lg-4">
                    <label className="form-control-label textPallete">Area</label>
                    <select
                      className="form-control text-darker"
                      disabled
                      id="devices"
                      name="devices"
                      value={8}
                      data-toggle="select"
                    >
                      {
                        area.length === 0 ? (<></>) : (
                          area.map((el, idx) => (
                            <option value={el.id} key={idx}>{el.name}</option>
                          ))
                        )
                      }
                    </select>
                  </div>
                  <div className="col-lg-4">
                    <label className="form-control-label textPallete">Location</label>
                    <select
                      className="form-control text-darker"
                      id="devices"
                      name="devices"
                      data-toggle="select"
                      value={locationSelected}
                      disabled
                      onChange={(e) => handleGetDevice(e.target.value)}
                    >
                      {/* <option value="" >-- Select Location --</option> */}
                      {
                        location.length === 0 ? (<></>) : (
                          location.map((el, idx) => (
                            <option value={el.name} key={idx}>{el.name}</option>
                          ))
                        )
                      }
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Page content */}
          <div className="container-fluid">
            <div className="row mt-3">
              <div className="col-lg-12">
                <div className="card shadowTailwind">
                  <div className="card-header d-flex  justify-content-end">
                    <span className={Helper(cardInfo[2])[1] ? "text-red font-weight-bold" : "text-success"}>
                      Last Update  {moment.utc(cardInfo[2]).local().format('DD/MM/YYYY HH:mm:ss')}
                    </span>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="card card-stats bg-primary">
                          <div className="card-body">
                            <div className="row">
                              <div className="col">
                                <h4 className="card-title text-uppercase fontTV text-white mb-0">
                                  Flow Rate
                                </h4>
                                <span className="fontTV font-weight-bold mb-0 text-white">
                                  {cardInfo[0]} m<sup>3</sup>/h
                                </span>
                              </div>
                              <div className="col-auto">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="card card-stats bg-primary">
                          <div className="card-body">
                            <div className="row">
                              <div className="col">
                                <h4 className="card-title fontTV  text-uppercase text-darker text-white mb-0">
                                  Total Debit
                                </h4>
                                <span className="fontTV font-weight-bold mb-0 text-white">
                                  {cardInfo[1]} m<sup>3</sup>
                                </span>
                              </div>
                              <div className="col-auto">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg" >
                        <h3 className="text-darker">List Data Sludge Drain</h3>
                        <DataTableExtensions print={false} export={false} exportHeaders columns={columnsListDataClearator} data={dailyData} filter={false} >
                          <DataTable pagination />
                        </DataTableExtensions>
                        <span className="badge badge-warning mt-3">
                          NB : Data ini akan direset 24 jam
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      {/* } */}
    </div>
  );
};

export default Clearator;
