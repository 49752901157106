import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import DataTable from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import API from "../../api/base_url";
import Swal from "sweetalert2";
import BreadCumb from "../../components/BreadCumb";
import Footer from "../../components/Footer";



const CategoryFaq = () => {
  const [allData, setAllData] = useState([]);
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [updateName, setUpdateName] = useState("");
  const [updateDescription, setUpdateDescription] = useState("");
  const [pending, setPending] = useState(true);
  const [willUpdate, setWillUpdate] = useState("")

  let mainData = allData.filter((el) => el.status === "AC");
  let token = localStorage.token;
  const headers = {
    Authorization: `Bearer ${token}`,
  };


  const columns = [
    {
      name: 'No',
      cell: (row, index) => index + 1,
      width: "50px"
      // sortable: true,                                    
    },
    {
      name: 'Name',
      cell: (row) => row.name,
      // sortable: true,                                    
    },
    {
      name: 'Description',
      cell: (row) => row.description,
    },
    {
      name: "Action",
      center:true,
      cell:(row)=><div className="dropdown">
      <button
        className="btn btn-warning dropdown-toggle"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        Action
      </button>
      <div
        className="dropdown-menu"
        aria-labelledby="dropdownMenuButton"
      >
        <button
          id="btn_add"
          type="button"
          className="dropdown-item button-modal"
          data-toggle="modal"
          data-target="#exampleModalUpdate"
          data-action="tambah"
          onClick={() => populationDataForUpdate(row.id)}
        >
          Edit
        </button>
        <button
          type="button"
          className="dropdown-item"
          href="#"
          onClick={() => handleDelete(row.id)}
        >
          Delete
        </button>
      </div>
    </div>,
    },
  ];

  // alert
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const confirm = () => {
    Swal.fire({
      title: 'Do you want to add Categgory FAQ?',
      showCancelButton: true,
      confirmButtonText: 'Add',
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          name,
          description,
        };
        if (!payload.name || !payload.description) {
          Swal.fire('Data is Required, please fill out the form', '', 'error')
        } else {
          API.post("dashboard/master/faq/category", payload, { headers })
            .then(({ data }) => {
              setName("");
              setDescription("");
              getData();
              Swal.fire('new Category of FAQ has added successfully', '', 'success')
            })
            .catch((err) => {
              setName("");
              setDescription("");
              Swal.fire('error from server', '', 'error')
            });
        }
      }
    })
  }
  // end of Alert

  const getData = () => {
    API.get("dashboard/master/faq/categories", { headers })
      .then(({ data: content }) => {
        setAllData(content.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const populationDataForUpdate = (id) => {
    API.get(`dashboard/master/faq/category/${id}`, { headers })
      .then(({ data: content }) => {
        setWillUpdate(content.data.id)
        setUpdateName(content.data.name);
        setUpdateDescription(content.data.description);
      })
      .catch((err) => {
        console.log(err); 
      });
  };

  const handleUpdate = () => {
    Swal.fire({
      title: 'Do you want to Update this Category FAQ?',
      showCancelButton: true,
      confirmButtonText: 'Update',
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          name: updateName,
          description: updateDescription
        };
        if (!payload.name || !payload.description) {
          Swal.fire('Data is Required, please fill out the form', '', 'error')
        } else {
          API.put(`/dashboard/master/faq/category/${willUpdate}`, payload, { headers })
            .then(({ data }) => {
              setUpdateName("");
              setUpdateDescription("");
              getData();
              Swal.fire('Category faq has updated successfully', '', 'success')
            })
            .catch((err) => {
              setUpdateName("");
              setUpdateDescription("");
              Swal.fire('error from server', '', 'error')
            });
        }
      }
    })
  }

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Do you want to Delete this Category FAQ?',
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.isConfirmed) {
        API.patch(`dashboard/master/faq/category/${id}`, {}, { headers })
        .then(({ data }) => {
          getData();
          Toast.fire({
            icon: "success",
            title: "Delete Category successfully",
          });
        })
        .catch((err) => {
          console.log(err);
          Toast.fire({
            icon: "error",
            title: "Delete Category successfully",
          });
        });
      }
    })
  };


  return (
    <div id="wrap">
      <div>
        <Header />
        <BreadCumb pageRoot="Admin Panel" name="Category FAQ" parent="/dashboard" subParent="/dashboard" underPage="Admin Panel" />

        {/* Page content */}
        <div className="container-fluid mt--6">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                    <div className="row">
                      <div className="col-lg-6">
                        <h5 className="h3 mb-0">Category FAQ</h5>
                      </div>
                      <div className="col-lg-6">
                        <button
                          id="btn_add"
                          type="button"
                          className="float-right btn btn-primary button-modal"
                          data-toggle="modal"
                          data-target="#exampleModal"
                          data-action="tambah"
                        >
                          <i className="fas fa-plus mr-3" /> ADD CATEGORY
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                  <DataTableExtensions  exportHeaders  columns={columns} data={mainData} filter={false} >
                    <DataTable pagination />
                  </DataTableExtensions>
                  </div>
              </div>
            </div>
          </div>

          {/* CALLING MODAL */}
          {/* Modal Add Data */}
          <div className="modal fade" id="exampleModal" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-12">
                        <h3 className="modal-title mb-5" id="exampleModalLabel">
                          Add New Category FAQ
                        </h3>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label className="form-control-label">
                            Name
                          </label>
                          <textarea
                            className="form-control input-sm"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <label className="form-control-label">
                            Description
                          </label>
                          <textarea
                            className="form-control input-sm"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <button
                      className="btn btn-primary mt-5"
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => confirm()}
                    >
                      Add
                    </button>
                    <button
                      className="btn btn-warning mt-5"
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      Cancel
                    </button>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div id="dynamicForm" />
                </div>
              </div>
            </div>
          </div>

          {/* Modal Update Data */}
          <div
            className="modal fade"
            id="exampleModalUpdate"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-12">
                        <h3 className="modal-title mb-5" id="exampleModalLabel">
                          Update Category FAQ
                        </h3>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label className="form-control-label">
                            Name
                          </label>
                          <textarea
                            className="form-control input-sm"
                            value={updateName}
                            onChange={(e) => setUpdateName(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <label className="form-control-label">
                            Description
                          </label>
                          <textarea
                            className="form-control input-sm"
                            value={updateDescription}
                            onChange={(e) => setUpdateDescription(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <button 
                      className="btn btn-warning mt-5" 
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={()=> handleUpdate()}
                      >
                        Update
                      </button>
                    <button
                      className="btn btn-primary mt-5"
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      Cancel
                    </button>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div id="dynamicForm" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryFaq;
