import { useRef } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import moment from 'moment';
import Swal from 'sweetalert2'




export default function ExportListBackwash({time, location, data}) {
  const tableRef = useRef(null);

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    background:"#c51a1a",
    color:"#ffffff",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `Backwash Area - ${location}`,
    sheet: "Backwash Area"
  });

  let handleSubmit = () => {
    onDownload()
    
    Toast.fire({
      icon: "success",
      title: "Data has exported",
    });
  } 
  

  return (
    <div>
      <button hidden={data.length == 0} onClick={()=> handleSubmit()} className="btn bg-pallete text-white"><span className="fa fa-download mr-2" /> Export excel </button>

      <table ref={tableRef} style={{display:"none"}}>
        <tbody>
          <tr>
            <th colSpan="5" align="center">Backwash Area Location : {location}</th>
          </tr>
          <tr>
            <th colSpan="5"   align="center">Time Production : {time}</th>
          </tr>
          <tr>

          </tr>
          <tr>
            <th>No</th>
            <th>Name</th>
            <th>Date & Time</th>
            <th>Debit Value</th>
            <th>Device Parameter</th>
          </tr>
          {
            data !==0 && data.map((el, idx) =>  (
              <tr key={idx}>
                <td>{idx + 1}</td>
                <td width={200}>{el.device_name}</td>
                <td width={200} align="left">{moment.utc(el.recorded_at).local().format('DD/MM/YYYY HH:mm:ss')}</td>
                <td width={200}>{el.value}</td>
                <td width={200}>{el.device_parameter}</td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  );
}
