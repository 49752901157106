import React, { useState, useEffect } from "react";
import ChartExample from "../../components/ChartExample";
import DatePicker from "../../components/DatePicker";
import Header from "../../components/Header";
import Swal from 'sweetalert2'
import BreadCumb from "../../components/BreadCumb";
import API from "../../api/base_url";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import moment from 'moment-timezone'
import ExportListData from "../../components/ExportExcelChemical/ExportListData";
import ExportTotalWeight from "../../components/ExportExcelChemical/ExportTotalWeight";
import ReactSpinner from "../../components/ReactSpinner";
import Moment from 'react-moment';




const ChemicalArea = () => {
  const token = localStorage.token;
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    background: "#c51a1a",
    color: "#ffffff",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  let start = moment().startOf('day').utc().format("YYYY-MM-DD HH:mm:ss")
  let end = moment().endOf('day').utc().format("YYYY-MM-DD HH:mm:ss")
  // chemical function


  const columnsListData = [
    {
      name: "No",
      cell: (row, index) => index + 1,
      width: "50px",
    },
    {
      name: "Data & Time",
      cell: (row) => <Moment format="DD-MM-YYYY HH:mm:ss">{row.recorded_at}</Moment>,
    },
    {
      name: "Stage 1",
      cell: (row) => row.stage1,
    },
    {
      name: "Stage 2",
      cell: (row) => `${row.stage2}`,
    },
    {
      name: "Intake",
      cell: (row) => `${row.intake}`,
    },
    {
      name: "Total KWH",
      cell: (row) => `${row.kwh}`,
    },
    {
      name: "Production",
      cell: (row) => `${row.production}`,
    },
    {
      name: "KWH / M3",
      cell: (row) => `${row.kwh_m3}`,
    },
  ];

  const columnsTotalWeight = [
    {
      name: "No",
      cell: (row, index) => index + 1,
      width: "50px",
    },
    {
      name: "Data & Time",
      cell: (row) => <Moment format="DD-MM-YYYY HH:mm:ss">{row.recorded_at}</Moment>,
    },
    {
      name: "Tank A",
      cell: (row) => `${row.weight_tank_a} Kg`,
    },
    {
      name: "Tank B",
      cell: (row) => `${row.weight_tank_b} Kg`,
    },
    {
      name: "Total Weight",
      cell: (row) => `${row.weight_total} Kg`,
    },
  ];
  // end of chemical function

  // container populate data
  const [site, setSite] = useState([]);
  const [area, setArea] = useState([]);
  const [location, setLocation] = useState([]);

  const [listData, setListData] = useState([]);
  const [listTotalWeightData, setListTotalWeightData] = useState([]);
  const [dataChartTankA, setDataChartTankA] = useState([]);
  const [dataChartTankB, setDataChartTankB] = useState([]);
  // end of container populate data

  const [locationSelected, setLocationSelected] = useState("");
  const [idRender, setIdRender] = useState(4)
  const [dateRangePickerValue, setDateRangePickerValue] = useState(`${start}/${end}`);
  const [dateRangePickerDisplay, setDateRangePickerDisplay] = useState(`${start}/${end}`);
  const [pending, setPending] = useState(true);
  const [isLoading, setIsLoading] = useState(true)



  // populated header
  const getSite = () => {
    API.get("utility/master/data/site", { headers })
      .then(({ data: content }) => {
        setSite(content.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getArea = () => {
    API.get("utility/master/data/area/1", { headers })
      .then(({ data: content }) => {
        setArea(content.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLocation = () => {
    API.get(`utility/master/data/location/2`, { headers })
      .then(({ data: content }) => {
        setLocation(content.data);
        setLocationSelected(content.data[0].name)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getNameLocation = (index) => {

    switch (index) {
      case "4":
        API.get(`utility/master/data/location/2`, { headers })
          .then(({ data: content }) => {
            setLocationSelected(content.data[0].name)
          })
          .catch((err) => {
            console.log(err);
          });
        break;
      case "6":
        API.get(`utility/master/data/location/2`, { headers })
          .then(({ data: content }) => {
            setLocationSelected(content.data[1].name)
          })
          .catch((err) => {
            console.log(err);
          });
        break;
      case "8":
        API.get(`utility/master/data/location/2`, { headers })
          .then(({ data: content }) => {
            setLocationSelected(content.data[2].name)
          })
          .catch((err) => {
            console.log(err);
          });
        break;

      default:
        break;
    }

  };


  const handleFilterSubmit = () => {
    setListData([])
    setListTotalWeightData([])
    setDataChartTankA([])
    setDataChartTankB([])
    getNameLocation(idRender)
    getListData()
    Toast.fire({
      icon: "success",
      title: "Filter Data Request",
    });
  }

  const getListData = () => {
    API.get(`report/electrical/${dateRangePickerValue}`, { headers })
      .then(({ data: content }) => {
        setListData(content.data.table);
        // setListTotalWeightData(content.data.list_total);
        let tankATmps = []
        let tankBTmps = []

        content.data.chart.forEach(el => {
          tankATmps.push({ x: moment.utc(el.date).local().format('DD/MM/YYYY HH:mm:ss'), y: el.kwh_m3 })
        });

        // content.data.tank_b.report_device_value_trend.forEach(el => {
        //   tankBTmps.push({ x: moment.utc(el.report_device_value_trend_time).local().format('DD/MM/YYYY HH:mm:ss'), y: el.report_device_value_trend_value })
        // });

        setDataChartTankA(tankATmps)
        setDataChartTankB(tankBTmps);

        setPending(false)
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const setDateRangePickerValueFunc = (val) => {
    setDateRangePickerValue(val)
  }

  const setDateRangePickerValueExcelFunc = (val) => {
    setDateRangePickerDisplay(val)
  }

  const handleGetLocation = (id) => {
    setIdRender(id)
  }

  useEffect(() => {
    getSite();
    getArea();
    getLocation();
    getListData()
  }, []);

  return (
    <div id="wrap">
      {/* {isLoading ? <ReactSpinner /> : */}
      <div >
        <Header />
        <BreadCumb pageRoot="Report & Analisis" name="Electrical" parent="/dashboard" subParent="/dashboard" underPage="Report Analisis" />
        <div className="container-fluid mt--6">
          <div className="card shadowTailwind">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-3" style={{display:'none'}}>
                  <label className="form-control-label textPallete">Site</label>
                  <select
                    className="form-control text-darker"
                    id="channel"
                    name="channel"
                    data-toggle="select"
                    disabled
                    value={1}
                  >
                    {site.length !== 0 && site.map((el, idx) => (
                      <option value={el.id} key={idx}>
                        {el.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-lg-3" style={{display:'none'}}>
                  <label className="form-control-label textPallete">Area</label>
                  <select
                    className="form-control text-darker"
                    disabled
                    id="devices"
                    name="devices"
                    value={2}
                    data-toggle="select"
                  >
                    {area.length !== 0 && area.map((el, idx) => (
                      <option value={el.id} key={idx}>
                        {el.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-lg-3" style={{display:'none'}}>
                  <label className="form-control-label textPallete">
                    Location
                  </label>
                  <select
                    className="form-control text-darker"
                    id="devices"
                    name="devices"
                    data-toggle="select"
                    value={idRender}
                    onChange={(e) => handleGetLocation(e.target.value)}
                  >
                    {location.length !== 0 && location.map((el, idx) => (
                      <option value={el.id} key={idx}>
                        {el.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-2">
                  <label className="form-control-label textPallete">Date Range</label>
                  <DatePicker take={(val) => setDateRangePickerValueFunc(val)} takeTimeExcel={(val) => setDateRangePickerValueExcelFunc(val)} />
                </div>
                <div className="col-lg-1 p-1">
                  <label className="form-control-label textPallete">&nbsp;</label> <br />
                  <button className="btn bg-pallete text-white" onClick={() => handleFilterSubmit()}>
                    <span className="fa fa-search mr-2" /> Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* filter */}
        {/* <div className="container-fluid mt--3">
          <div className="card shadowTailwind">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-8">
                  <label className="form-control-label textPallete">Date Range</label>
                  <DatePicker take={(val) => setDateRangePickerValueFunc(val)} takeTimeExcel={(val) => setDateRangePickerValueExcelFunc(val)} />
                </div>
                <div className="col-lg-4">
                  <label className="form-control-label textPallete">&nbsp;</label> <br />
                  <button className="btn bg-pallete text-white" onClick={() => handleFilterSubmit()}>
                    <span className="fa fa-search mr-2" /> Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* Page content */}
        <div className="container-fluid">
          <div className="row mt-3">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="mb-0 text-red">Electrical</h4>
                </div>
                <div className="card-body">
                  <div className="row">

                    <div className="card-header">
                      <h4 className="mb-0 text-red">Chart </h4>
                    </div>
                    <div className="col-lg-12">
                      <ChartExample streaming={dataChartTankA} />
                    </div>
                    <div className="col-lg-12">
                      <div className="d-flex justify-content-between">
                        <h4 className="text-darker">
                          List Data
                        </h4>
                        <ExportListData time={dateRangePickerDisplay} location={locationSelected} data={listData} />
                      </div>
                      <DataTableExtensions
                        print={false}
                        export={false}
                        exportHeaders
                        columns={columnsListData}
                        data={listData}
                        filter={true}
                        filterDigit={1}
                      >
                        <DataTable pagination highlightOnHover progressPending={pending} />
                      </DataTableExtensions>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* } */}
    </div>
  );
};

export default ChemicalArea;
