import React, { useState } from "react";
import Sidebar from "../../components/Sidebar";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { useStopwatch } from "react-timer-hook";
import DatePicker from "../../components/DatePicker";
import Swal from 'sweetalert2'
import BreadCumb from "../../components/BreadCumb";



function MyStopwatch() {
  const { seconds, minutes, hours, isRunning, start, pause, reset } =
    useStopwatch({ autoStart: false });

  let endNow = () => {
    pause()
    
    setTimeout(() => { 
      reset()
      pause()
     }, 100)
     
  };

  let startNow = () => {
    reset()
    start()
  }

  let convertTimer = () => {
    var timeInSeconds = (hours * 60 * 60) +
      (minutes * 60) +
      seconds;

    let displayHours = Math.floor(timeInSeconds / (60 * 60));
    let remainder = timeInSeconds - (displayHours * 60 * 60);
    let displayMinutes = Math.floor(remainder / 60);
    let displaySeconds = remainder - (displayMinutes * 60);

    let result = ('0' + displayHours).substr(-2) + ":" +
        ('0' + displayMinutes).substr(-2) + ":" + ('0' + displaySeconds).substr(-2);

    return result
  }  

  return (
    <div style={{ textAlign: "center" }}>
      <h1>Production Time</h1>
      <div style={{ fontSize: "100px" }}>
        {/* <span>{hours}</span>:<span>{minutes}</span>:
        <span>{seconds}</span> */}
        <span>{convertTimer()}</span>
      </div>
      <p className="customeText">{isRunning ? "Running" : "Not running"}</p>
      <button className="btn btn-primary" onClick={() => startNow()}>
        Start
      </button>
      <button className="btn btn-danger" onClick={() => endNow()}>
        Stop
      </button>
      {/* <button className="btn btn-danger" onClick={reset}>
        Reset
      </button> */}
    </div>
  );
}

const ProductionTime = () => {
  const [intake, setIntake] = useState("Intake 1");

  let btnDownload = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })
    
    swalWithBootstrapButtons.fire({
      title: 'Download Option',
      text: "choose one for your needs",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'CSV',
      cancelButtonText: 'PDF',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        swalWithBootstrapButtons.fire(
          'Download as CSV!',
          'Your file has been downloaded.',
          'success'
        )
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Download as PDF!',
          'Your file has been downloaded :)',
          'success'
        )
      }
    })
  }

  
  return (
    <div id="wrap">
      {/* Sidenav */}
      {/* Main content */}
      <div>
        {/* Topnav */}
        <Header />
        {/* ENDSIDEBAR */}
        <BreadCumb pageRoot="Report & Analisis" name="Production Time" parent="/dashboard" subParent="/dashboard" underPage="Report Analisis" />
        
        {/* DEBUG-VIEW ENDED 2 APPPATH/Views/template/sidebar_v.php */}
        <div className="container-fluid mt--6">
          {/* DEBUG-VIEW START 4 APPPATH/Views/template/content_v.php */}
          {/* DEBUG-VIEW START 3 APPPATH/Views/report_analisis/canal_intake/index.php */}
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6">
                  <select
                    className="form-control"
                    id="channel"
                    name="channel"
                    data-toggle="select"
                  >
                    <option value>Select WTP</option>
                    <option value={1} selected>
                      WTP 1
                    </option>
                  </select>
                </div>
               
                <div className="col-lg-12 mt-3">
                  <div>
                    <p>
                      <div className="row">
                        <div className="col-lg-6">
                        <div className="input-group date mdatetimepicker">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Date Range"
                          disabled
                        />
                        <span className="input-group-addon input-group-append">
                          <button
                            className="btn btn-primary"
                            type="button"
                            id="button-addon2"
                            aria-controls="collapseExample"
                            aria-expanded="false"
                            data-target="#collapseExample"
                            data-toggle="collapse"
                          >
                            {" "}
                            <span className="fa fa-calendar" />
                          </button>
                        </span>
                      </div>
                        </div>
                      </div>
                    </p>
                    <div className="collapse" id="collapseExample">
                      <div className="col-lg-12 mt-6">
                        <DatePicker />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mt-3">
                  <button className="btn btn-success" onClick={()=> btnDownload()}>
                    <span className="fa fa-download" /> Download
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* TABLE */}

          {/* END TABLE */}
          {/* END CALLING MODAL   */}
          {/* DEBUG-VIEW ENDED 3 APPPATH/Views/report_analisis/canal_intake/index.php */}
          {/* DEBUG-VIEW ENDED 4 APPPATH/Views/template/content_v.php */}
        </div>

        <div className="container-fluid">
          {/* DEBUG-VIEW START 4 APPPATH/Views/template/content_v.php */}
          {/* DEBUG-VIEW START 3 APPPATH/Views/report_analisis/canal_intake/index.php */}
          
          {/* TABLE */}

          {/* END TABLE */}
          {/* END CALLING MODAL   */}
          {/* DEBUG-VIEW ENDED 3 APPPATH/Views/report_analisis/canal_intake/index.php */}
          {/* DEBUG-VIEW ENDED 4 APPPATH/Views/template/content_v.php */}
        </div>
        {/* Page content */}
        <div className="container-fluid ">
          {/* DEBUG-VIEW START 4 APPPATH/Views/template/content_v.php */}
          {/* DEBUG-VIEW START 3 APPPATH/Views/dashboard/canal_intake/index.php */}
          {/* TABLE */}
          <div className="row mt-3">
            <div className="col-lg-12">
              <div className="card">
                {/* Card header */}
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-12">
                      <h4>Log Activity </h4>
                      <table className="table table-flush datatables">
                        <thead className="thead-light">
                          <tr>
                            <th>No</th>
                            <th>Date Start</th>
                            <th>Time Start</th>
                            <th>Date Stop</th>
                            <th>Time Stop</th>
                            <th>Total Time</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>22 Juni 2022</td>
                            <td>01:00:34</td>
                            <td>22 Juni 2022</td>
                            <td>04:00:00</td>
                            <td>03:00:34</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>22 Juni 2022</td>
                            <td>02:00:00</td>
                            <td>22 Juni 2022</td>
                            <td>04:00:00</td>
                            <td>02:00:34</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* END TABLE */}
          {/* DEBUG-VIEW ENDED 3 APPPATH/Views/dashboard/canal_intake/index.php */}
          {/* DEBUG-VIEW ENDED 4 APPPATH/Views/template/content_v.php */}
        </div>
        {/* DEBUG-VIEW START 6 APPPATH/Views/template/footer_v.php */}
        {/* Footer */}
        <div className="container-fluid">
          <footer className="footer pt-0">
            <div className="row align-items-center justify-content-lg-between">
              <div className="col-lg-6">
                <div className="copyright text-center  text-lg-left  text-muted">
                  © 2022{" "}
                  <a href="#" className="font-weight-bold ml-1" target="_blank">
                    Telkomsel IoT
                  </a>
                </div>
              </div>
              <div className="col-lg-6">
                <ul className="nav nav-footer justify-content-center justify-content-lg-end">
                  <li className="nav-item">
                    <a href="#" className="nav-link" target="_blank">
                      Telkomsel IoT
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link" target="_blank">
                      About Us
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link" target="_blank">
                      License
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </footer>
        </div>
        {/* END FOOTER */}
      </div>
    </div>
  );
};

export default ProductionTime;
