import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import BreadCumb from "../../components/BreadCumb";
import Footer from "../../components/Footer";
import API from "../../api/base_url";
import DataTable from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions';
import Swal from "sweetalert2";
import DateTimePicker from 'react-datetime-picker';
import Moment from "react-moment";


const RinseDrain = () => {

  const [site, setSite] = useState([]);
  const [area, setArea] = useState([]);
  const [location, setLocation] = useState([]);
  const [locationSelected, setLocationSelected] = useState(""); // <-- get id Location selected to determine name Canal Intake Form
  // above is tmp for each filter
  const [dailyData, setDailyData] = useState([])
  const [listTotalData, setListTotalData] = useState([])
  // above is table data
  const [formWtp, setFormWtp] = useState("1")              // <-- Form WTP
  const [value, onChange] = useState(new Date());          // <-- Form Date and Time
  const [formNameDevice, setFormNameDevice] = useState("") // <-- Form Name Canal Intake
  const [formDebit, setFormDebit] = useState("")           // <-- Form Debit Value
  const [formSatuan, setFormSatuan] = useState("m3")       // <-- Form Satuan
  // above is tmp for two way binding in form to create purpose
  const [selectedDevice, setSelectedDevice] = useState("")
  const [selectedLocationValue, setSelectedLocationValue] = useState([])
  // above is device selected

  const token = localStorage.token;
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const columnsDaily = [
    {
      name: 'No',
      cell: (row, index) => index + 1,
      width: "50px"
    },
    {
      name: 'Date & Time',
      cell: (row) => <Moment format="DD-MM-YYYY HH:mm:ss">{row.recorded_at}</Moment>,
    },
    {
      name: 'Rinse / Drain Name',
      cell: (row) => row.device_name,
    },
    {
      name: 'Debit Value',
      cell: (row) => row.device_value_name,
    },
    {
      name: 'Satuan',
      cell: (row) => row.device_default_unit,
    },
    {
      name: "Action",
      center: true,
      cell: (row) => <div className="dropdown">
        <button
          className="btn btn-warning dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Action
        </button>
        <div
          className="dropdown-menu"
          aria-labelledby="dropdownMenuButton"
        >
          <button
            id="btn_add"
            type="button"
            className="dropdown-item button-modal"
            data-toggle="modal"
            data-target="#exampleModalUpdate"
            data-action="tambah"
            onClick={() => console.log(row.id)}
          >
            Edit
          </button>
          <button
            type="button"
            className="dropdown-item"
            href="#"
            onClick={() => handleDelete(row.id)}
          >
            Delete
          </button>
        </div>
      </div>,
    },
  ];

  const columnsListTotalData = [
    {
      name: 'No',
      cell: (row, index) => index + 1,
      width: "50px"
      // sortable: true,                                    
    },
    {
      name: 'Date & Time',
      cell: (row) => <Moment format="DD-MM-YYYY HH:mm:ss">{row.recorded_at}</Moment>,
      // sortable: true,                                    
    },
    {
      name: 'Rinse / Drain Name',
      cell: (row) => row.device_name,
    },
    {
      name: 'Debit Value',
      cell: (row) => row.device_value_name,
    },
    {
      name: 'Satuan',
      cell: (row) => row.device_default_unit,
    },
    {
      name: 'Total Value',
      cell: (row) => row.device_default_unit,
    },
  ];


  // function to populated data needed
  const getSite = () => {
    API.get("utility/master/data/site", { headers })
      .then(({ data: content }) => {
        setSite(content.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getArea = () => {
    API.get("utility/master/data/area/1", { headers })
      .then(({ data: content }) => {
        setArea(content.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLocation = () => {
    API.get("utility/master/data/location/10", { headers })
      .then(({ data: content }) => {
        setLocation(content.data);
        setSelectedLocationValue(content.data[0].name)
        setLocationSelected(content.data[0].id)
        getDevice(content.data[0].id)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDevice = (id) => {
    API.get(`utility/master/data/device/${id}`, { headers })
      .then(({ data: content }) => {
        if (content.data.length !== 0) {
          setSelectedDevice(content.data[0].id)
          setFormNameDevice(content.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDailyData = () => {
    API.get("utility/rinse/drain/dailys", { headers })
      .then(({ data: content }) => {
        setDailyData(content.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getListTotalData = () => {
    API.get("utility/rinse/drain/report", { headers })
      .then(({ data: content }) => {
        setListTotalData(content.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  // end of function to populated data needed


  // Location Result in Filter 
  const handleGetDevice = (id) => {
    if (!id) {
      setFormWtp("1")
      setFormNameDevice("")
      setFormDebit("")
      setFormSatuan("m3")
      setLocationSelected("")
    } else {
      setLocationSelected(id)
      getDevice(id)
    }
  }

  useEffect(() => {
    getSite()
    getArea()
    getLocation()
    getDailyData()
    getListTotalData()
  }, [])

  const handleSubmit = () => {
    Swal.fire({
      title: 'Do you want to add ?',
      showCancelButton: true,
      confirmButtonText: 'Add',
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          device_id: selectedDevice,
          recorded_at_milis: Math.round(new Date(value).getTime() / 1000),
          value: formDebit,
        };
        console.log(payload)
        if (!payload.device_id || !payload.recorded_at_milis || !payload.value) {
          Swal.fire('Data is Required, please fill out the form', '', 'error')
        } else {
          API.post("utility/rinse/drain", payload, { headers })
            .then(({ data }) => {
              setFormWtp("1")
              setFormNameDevice("")
              setFormDebit("")
              setLocationSelected("")
              getDailyData()
              getListTotalData()
              Swal.fire('added successfully', '', 'success')
            })
            .catch((err) => {
              Swal.fire('error from server', '', 'error')
            });
        }
      }
    })
  }

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Do you want to Delete this Record',
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.isConfirmed) {
        // API.patch(`utility/rinse/drain/${id}`, {}, { headers })
        // .then(({ data }) => {
        //   getData();
        //   Toast.fire({
        //     icon: "success",
        //     title: "Delete FAQ successfully",
        //   });
        // })
        // .catch((err) => {
        //   console.log(err);
        //   Toast.fire({
        //     icon: "error",
        //     title: "Delete FAQ successfully",
        //   });
        // });
      }
    })
  };

  return (
    <div id="wrap">
      <div>
        <Header />
        <BreadCumb pageRoot="Dashboard" name="Rinse / Drain" parent="/dashboard" subParent="/dashboard" underPage="Utility" />
        <div className="container-fluid mt--6">
          <div className="card shadowTailwind">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-4">
                  <label className="form-control-label textPallete">Site</label>
                  <select
                    className="form-control text-darker"
                    id="channel"
                    name="channel"
                    data-toggle="select"
                    disabled
                    value={1}
                  >
                    {
                      site.length === 0 ? (<></>) : (
                        site.map((el, idx) => (
                          <option value={el.id} key={idx}>{el.name}</option>
                        ))
                      )
                    }
                  </select>
                </div>
                <div className="col-lg-4">
                  <label className="form-control-label textPallete">Area</label>
                  <select
                    className="form-control text-darker"
                    disabled
                    id="devices"
                    name="devices"
                    value={10}
                    data-toggle="select"
                  >
                    {
                      area.length === 0 ? (<></>) : (
                        area.map((el, idx) => (
                          <option value={el.id} key={idx}>{el.name}</option>
                        ))
                      )
                    }
                  </select>
                </div>
                <div className="col-lg-4">
                  <label className="form-control-label textPallete">Location</label>
                  <select
                    className="form-control text-darker"
                    id="devices"
                    name="devices"
                    data-toggle="select"
                    disabled
                    value={locationSelected}
                    onChange={(e) => handleGetDevice(e.target.value)}
                  >
                    {
                      location.length === 0 ? (<></>) : (
                        location.map((el, idx) => (
                          <option value={el.id} key={idx}>{el.name}</option>
                        ))
                      )
                    }
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Page content */}
        <div className="container-fluid ">
          <div className="row mt-3">
            <div className="col-lg-12">
              <div className="card shadowTailwind">
                <div className="card-header">
                  <h4 className="mb-0 font-weight-bold text-red">{selectedLocationValue}</h4>
                </div>
                <div className="card-body">
                  <div className="row">
                    {/* Form Input */}
                    <div className="col-lg-4">
                      <h4>Form Input </h4>
                      {/* date and time */}
                      <div className="form-group">
                        <label className="form-control-label" htmlFor="exampleFormControlInput1">
                          Date &amp; Time
                        </label>
                        <input className="form-control" name="ends_at" type="datetime-local" id="ends_at" defaultValue="2021-05-13T20:00:00" />
                      </div>
                      {/* name canal intake */}
                      <div className="form-group">
                        <label className="form-control-label"> Name Rinse / Drain </label>
                        <select className="form-control" onChange={(e) => setSelectedDevice(e.target.value)}>
                          {
                            formNameDevice.length === 0 ? (<></>) : (
                              formNameDevice.map((el) => (
                                <option value={el.id} key={el.id}>{`${el.device_type_name} - ${el.name}`}</option>
                              ))
                            )
                          }
                        </select>
                      </div>
                      {/* debit value */}
                      <div className="form-group">
                        <label className="form-control-label">  Debit Value </label>
                        <input type="number" className="form-control" value={formDebit} onChange={(e) => setFormDebit(e.target.value)} />
                      </div>
                      {/* satuan */}
                      <div className="form-group">
                        <label className="form-control-label">Satuan</label>
                        <select className="form-control" disabled value={formSatuan} onChange={(e) => setFormSatuan(e.target.value)}>
                          <option value="m3">m3 /h</option>
                        </select>
                      </div>
                      {/* submit Button */}
                      <button className="btn bg-primary text-white" style={{ visibility: !formNameDevice ? "hidden" : "" }} onClick={() => handleSubmit()}>Submit</button>
                    </div>
                    {/* end of form input */}


                    {/* table */}
                    <div className="col-lg">
                      <h4 className="text-darker">List Data Rinse / Drain</h4>
                      <DataTableExtensions print={false} export={false} exportHeaders columns={columnsDaily} data={dailyData} filter={false} >
                        <DataTable pagination />
                      </DataTableExtensions>
                      <span className="badge badge-warning mt-3">
                        NB : Data ini akan direset 24 jam
                      </span>
                    </div>
                    <div className="col-lg-12 mt-5">
                      <h4 className="text-darker">List Total Data </h4>
                      <DataTableExtensions print={false} export={false} exportHeaders columns={columnsListTotalData} data={listTotalData} filter={false} >
                        <DataTable pagination />
                      </DataTableExtensions>
                    </div>
                    {/* end of table */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="exampleModalUpdate"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12">
                      <h4 className="modal-title mb-5" id="exampleModalLabel">
                        Update Daily Data
                      </h4>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label className="form-control-label">
                          Canal Intake Name
                        </label>
                        <select
                          className="custom-select"
                          id="inputGroupSelect02"
                        // value={updateCategory}
                        // onChange={(e) => setUpdateategory(e.target.value)}
                        >
                          <option value={1} >Canal Intake A</option>
                          <option value={2}>Canal Intake B</option>
                        </select>
                      </div>
                      <div className="form-group mb-3">
                        <label className="form-control-label">
                          Debit Value
                        </label>
                        <input type="text" className="form-control" />
                      </div>
                      <div className="form-group">
                        <label className="form-control-label">
                          Satuan
                        </label>
                        <select
                          className="custom-select"
                          id="inputGroupSelect02"
                        // value={updateCategory}
                        // onChange={(e) => setUpdateategory(e.target.value)}
                        >
                          <option value={1} >m3/h</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <button
                    className="btn btn-warning mt-5"
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => console.log()}
                  >
                    Update
                  </button>
                  <button
                    className="btn btn-primary mt-5"
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    Cancel
                  </button>
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div id="dynamicForm" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RinseDrain;
