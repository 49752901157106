import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import TankGaugeVolume from "../../components/TankGaugeVolume";
import BreadCumb from "../../components/BreadCumb";
import Footer from "../../components/Footer";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import API from "../../api/base_url";
import Swal from "sweetalert2";
import TankRadial from "../../components/TankRadial";
import TankCylincerSquare from "../../components/TankCylinderSquare";
import Helper from "../../utility/helper.js"
import moment from 'moment'
import ReactSpinner from "../../components/ReactSpinner";
import Moment from "react-moment";



const ChemicalArea = () => {
  const [site, setSite] = useState([]);
  const [area, setArea] = useState([]);
  const [location, setLocation] = useState([]);
  const [locationSelected, setLocationSelected] = useState(""); // <-- get id Location selected to determine name Canal Intake Form
  // above is tmp for each filter
  const [dailyData, setDailyData] = useState([]);
  const [listTotalData, setListTotalData] = useState([]);
  const [tank, setTank] = useState([]);
  const [success, setSuccess] = useState(false);
  const [idRender, setIdRender] = useState(null)

  // above is table data
  const [formWtp, setFormWtp] = useState("1"); // <-- Form WTP
  const [value, onChange] = useState(new Date()); // <-- Form Date and Time
  const [formNameDevice, setFormNameDevice] = useState(""); // <-- Form Name Canal Intake
  const [formDebit, setFormDebit] = useState(""); // <-- Form Debit Value
  const [formSatuan, setFormSatuan] = useState("m3"); // <-- Form Satuan
  // above is tmp for two way binding in form to create purpose
  const [selectedDevice, setSelectedDevice] = useState("");
  const [selectedLocationValue, setSelectedLocationValue] = useState([]);
  const [isLoading, setIsLoading] = useState(true)


  // above is device selected
  const [balance, setBalance] = useState("");

  const token = localStorage.token;
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const columnsListData = [
    {
      name: "No",
      cell: (row, index) => index + 1,
      width: "50px",
    },
    {
      name: "Nama Chemical Area",
      cell: (row) => row.name,
    },
    {
      name: "Data & Time",
      cell: (row) => <Moment format="DD-MM-YYYY HH:mm:ss">{row.recorded_at}</Moment>,
    },
    {
      name: "Level",
      cell: (row) => `${row.level} cm`,
    },
    {
      name: "Weight",
      cell: (row) => `${row.weight} Kg`,
    },
  ];

  const columnsTotalWeight = [
    {
      name: "No",
      cell: (row, index) => index + 1,
      width: "50px",
    },
    {
      name: "Data & Time",
      cell: (row) => <Moment format="DD-MM-YYYY HH:mm:ss">{row.recorded_at}</Moment>,
    },
    {
      name: "Tank A",
      cell: (row) => `${row.weight_tank_a} cm`,
    },
    {
      name: "Tank B",
      cell: (row) => `${row.weight_tank_b} cm`,
    },
    {
      name: "Total Weight",
      cell: (row) => `${row.weight_total} Kg`,
    },
  ];

  // function to populated data needed
  const getSite = () => {
    API.get("utility/master/data/site", { headers })
      .then(({ data: content }) => {
        setSite(content.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getArea = () => {
    API.get("utility/master/data/area/1", { headers })
      .then(({ data: content }) => {
        setArea(content.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLocation = () => {
    API.get(`utility/master/data/location/2`, { headers })
      .then(({ data: content }) => {
        // console.log(content.data[0].id,'locationDefaultwillBeRender')
        setIdRender(content.data[0].id)
        setLocation(content.data);
        setSelectedLocationValue(content.data[0].name);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDevice = (id) => {
    API.get(`utility/master/data/device/${id}`, { headers })
      .then(({ data: content }) => {
        if (content.data.length !== 0) {
          setSelectedDevice(content.data[0].id);
          setFormNameDevice(content.data);
          setSelectedLocationValue(content.data[0].location_name);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDailyData = () => {
    API.get(`utility/chemical/${idRender || 4}`, { headers })
      .then(({ data: content }) => {
        setBalance(content.data.balance);
        setDailyData(content.data.list_data);
        setTank(content.data.tank);
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getListTotalData = () => {
    API.get(`utility/chemical/${idRender || 4}`, { headers })
      .then(({ data: content }) => {
        setListTotalData(content.data.list_total);
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // end of function to populated data needed

  // Location Result in Filter
  const handleGetDevice = (id) => {
    setIdRender(id)
    getDailyData()
    getListTotalData()
    setLocationSelected(id);
    getDevice(id);
    setTimeout(() => { setIsLoading(true) }, 500)

  };


  useEffect(() => {
    getSite();
    getArea();
    getLocation();
    getDailyData();
    getListTotalData();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getDailyData();
      getListTotalData();
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 1000);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [dailyData]);



  return (
    <div id="wrap">
      {/* {isLoading ? <ReactSpinner /> : */}
        <div>
          <Header />
          <BreadCumb
            pageRoot="Dashboard"
            name="Chemical Area"
            parent="/dashboard"
            subParent="/dashboard"
            underPage="Utility"
          />
          <div className="container-fluid mt--6">
            <div className="card shadowTailwind">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-4">
                    <label className="form-control-label textPallete">Site</label>
                    <select
                      className="form-control text-darker"
                      id="channel"
                      name="channel"
                      data-toggle="select"
                      disabled
                      value={1}
                    >
                      {site.length === 0 ? (
                        <></>
                      ) : (
                        site.map((el, idx) => (
                          <option value={el.id} key={idx}>
                            {el.name}
                          </option>
                        ))
                      )}
                    </select>
                  </div>
                  <div className="col-lg-4">
                    <label className="form-control-label textPallete">Area</label>
                    <select
                      className="form-control text-darker"
                      disabled
                      id="devices"
                      name="devices"
                      value={2}
                      data-toggle="select"
                    >
                      {area.length === 0 ? (
                        <></>
                      ) : (
                        area.map((el, idx) => (
                          <option value={el.id} key={idx}>
                            {el.name}
                          </option>
                        ))
                      )}
                    </select>
                  </div>
                  <div className="col-lg-4">
                    <label className="form-control-label textPallete">
                      Location
                    </label>
                    <select
                      className="form-control text-darker"
                      id="devices"
                      name="devices"
                      data-toggle="select"
                      value={idRender}
                      onChange={(e) => handleGetDevice(e.target.value)}
                    >
                      {location.length === 0 ? (
                        <></>
                      ) : (

                        location.map((el, idx) => (
                          <option value={el.id} key={idx}>
                            {el.name}
                          </option>
                        ))
                      )}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mt-3">
              <div className="col-lg-12">
                <div className="card shadowTailwind">
                  <div className="card-header">
                    <h4 className="mb-0 font-weight-bold text-darker" style={{ fontSize: "1rem" }}>
                      {selectedLocationValue}
                    </h4>
                    <div className="col-md-12 mt--5 text-right d-flex justify-content-end">
                      <div className="card card-stats bg-primary mt-5">
                        <div className="card-body border  rounded">
                          <div className="row">
                            <div className="col">
                              <span className="card-title fontTV text-uppercase text-white  mb-0">
                                Balance in {selectedLocationValue}
                              </span>
                              <br></br>
                              <span className="fontTv font-weight-bold mb-0 text-white">
                                {balance} Kg
                              </span>
                            </div>
                            <div className="col-auto"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      {tank.length === 0
                        ? ""
                        : tank.map((el, idx) => (
                          <div className="col-lg-6" key={idx}>
                            <div className="card card-stats">
                              {/* Card body */}
                              <div className="d-flex flex-column" key={idx}>
                                <div
                                  className="d-flex flex-row"
                                  style={{ justifyContent: "space-around" }}
                                >
                                  <div className="position-relative">
                                    <TankCylincerSquare percentage={el.percentage} />
                                    <div className="position-absolute top-0 right-0">
                                      <span className="font-weight-bold">
                                        <small>MAX</small>  <br /> {el.level_max}{" "}
                                      </span>
                                    </div>
                                    <div className="position-absolute bottom-0 right-0">
                                      <span className="font-weight-bold">
                                        <small>MIN</small>
                                      </span>
                                    </div>
                                  </div>

                                  <div className="p-2">
                                    <table className="table table-borderless table-sm">
                                      <thead>
                                        <tr>
                                          <th scope="col" className="text-red">
                                            <b>{el.name}</b>
                                          </th>
                                          <th scope="col"></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <th scope="row">Level</th>
                                          <th>{el.level} cm</th>
                                        </tr>
                                        <tr>
                                          <th scope="row">Weight</th>
                                          <th>{el.weight} Kg</th>
                                        </tr>
                                        <tr>
                                          <th scope="row">Last Update</th>
                                          <th>
                                            <span className={Helper(el.last_update)[1] ? "text-red font-weight-bold" : "text-success"}>
                                              {moment.utc(el.last_update).local().format('DD/MM/YYYY HH:mm:ss')}
                                            </span>
                                          </th>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}

                      <div className="col-lg-6">
                        <h4 className="text-darker">
                          List Data Chemical Area - {selectedLocationValue}
                        </h4>
                        <DataTableExtensions
                          print={false}
                          export={false}
                          exportHeaders
                          columns={columnsListData}
                          data={dailyData}
                          filter={false}
                        >
                          <DataTable pagination />
                        </DataTableExtensions>
                        <span className="badge badge-warning mt-3">
                          NB : Data ini akan direset 24 jam
                        </span>
                      </div>
                      <div className="col-lg-6">
                        <h4 className="text-darker">
                          Total Weight Chemical Area - {selectedLocationValue}
                        </h4>
                        <DataTableExtensions
                          print={false}
                          export={false}
                          exportHeaders
                          columns={columnsTotalWeight}
                          data={listTotalData}
                          filter={false}
                        >
                          <DataTable pagination />
                        </DataTableExtensions>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      {/* } */}
    </div>
  );
};

export default ChemicalArea;
