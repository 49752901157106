import React from "react";
import { Link } from 'react-router-dom'


const BreadCumb = ({pageRoot, parent, subParent, name, underPage}) => {
  return (
    <div className="header pb-2 ">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-6 col-7">
              <h6 className="h2 text-darker d-inline-block mb-0">{pageRoot}</h6>
              <nav
                aria-label="breadcrumb"
                className="d-none d-md-inline-block ml-md-4"
              >
                <ol className="breadcrumb breadcrumb-links breadcrumb-light">
                  <li className="breadcrumb-item">
                    <Link to={parent}>
                      <i className="fas fa-home text-darker" />
                    </Link>
                  </li>
                  <li className="breadcrumb-item ">
                    <Link className="text-darker" to={subParent}>{underPage}</Link>
                  </li>
                  <li className="breadcrumb-item active text-darker" aria-current="page">
                    {name}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          {/* Card FILTER */}
          <div className="row"></div>
        </div>
      </div>
    </div>
  );
};

export default BreadCumb;
