import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import API from "../../api/base_url";
import BreadCumb from "../../components/BreadCumb";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Footer from "../../components/Footer";
import Swal from "sweetalert2";

const User = () => {
  const [userData, setUserData] = useState([]);
  const [userInactivateData, setUserInactivateData] = useState([]);
  const [role, setRole] = useState(1);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [updateName, setUpdateName] = useState("");
  const [updatePhone, setUpdatePhone] = useState("");
  const [updateEmail, setUpdateEmail] = useState("");
  const [updatePassword, setUpdatePassword] = useState("");
  const [updateRole, setUpdateRole] = useState("");
  const [willUpdate, setWillUpdate] = useState("")

  let token = localStorage.token;
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  // alert
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const getData = () => {
    API.get("dashboard/master/users", { headers })
      .then(({ data:content }) => {
        const result = content.data
        let activatedUser   = result.filter((el) => el.status === "AC");
        let inActivatedUser = result.filter((el) => el.status === "DL");
        setUserData(activatedUser);
        setUserInactivateData(inActivatedUser);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      name: "No",
      cell: (row, index) => index + 1,
      width: "50px",
      // sortable: true,
    },
    {
      name: "Name",
      cell: (row) => row.name,
      // sortable: true,
    },
    {
      name: "Email",
      cell: (row) => row.email,
    },
    {
      name: "Action",
      center: true,
      cell: (row) => (
        <div className="dropdown">
          <button
            className="btn bg-pallete text-light dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Action
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <button
              id="btn_add"
              type="button"
              className="dropdown-item button-modal"
              data-toggle="modal"
              data-target="#exampleModalUpdate"
              data-action="tambah"
              onClick={() => populationDataForUpdate(row.id)}
            >
              Edit
            </button>
            <button
              type="button"
              className="dropdown-item"
              href="#"
              hidden={row.status === "AC" ? false : true}
              onClick={() => handleDelete(row.id)}
            >
              Delete
            </button>
            <button
              type="button"
              hidden={row.status === "AC" ? true : false}
              className="dropdown-item"
              href="#"
              onClick={() => console.log(row.id)}
            >
              <i className="fas fa-power-off text-success" />
              Re - Actived
            </button>
          </div>
        </div>
      ),
    },
  ];

  const getRoleInput = (e) => {
    setRole(Number(e.target.value));
  };


  const populationDataForUpdate = (id) => {
    API.get(`dashboard/master/user/${id}`, { headers })
      .then(({ data: content }) => {
        setWillUpdate(content.data.id)
        setUpdateName(content.data.name);
        setUpdatePhone(content.data.phone);
        setUpdateEmail(content.data.email);
        setUpdatePassword(content.data.password);
        setUpdateRole(Number(content.data.role_id))
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAdd = () => {
    Swal.fire({
      title: "Do you want to add new Account?",
      showCancelButton: true,
      confirmButtonText: "Add",
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          role_id: role,
          name: name,
          email: email,
          password: password,
          phone: phone,
        };
        console.log(payload);
        if (
          !payload.role_id ||
          !payload.name ||
          !payload.email ||
          !payload.password ||
          !payload.phone
        ) {
          Swal.fire("Data is Required, please fill out the form", "", "error");
        } else {
          API.post("dashboard/master/user", payload, { headers })
            .then(({ data }) => {
              setName("");
              setEmail("");
              setPassword("");
              setPhone("");
              getData();
              Swal.fire("Account resistered successfully", "", "success");
            })
            .catch((err) => {
              setName("");
              setEmail("");
              setPassword("");
              setPhone("");
              Swal.fire("error from server", "", "error");
            });
        }
      }
    });
  };

  const handleUpdate = () => {
    Swal.fire({
      title: "Do you want to Update this User?",
      showCancelButton: true,
      confirmButtonText: "Update",
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          role_id: updateRole,
          name: updateName,
          email: updateEmail,
          password: updatePassword,
          phone: updatePhone
        };
        if (!payload.name || !payload.email || !payload.password || !payload.phone || !payload.role_id) {
          Swal.fire("Data is Required, please fill out the form", "", "error");
        } else {
          API.put(`/dashboard/master/user/${willUpdate}`, payload, { headers })
            .then(({ data }) => {
              getData();
              Swal.fire("User has updated successfully", "", "success");
            })
            .catch((err) => {
              Swal.fire("error from server", "", "error");
            });
        }
      }
    });
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Do you want to Delete this User?",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        API.patch(`dashboard/master/user/${id}`, {}, { headers })
          .then(({ data }) => {
            getData();
            Toast.fire({
              icon: "success",
              title: "Delete User successfully",
            });
          })
          .catch((err) => {
            console.log(err);
            Toast.fire({
              icon: "error",
              title: "I am sorry, Delete User Failed",
            });
          });
      }
    });
  };

  return (
    <div id="wrap">
      <div>
        <Header />
        <BreadCumb
          pageRoot="Admin Panel"
          name="User"
          parent="/dashboard"
          subParent="/dashboard"
          underPage="Admin Panel"
        />

        {/* Page content */}
        <div className="container-fluid mt--6" style={{minHeight:'75vh'}}>
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-lg-6">
                      <h5 className="h3 mb-0 text-red">Account</h5>
                    </div>
                    <div className="col-lg-6">
                      <button
                        id="btn_add"
                        type="button"
                        className="float-right btn bg-pallete text-light button-modal"
                        data-toggle="modal"
                        data-target="#exampleModal"
                        data-action="tambah"
                      >
                        <i className="fas fa-plus mr-3" /> Add User
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card-body mt--3">
                    <DataTableExtensions
                      exportHeaders
                      print={false}
                      export={false}
                      columns={columns}
                      data={userData}
                    >
                      <DataTable pagination />
                    </DataTableExtensions>
                </div>
              </div>
            </div>
          </div>
          {/* Modal */}
          <div className="modal fade" id="exampleModal" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-12">
                        <h3 className="modal-title mb-5" id="exampleModalLabel">
                          Add New User
                        </h3>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label className="form-control-label">
                            Role User
                          </label>
                          <select
                            className="custom-select"
                            id="inputGroupSelect01"
                            value={role}
                            onChange={(e) => getRoleInput(e)}
                          >
                            <option value="1">Admin</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label className="form-control-label">Name</label>
                          <input
                            type="text"
                            className="form-control input-sm"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <label className="form-control-label">Email</label>
                          <input
                            type="text"
                            className="form-control"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <label className="form-control-label">Password</label>
                          <input
                            type="text"
                            className="form-control"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <label className="form-control-label">Phone</label>
                          <input
                            type="text"
                            className="form-control"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <button
                      className="btn btn-primary mt-5"
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => handleAdd()}
                    >
                      Add new account
                    </button>
                    <button
                      className="btn bg-pallete text-light mt-5"
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      Cancel
                    </button>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div id="dynamicForm" />
                </div>
              </div>
            </div>
          </div>

          {/* Modal Update */}
          <div className="modal fade" id="exampleModalUpdate"  aria-hidden="true" >
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-12">
                        <h3 className="modal-title mb-5" id="exampleModalLabel">
                          Edit User
                        </h3>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                            <label className="form-control-label">
                              Role User
                            </label>
                            <select
                              className="custom-select"
                              id="inputGroupSelect01"
                              value={updateRole}
                              onChange={(e) => getRoleInput(e)}
                            >
                              <option value={1}>Admin</option>
                            </select>
                          </div>
                        <div className="form-group">
                          <label className="form-control-label">Name</label>
                          <input
                            type="text"
                            value={updateName}
                            onChange={(e)=> setUpdateName(e.target.value)}
                            className="form-control input-sm"
                          />
                        </div>
                        <div className="form-group">
                          <label className="form-control-label">Email</label>
                          <input
                            type="text"
                            className="form-control"
                            value={updateEmail}
                            onChange={(e)=> setUpdateEmail(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <label className="form-control-label">Password</label>
                          <input
                            type="text"
                            className="form-control"
                            value={updatePassword}
                            onChange={(e)=> setUpdatePassword(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <label className="form-control-label">Phone</label>
                          <input
                            value={updatePhone}
                            type="text"
                            className="form-control"
                            onChange={(e)=> setUpdatePhone(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <button 
                    className="btn btn-warning mt-5"
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={()=> handleUpdate()}
                    >
                      EDIT
                    </button>
                    <button
                      className="btn btn-primary mt-5"
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      Cancel
                    </button>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div id="dynamicForm" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default User;
