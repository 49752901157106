import React, { useState, useEffect } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import BreadCumb from "../../components/BreadCumb";
import API from "../../api/base_url";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import Swal from "sweetalert2";
import moment from 'moment'
import ReactSpinner from "../../components/ReactSpinner";


const CanalIntake = () => {
  const [site, setSite] = useState([]);
  const [area, setArea] = useState([]);
  const [location, setLocation] = useState([]);
  const [locationSelected, setLocationSelected] = useState(""); // <-- get id Location selected to determine name Canal Intake Form
  // above is tmp for each filter
  const [dailyData, setDailyData] = useState([]);
  const [listTotalData, setListTotalData] = useState([]);
  // above is table data
  const [formWtp, setFormWtp] = useState("1"); // <-- Form WTP
  const [formDate, setFormDate] = useState(""); // <-- Form Date and Time
  const [formNameDevice, setFormNameDevice] = useState([{ name: "" }]); // <-- Form Name Canal Intake
  const [formDebit, setFormDebit] = useState(""); // <-- Form Debit Value
  const [formSatuan, setFormSatuan] = useState("m3"); // <-- Form Satuan
  const [formPump, setFormPump] = useState("")
  const [formStatus, setFormStatus] = useState("")
  // above is tmp for two way binding in form to create purpose
  const [selectedDevice, setSelectedDevice] = useState("");
  const [selectedLocationValue, setSelectedLocationValue] = useState([]);
  // above is device selected
  const [updateId, setUpdateId] = useState();
  const [updateCanalIntake, setUpdateCanalIntake] = useState([]);
  const [updateDebitValue, setUpdateDebitValue] = useState([]);
  const [updateSatuan, setUpdateSatuan] = useState([]);
  const [isLoading, setIsLoading] = useState(true)


  const token = localStorage.token;
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const columnsDaily = [
    {
      name: "No",
      cell: (row, index) => index + 1,
      width: "50px",
    },
    {
      name: "Date & Time",
      cell: (row) => moment.utc(row.recorded_at).local().format('DD/MM/YYYY HH:mm:ss'),
    },
    {
      name: "Canal Intake Name",
      cell: (row) => row.device_name,
    },
    {
      name: "Debit Value",
      cell: (row) => `${row.value} ${row.device_default_unit}`,
    },
    // {
    //   name: "Action",
    //   center: true,
    //   cell: (row) => (
    //     <div className="dropdown">
    //       <button
    //         className="btn bg-primary text-white dropdown-toggle"
    //         type="button"
    //         id="dropdownMenuButton"
    //         data-toggle="dropdown"
    //         aria-haspopup="true"
    //         aria-expanded="false"
    //       >
    //         Action
    //       </button>
    //       <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
    //         <button
    //           id="btn_add"
    //           type="button"
    //           className="dropdown-item button-modal"
    //           data-toggle="modal"
    //           data-target="#exampleModalUpdate"
    //           data-action="tambah"
    //           onClick={() => populateDataForUpdate(row.id)}
    //         >
    //           Edit
    //         </button>
    //         <button
    //           type="button"
    //           className="dropdown-item"
    //           href="#"
    //           onClick={() => handleDelete(row.id)}
    //         >
    //           Delete
    //         </button>
    //       </div>
    //     </div>
    //   ),
    // },
  ];

  const columnsListTotalData = [
    {
      name: "No",
      cell: (row, index) => index + 1,
      width: "50px",
      // sortable: true,
    },
    {
      name: "Date & Time",
      cell: (row) => moment.utc(row.recorded_at).local().format('DD/MM/YYYY HH:mm:ss'),
      // sortable: true,
    },
    {
      name: "Canal Intake Name",
      cell: (row) => row.device_name,
    },
    {
      name: "Debit Value",
      cell: (row) => `${row.value} ${row.device_default_unit}`,
    },
    {
      name: "Total Value",
      cell: (row) => `${row.total_value} ${row.device_default_unit}`,
    },
  ];

  // function to populated data needed
  const getSite = () => {
    API.get("utility/master/data/site", { headers })
      .then(({ data: content }) => {
        setSite(content.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getArea = () => {
    API.get("utility/master/data/area/1", { headers })
      .then(({ data: content }) => {
        setArea(content.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLocation = () => {
    API.get("utility/master/data/location/7", { headers })
      .then(({ data: content }) => {
        setLocation(content.data);
        setSelectedLocationValue(content.data[0].name);
        setLocationSelected(content.data[0].id);
        getDevice(content.data[0].id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDevice = (id) => {
    API.get(`utility/master/data/device/${id}`, { headers })
      .then(({ data: content }) => {
        if (content.data.length !== 0) {
          setSelectedDevice(content.data[0].id);
          setFormNameDevice(content.data);
          setSelectedLocationValue(content.data[0].location_name);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDailyData = () => {
    API.get("utility/canal/intake/dailys", { headers })
      .then(({ data: content }) => {
        setDailyData(content.data);
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getListTotalData = () => {
    API.get("utility/canal/intake/report", { headers })
      .then(({ data: content }) => {
        setListTotalData(content.data);
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // end of function to populated data needed

  // Location Result in Filter
  const handleGetDevice = (id) => {
    if (!id) {
      setFormWtp("1");
      setFormNameDevice("");
      setFormDebit("");
      setFormSatuan("m3");
      setLocationSelected("");
    } else {
      setLocationSelected(id);
      getDevice(id);
    }
  };

  useEffect(() => {
    getSite();
    getArea();
    getLocation();
    getDailyData();
    getListTotalData();
  }, []);

  const handleSubmit = () => {
    Swal.fire({
      title: "Do you want to add ?",
      showCancelButton: true,
      confirmButtonText: "Add",
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          device_id: selectedDevice,
          recorded_at_milis: Math.round(new Date(formDate).getTime()),
          value: formDebit,
          pump: formPump,
          status: formStatus
        };
        if (
          !payload.device_id ||
          !payload.recorded_at_milis ||
          !payload.value ||
          !payload.pump ||
          !payload.status
        ) {
          Swal.fire("Data is Required, please fill out the form", "", "error");
        } else {
          API.post("utility/canal/intake", payload, { headers })
            .then(({ data }) => {
              setFormWtp("1");
              setFormDebit("");
              setFormDate("");
              setFormPump("")
              setFormStatus("")
              getDailyData();
              getListTotalData();
              Swal.fire("added successfully", "", "success");
            })
            .catch((err) => {
              Swal.fire("error from server", "", "error");
            });
        }
      }
    });
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Do you want to Delete this Record",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        API.patch(`utility/canal/intake/${id}`, {}, { headers })
          .then(({ data }) => {
            getDailyData();
            Toast.fire({
              icon: "success",
              title: "Delete Data successfully",
            });
          })
          .catch((err) => {
            console.log(err);
            Toast.fire({
              icon: "error",
              title: "Delete Data error",
            });
          });
      }
    });
  };

  const populateDataForUpdate = (id) => {
    API.get(`utility/canal/intake/daily/${id}`, { headers })
      .then(({ data: content }) => {
        setUpdateId(content.data.id);
        setUpdateCanalIntake(content.data.device_name);
        setUpdateDebitValue(content.data.value);
        setUpdateSatuan(content.data.device_default_unit);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUpdate = () => {
    Swal.fire({
      title: "Do you want to Update?",
      showCancelButton: true,
      confirmButtonText: "Update",
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          device_id: 1,
          recorded_at_milis: new Date().getTime(),
          value: updateDebitValue,
        };
        if (!payload.device_id || !payload.value) {
          Swal.fire("Data is Required, please fill out the form", "", "error");
        } else {
          API.put(`utility/canal/intake/${updateId}`, payload, { headers })
            .then(({ data }) => {
              setUpdateDebitValue("");
              getDailyData();
              Swal.fire("data updated successfully", "", "success");
            })
            .catch((err) => {
              // setUpdateQuestion("");
              setUpdateDebitValue("");
              Swal.fire("error from server", "", "error");
            });
        }
      }
    });
  };

  return (
    <div id="wrap">
      {/* {isLoading ? <ReactSpinner/>:  */}
      <div>
        <Header />
        {/* ENDSIDEBAR */}
        <BreadCumb
          pageRoot="Dashboard"
          name="Canal Intake"
          parent="/dashboard"
          subParent="/dashboard"
          underPage="Utility"
        />
        <div className="container-fluid mt--6 ">
          <div className="card shadowTailwind">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-4">
                  <label className="form-control-label textPallete">Site</label>
                  <select
                    className="form-control text-darker"
                    id="channel"
                    name="channel"
                    data-toggle="select"
                    disabled
                    value={1}
                  >
                    {site.length === 0 ? (
                      <></>
                    ) : (
                      site.map((el, idx) => (
                        <option value={el.id} key={idx}>
                          {el.name}
                        </option>
                      ))
                    )}
                  </select>
                </div>
                <div className="col-lg-4">
                  <label className="form-control-label textPallete">Area</label>
                  <select
                    className="form-control text-darker"
                    disabled
                    id="devices"
                    name="devices"
                    value={7}
                    data-toggle="select"
                  >
                    {area.length === 0 ? (
                      <></>
                    ) : (
                      area.map((el, idx) => (
                        <option value={el.id} key={idx}>
                          {el.name}
                        </option>
                      ))
                    )}
                  </select>
                </div>
                <div className="col-lg-4">
                  <label className="form-control-label textPallete">
                    Location
                  </label>
                  <select
                    className="form-control text-darker"
                    id="devices"
                    name="devices"
                    data-toggle="select"
                    value={locationSelected}
                    disabled
                    onChange={(e) => handleGetDevice(e.target.value)}
                  >
                    {location.length === 0 ? (
                      <></>
                    ) : (
                      location.map((el, idx) => (
                        <option value={el.id} key={idx}>
                          {el.name}
                        </option>
                      ))
                    )}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Page content */}
        <div className="container-fluid ">
          <div className="row mt-3">
            <div className="col-lg-12">
              <div className="card shadowTailwind">
                <div className="card-header">
                  <h4 className="mb-0 font-weight-bold text-red">
                    {selectedLocationValue}
                  </h4>
                </div>
                <div className="card-body">
                  <div className="row">
                    {/* <div className="col-lg-4">
                      <h4>Form Input </h4>
                      <div className="form-group">
                        <label
                          className="form-control-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Date &amp; Time
                        </label>
                        <input
                          className="form-control"
                          name="ends_at"
                          type="datetime-local"
                          id="ends_at"
                          value={formDate}
                          onChange={(e) => setFormDate(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <select
                          className="form-control"
                          onChange={(e) => setSelectedDevice(e.target.value)}
                          hidden
                        >
                          {formNameDevice.length === 0 ? (
                            <></>
                          ) : (
                            formNameDevice.map((el, idx) => (
                              <option
                                value={el.id}
                                key={idx}
                              >{`${el.device_type_name} - ${el.name}`}</option>
                            ))
                          )}
                        </select>
                      </div>

                      <div className="form-group">
                        <label className="form-control-label">Debit Value</label>
                        <div className="input-group mb-3">
                          <input type="text" className="form-control"  value={formDebit}  aria-describedby="basic-addon2" onChange={(e) => setFormDebit(e.target.value)} />
                          <div className="input-group-append">
                            <span className="input-group-text bg-gray text-white" id="basic-addon2">m<sup>3</sup> </span>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="form-control-label">Pump</label>
                        <input type="text" className="form-control" value={formPump} onChange={(e) => setFormPump(e.target.value)} />
                      </div>

                      

                      <div className="form-group">
                        <label className="form-control-label">
                          Status
                        </label>
                        <div>
                          <div>
                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="radio"  name="inlineRadioOptions" id="inlineRadio1"  value="START" onChange={(e) => setFormStatus(e.target.value)} />
                              <label className="form-check-label" htmlFor="inlineRadio1">Start</label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="radio"  name="inlineRadioOptions" id="inlineRadio2"  value="STOP" onChange={(e) => setFormStatus(e.target.value)} />
                              <label className="form-check-label" htmlFor="inlineRadio2">Stop</label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <button
                        className="btn bg-primary text-white"
                        onClick={() => handleSubmit()}
                      >
                        Submit
                      </button>
                    </div> */}

                    {/* table */}
                    <div className="col-lg">
                      <h4 className="text-darker">Daily Data</h4>
                      <DataTableExtensions
                        print={false}
                        export={false}
                        exportHeaders
                        columns={columnsDaily}
                        data={dailyData}
                        filter={false}
                      >
                        <DataTable pagination />
                      </DataTableExtensions>
                      <span className="badge badge-warning  mt-3">
                        NB : Data ini akan direset 24 jam
                      </span>
                    </div>
                    <div className="col-lg-12 mt-5">
                      <h4 className="text-darker">List Total Data </h4>
                      <DataTableExtensions
                        exportHeaders
                        print={false}
                        export={false}
                        columns={columnsListTotalData}
                        data={listTotalData}
                        filter={false}
                      >
                        <DataTable pagination />
                      </DataTableExtensions>
                    </div>
                    {/* end of table */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modal Update */}
        <div className="modal fade" id="exampleModalUpdate" aria-hidden="true">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12">
                      <h4 className="modal-title mb-5" id="exampleModalLabel">
                        Update Daily Data
                      </h4>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label
                          className="form-control-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Date &amp; Time
                        </label>
                        <input
                          className="form-control"
                          name="ends_at"
                          type="datetime-local"
                          id="ends_at"
                          value={formDate}
                          onChange={(e) => setFormDate(e.target.value)}
                        />
                      </div>

                      <div className="form-group">
                        <label className="form-control-label">Debit Value</label>
                        <div className="input-group mb-3">
                          <input type="text" className="form-control" value={updateDebitValue} aria-describedby="basic-addon2" onChange={(e) => setUpdateDebitValue(e.target.value)} />
                          <div className="input-group-append">
                            <span className="input-group-text bg-gray text-white" id="basic-addon2">m<sup>3</sup> </span>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="form-control-label">
                          Status
                        </label>
                        <div>
                          <div>
                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" defaultValue="option1" />
                              <label className="form-check-label" htmlFor="inlineRadio1">Start</label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" defaultValue="option2" />
                              <label className="form-check-label" htmlFor="inlineRadio2">Stop</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    className="btn btn-warning mt-5"
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => handleUpdate()}
                  >
                    Update
                  </button>
                  <button
                    className="btn btn-primary mt-5"
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    Cancel
                  </button>
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div id="dynamicForm" />
              </div>
            </div>
          </div>
        </div>
        {/* End of Modal Update */}
      </div>
      {/* } */}
    </div>
  );
};

export default CanalIntake;
