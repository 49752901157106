import React from 'react';
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import {FormControl} from 'react-bootstrap'
import moment from "moment-timezone"
import Moment from 'react-moment';



const DatePicker = ({take, takeTimeExcel}) => {
    let now = new Date();
    let start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0,0,0,0))
    let end = moment(start).add("days").subtract("seconds");
    let [starts, setStart] = React.useState(start)
    let [ends, setEnd] = React.useState(end)
    let ranges = {
        "Today Only": [moment(start), moment(end)],
        "Yesterday Only": [moment(start).subtract(1, "days"), moment(end).subtract(1, "days")],
        "3 Days": [moment(start).subtract(3, "days"), moment(end)]
    }
    let local = {
        "format":"DD-MM-YYYY HH:mm",
        "sundayFirst" : false
    }
    let maxDate = moment(start).add(24, "hour")

    let applyCallback = (startDate, endDate) => {
        setStart(startDate)
        setEnd(endDate)
        take(`${startDate.utc().format("YYYY-MM-DD HH:mm:ss")}/${endDate.utc().format("YYYY-MM-DD HH:mm:ss")}`)
        takeTimeExcel(`${startDate.local().format("DD-MM-YYYY HH:mm:ss")}/${endDate.local().format("DD-MM-YYYY HH:mm:ss")}`)
    }
  return (
        <div>
            <DateTimeRangeContainer 
                ranges={ranges}
                start={starts}
                end={ends}
                local={local}
                maxDate={maxDate}
                applyCallback={applyCallback}
            >    
                <FormControl
                id="formControlsTextB"
                type="text"
                label="Text"
                placeholder="Enter text here"
                readOnly
                value={`${starts.local().format("DD-MM-YYYY HH:mm")} - ${ends.local().format("DD-MM-YYYY HH:mm")}`}
                /> 
            </DateTimeRangeContainer>
        </div>
  )
}

export default DatePicker