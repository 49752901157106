import React, { useState, useEffect  } from "react";
import Header from "../../components/Header";
import BreadCumb from "../../components/BreadCumb";
import API from "../../api/base_url";
import Swal from "sweetalert2";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import ReactSpinner from "../../components/ReactSpinner";

const ChemicalArea = () => {
  const [site, setSite] = useState([]);
  const [area, setArea] = useState([]);
  const [location, setLocation] = useState([]);
  const [idRender, setIdRender] = useState(4)
  const [locationSelected, setLocationSelected] = useState(""); 
  const [listData, setListData] = useState([]);
  const [pending, setPending] = useState(true);
  // form
  const [min, setMin] = useState("")
  const [med, setMed] = useState("")
  const [max, setMax] = useState("")
  const [tankName, setTankName] = useState("A")
  // update
  const [updateMin, setUpdateMin] = useState("")
  const [updateMed, setUpdateMed] = useState("")
  const [updateMax, setUpdateMax] = useState("")
  const [updateTankName, setUpdateTankName] = useState("A")
  const [idUpdate, setIdUpdate] = useState("")
  const [isLoading, setIsLoading] = useState(true)

  const token = localStorage.token;
const headers = {
  Authorization: `Bearer ${token}`,
};

const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  background:"#c51a1a",
  color:"#ffffff",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

  const columnsListData = [
    {
      name: "No",
      cell: (row, index) => index + 1,
      width: "50px",
    },
    {
      name: "Name Chemical Area",
      cell: (row) => row.location_id == 4 ? "NaOcl Ab" : row.location_id == 6 ? "Polymer AB" : "PAC AB",
    },
    {
      name: "Name Tank",
      cell: (row) => row.tank,
    },
    {
      name: "Level Min",
      cell: (row) => row.min ,
    },
    {
      name: "Level Mid",
      cell: (row) => row.med,
    },
    {
      name: "Level Weight",
      cell: (row) => row.max,
    },
    {
      name: "Action",
      center:true,
      cell:(row)=><div className="dropdown">
      <button
        className="btn bg-primary text-white dropdown-toggle"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        Action
      </button>
      <div
        className="dropdown-menu"
        aria-labelledby="dropdownMenuButton"
      >
        <button
          id="btn_add"
          type="button"
          className="dropdown-item button-modal"
          data-toggle="modal"
          data-target="#exampleModalUpdate"
          data-action="tambah"
          onClick={() => populateDataForUpdate(row.id)}
        >
          Edit
        </button>
        <button
          type="button"
          className="dropdown-item"
          href="#"
          onClick={() => handleDelete(row.id)}
        >
          Delete
        </button>
      </div>
    </div>,
    },
  ];

  const handleGetLocation = (id) => {
    setIdRender(id)
   }

  const getSite = () => {
    API.get("utility/master/data/site", { headers })
      .then(({ data: content }) => {
        setSite(content.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getArea = () => {
    API.get("utility/master/data/area/1", { headers })
      .then(({ data: content }) => {
        setArea(content.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLocation = () => {
    API.get(`utility/master/data/location/2`, { headers })
      .then(({ data: content }) => {
        setLocation(content.data);
        setLocationSelected(content.data[0].name)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getData = () => {
    API.get("dashboard/setting/bmals", { headers })
      .then(({ data: content }) => {
        setListData(content.data);
        setPending(false)
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAdd = () => {
    Swal.fire({
      title: "Do you want to add new Data?",
      showCancelButton: true,
      confirmButtonText: "Add",
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
         location_id:Number(idRender),
         tank:tankName,
         min: Number(min),
         med: Number(med),
         max: Number(max)
        };
        if (
          !payload.min ||
          !payload.med ||
          !payload.max 
        ) {
          Swal.fire("Data is Required, please fill out the form", "", "error");
        } else {
          API.post("dashboard/setting/bmal", payload, { headers })
            .then(({ data }) => {
              getData()
              setMin("")
              setMed("")
              setMax("")
              Swal.fire("Data added successfully", "", "success");
            })
            .catch((err) => {
              setMin("")
              setMed("")
              setMax("")
              Swal.fire("error from server", "", "error");
            });
        }
      }
    });
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Do you want to Delete this Record",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        console.log(id,'--->')
        API.delete(`dashboard/setting/bmal/${id}`, {}, { headers })
          .then(({ data }) => {
            getData()
            Toast.fire({
              icon: "success",
              title: "Delete Data successfully",
            });
          })
          .catch((err) => {
            console.log(err);
            Toast.fire({
              icon: "error",
              title: "Delete Data error",
            });
          });
      }
    });
  };

  const populateDataForUpdate = (id) => {
    API.get(`/dashboard/setting/bmal/${id}`, { headers })
      .then(({ data: content }) => {
        setIdUpdate(content.data.id)
        setUpdateMin(content.data.min)
        setUpdateMed(content.data.med)
        setUpdateMax(content.data.max)
        setUpdateTankName(content.data.tank)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUpdate = () => {
    Swal.fire({
      title: "Do you want to Update?",
      showCancelButton: true,
      confirmButtonText: "Update",
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          location_id:Number(idRender),
          tank:updateTankName,
          min: Number(updateMin),
          med: Number(updateMed),
          max: Number(updateMax)
        };
        if (
          !payload.min ||
          !payload.med ||
          !payload.max
          ) {
          Swal.fire("Data is Required, please fill out the form", "", "error");
        } else {
          API.put(`dashboard/setting/bmal/${idUpdate}`, payload, { headers })
            .then(({ data }) => {
              getData()
              setUpdateMin("")
              setUpdateMed("")
              setUpdateMax("")
              setUpdateTankName("")
              setIdUpdate("")
              Swal.fire("data updated successfully", "", "success");
            })
            .catch((err) => {
              setUpdateMin("")
              setUpdateMed("")
              setUpdateMax("")
              setUpdateTankName("")
              setIdUpdate("")
              Swal.fire("error from server", "", "error");
            });
        }
      }
    });
  };

  useEffect(() => {
    getSite();
    getArea();
    getLocation();
    getData()
  }, []);


  return (
    <div id="wrap">
      {/* {isLoading ? <ReactSpinner/>: */}
      <>
      <div style={{minHeight:'90vh'}}>
        <Header/>
        <BreadCumb pageRoot="Setting BMAL" name="Chemical Area" parent="/dashboard" subParent="/dashboard" underPage="Setting BMAL" />

        <div className="container-fluid mt--6">
          <div className="card shadowTailwind">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-4">
                  <label className="form-control-label textPallete">Site</label>
                  <select
                    className="form-control text-darker"
                    id="channel"
                    name="channel"
                    data-toggle="select"
                    disabled
                    value={1}
                  >
                    {site.length !== 0 && site.map((el, idx) => (
                      <option value={el.id}  key={idx}>
                        {el.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-lg-4">
                  <label className="form-control-label textPallete">Area</label>
                  <select
                    className="form-control text-darker"
                    disabled
                    id="devices"
                    name="devices"
                    value={2}
                    data-toggle="select"
                  >
                    {area.length !== 0 && area.map((el, idx) => (
                      <option value={el.id}  key={idx}>
                        {el.name}
                      </option>
                    ))}
                  </select>
                </div>
                
                <div className="col-lg-4">
                  <label className="form-control-label textPallete">
                    Location
                  </label>
                  <select
                    className="form-control text-darker"
                    id="devices"
                    name="devices"
                    data-toggle="select"
                    value={idRender}
                    onChange={(e) => handleGetLocation(e.target.value)}
                  >
                    {location.length !== 0 && location.map((el, idx) => (
                      <option value={el.id} key={idx}>
                        {el.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
       
        {/* Page content */}
        <div className="container-fluid">
          <div className="row mt-3">
            <div className="col-lg-12">
            <div className="card">
                <div className="card-body">
                <div className="col-lg-12">
                      <div className="row mb-5">
                        <div className="col-lg-6">
                          <h3 className=" mb-0 text-red">List Data BMAL Chemical Area</h3>
                        </div>
                        <div className="col-lg-6">
                          <button
                            id="btn_add"
                            type="button"
                            className="float-right btn bg-pallete text-white button-modal"
                            data-toggle="modal"
                            data-target="#exampleModal"
                            data-action="tambah"
                          >
                            <i className="fas fa-plus mr-3" /> Add Data
                          </button>
                        </div>
                      </div>
                      {/* table */}
                      <DataTableExtensions
                        print={false}
                        export={false}
                        exportHeaders
                        columns={columnsListData}
                        data={listData}
                        filter={false}
                      >
                        <DataTable pagination progressPending={pending} />
                      </DataTableExtensions>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      <div className="modal fade" id="exampleModal" aria-hidden="true">
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-12">
                        <div>
                        <div className="card-header">
                          <h3 className="text-red"><small>Form Input BMAL Chemical Area</small></h3>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Tank Name
                                </label>
                                <div>
                                  <div>
                                    <div className="form-check form-check-inline" >
                                      <input className="form-check-input" type="radio" onChange={() => {setTankName("A")}} name="inlineRadioOptions" value={tankName} id="inlineRadio1" checked={tankName == "A"}  />
                                      <label className="form-check-label" htmlFor="inlineRadio1">A</label>
                                    </div>
                                    <div className="form-check form-check-inline" >
                                      <input className="form-check-input" type="radio" onChange={() => {setTankName("B")}} name="inlineRadioOptions" value={tankName} id="inlineRadio2" checked={tankName == "B"} />
                                      <label className="form-check-label" htmlFor="inlineRadio2">B</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-3">
                            <div className="form-group">
                              <label className="form-control-label textPallete">
                                Level Min
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Level Min"
                                value={min}
                                onChange={(e)=> setMin(e.target.value)}
                              />
                              </div>
                            </div>

                            <div className="col-lg-3">
                              <div className="form-group">
                                <label className="form-control-label textPallete">
                                  Level Med
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Level Mid"
                                  value={med}
                                  onChange={(e)=> setMed(e.target.value)}
                                />
                              </div>
                            </div>

                            <div className="col-lg-3">
                              <div className="form-group">
                                <label className="form-control-label textPallete">
                                  Level Max
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Level Max"
                                  value={max}
                                  onChange={(e)=> setMax(e.target.value)}
                                />
                              </div>
                            </div>
                          
                          </div>
                        </div>
                        </div>
                      </div>
                    </div>
                    <button
                      className="btn btn-primary mt-5"
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => handleAdd()}
                    >
                      Add new Data
                    </button>
                    <button
                      className="btn bg-pallete text-white mt-5"
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      Cancel
                    </button>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div id="dynamicForm" />
                </div>
              </div>
            </div>
      </div>
      {/* update form modal */}
      <div className="modal fade" id="exampleModalUpdate" aria-hidden="true">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <div className="container-fluid">
                <div className="row">
                      <div className="col-lg-12">
                        <div>
                        <div className="card-header">
                          <h3 className="text-red"><small>Update Input BMAL Chemical Area</small></h3>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Tank Name
                                </label>
                                <div>
                                  <div>
                                    <div className="form-check form-check-inline" >
                                      <input className="form-check-input" type="radio" onChange={() => {setUpdateTankName("A")}} name="inlineRadioOptions" value={updateTankName} id="inlineRadio1" checked={updateTankName == "A"}  />
                                      <label className="form-check-label" htmlFor="inlineRadio1">A</label>
                                    </div>
                                    <div className="form-check form-check-inline" >
                                      <input className="form-check-input" type="radio" onChange={() => {setUpdateTankName("B")}} name="inlineRadioOptions" value={updateTankName} id="inlineRadio2" checked={updateTankName == "B"} />
                                      <label className="form-check-label" htmlFor="inlineRadio2">B</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-3">
                            <div className="form-group">
                              <label className="form-control-label textPallete">
                                Level Min
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Level Min"
                                value={updateMin}
                                onChange={(e)=> setUpdateMin(e.target.value)}
                              />
                              </div>
                            </div>

                            <div className="col-lg-3">
                              <div className="form-group">
                                <label className="form-control-label textPallete">
                                  Level Med
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Level Mid"
                                  value={updateMed}
                                  onChange={(e)=> setUpdateMed(e.target.value)}
                                />
                              </div>
                            </div>

                            <div className="col-lg-3">
                              <div className="form-group">
                                <label className="form-control-label textPallete">
                                  Level Max
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Level Max"
                                  value={updateMax}
                                  onChange={(e)=> setUpdateMax(e.target.value)}
                                />
                              </div>
                            </div>
                          
                          </div>
                        </div>
                        </div>
                      </div>
                    </div>
                 
                  <button
                    className="btn btn-warning mt-5"
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => handleUpdate()}
                  >
                    Update
                  </button>
                  <button
                    className="btn btn-primary mt-5"
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    Cancel
                  </button>
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div id="dynamicForm" />
              </div>
            </div>
          </div>
      </div>
      </>
{/* } */}
    </div>
  );
};

export default ChemicalArea;
