import React, { useEffect, useState } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import API from "../api/base_url";

const Sidebar = () => {
  const [privilege, setPrivilage] = useState([]);
const location = useLocation(); 
  const token = localStorage.token;
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const getData = () => {
    API.get("dashboard/account/privilege", { headers })
      .then(({ data: content }) => {
        setPrivilage(content.data.privilege_category);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <nav
      className="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white a"
      id="sidenav-main"
    >
      <div className="scrollbar-inner">
        {/* Brand */}
        <div className="sidenav-header d-flex align-items-center">
          <Link className="navbar-brand" to="utility/dashboard">
            <img
              src="/assets/img/brand/logotiot.png"
              className="navbar-brand-img"
              alt="..."
            />
          </Link>
          <div className="ml-auto d-none d-md-none  d-lg-block">
            <div
              className="sidenav-toggler d-none d-xl-block"
              data-action="sidenav-unpin"
              data-target="#sidenav-main"
            >
              <div className="hidden-lg d-none d-md-none sidenav-toggler-inner">
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
              </div>
            </div>
          </div>
        </div>
        <div className="navbar-inner">
          <div className="collapse navbar-collapse" id="sidenav-collapse-main">
            {/* Start of Role Menu */}
            <ul className="navbar-nav">
              {privilege.length === 0
                ? ""
                : privilege.map((el, idx) => (
                  <li className="nav-item" key={idx}>
                    <a
                      className="nav-link textPallete"
                      href={`#${el.code}`}
                      data-toggle="collapse"
                      role="button"
                      aria-expanded={el.code === "IC-SM" ? "true" : "false"}
                      aria-controls={el.code}
                    >
                      <i className={el.icon} />
                      <span className="nav-link-text text-darker ">{el.name}</span>
                    </a>

                    <div className={el.code === "IC-SM" ? "collapse show" : "collapse"} id={el.code}>
                      <ul className="nav nav-sm flex-column">
                        {el.privilege_category.map((elChild, idxChild) => (
                          <li className="nav-item " key={idxChild}>
                            <NavLink
                              to={elChild.url}
                              className="nav-link"
                              style={({ isActive }) => ({
                                background: isActive ? "#b2060012" : "#fff",
                                borderRight: isActive
                                  ? "10px solid #B20600"
                                  : "",
                                borderRadius: isActive ? "0 3px 3px 0" : "",
                              })}
                            >
                              <span className="sidenav-mini-icon"> D </span>
                              <span className="sidenav-normal" style={{ color: '#000' }}>
                                {" "}
                                {elChild.name}{" "}
                              </span>
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </li>
                ))}
            </ul>
            {/* End of Role Menu */}
          </div>
        </div>
        {/* END SIDEBAR */}
      </div>
    </nav>
  );
};

export default Sidebar;
