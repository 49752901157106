import React, {useState, useEffect} from "react";
import ChartExample from "../../components/ChartExample";
import DatePicker from "../../components/DatePicker";
import BreadCumb from "../../components/BreadCumb";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import API from "../../api/base_url";
import DataTable from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions';
import moment from 'moment-timezone'
import Swal from "sweetalert2";
import ExportListFlowrate from "../../components/ExportExcelClearator/ExportListFlowrate";
import ExportListTotalizer from "../../components/ExportExcelClearator/ExportListTotalizer";
import ReactSpinner from "../../components/ReactSpinner";
import Moment from 'react-moment';




const Clearator = () => {
  let start =  moment().startOf('day').utc().format("YYYY-MM-DD HH:mm:ss")
  let end = moment().endOf('day').utc().format("YYYY-MM-DD HH:mm:ss")

  const token = localStorage.token;
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    background:"#c51a1a",
    color:"#ffffff",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const columnsListData = [
    {
      name: "No",
      cell: (row, index) => index + 1,
      width: "50px",
    },
    {
      name: 'Sludge Drain Name',
      cell: (row) => row.name,
    },
    {
      name: 'Flowrate',
      cell: (row) => row.flowrate,
    },
    {
      name: 'Totalizer',
      cell: (row) => row.totalizer,
    },
    {
      name: 'Data & Time',
      cell: (row) => <Moment format="DD-MM-YYYY HH:mm:ss">{row.recorded_at}</Moment>,
    },
  ];


  // container populate data
  const [site, setSite] = useState([]);
  const [area, setArea] = useState([]);
  const [location, setLocation] = useState([]);
  const [chartFlowRateData, setChartFlowRateData] = useState([]);
  const [chartClearatorData, setChartClearatorData] = useState([]);
  const [listData, setListData] = useState([])
  const [locationSelected, setLocationSelected] = useState(""); // <-- get id Location selected to determine render data
  const [dateRangePickerValue, setDateRangePickerValue] = useState(`${start}/${end}`);
  const [dateRangePickerDisplay, setDateRangePickerDisplay] = useState(`${start}/${end}`);
  const [pending, setPending] = useState(true);
  const [isLoading, setIsLoading] = useState(true)

  // end container populated

  // function to populated data needed
  const getSite = () => {
    API.get("utility/master/data/site", { headers })
      .then(({ data: content }) => {
        setSite(content.data);
      })
      .catch((err) => {
        console.log(err);
      });
   };

  const getArea = () => {
  API.get("utility/master/data/area/1", { headers })
    .then(({ data: content }) => {
      setArea(content.data);
    })
    .catch((err) => {
      console.log(err);
    });
  };

  const getLocation = () => {
    API.get(`utility/master/data/location/8`, { headers })
      .then(({ data: content }) => {
        setLocation(content.data);
      })
      .catch((err) => {
        console.log(err);
      });
   };
  // end container populated

  const getListData = () => {
    API.get(`report/clearator/${dateRangePickerValue}`, { headers })
      .then(({ data: content }) => {
        let flowrateTmps = []
        let clearatorTmps = []

        setListData(content.data.list_data)

        content.data.flow_rate.report_device_value_trend.forEach(el => {
          flowrateTmps.push({x:moment.utc(el.report_device_value_trend_time).local().format('DD/MM/YYYY HH:mm:ss'), y:el.report_device_value_trend_value})
        });

        content.data.totalizer.report_device_value_trend.forEach(el => {
          clearatorTmps.push({x:moment.utc(el.report_device_value_trend_time).local().format('DD/MM/YYYY HH:mm:ss'), y:el.report_device_value_trend_value})
        });

        setChartFlowRateData(flowrateTmps);
        setChartClearatorData(clearatorTmps);

        setPending(false)
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err);
      });
   }

   const setDateRangePickerValueFunc = (val) => {
    setDateRangePickerValue(val)
   }
  
   const setDateRangePickerValueExcelFunc = (val) => {
    setDateRangePickerDisplay(val)
   }
   
   const handleFilterSubmit = () => {
    setListData([])
    setChartClearatorData([])
    setChartFlowRateData([])
    getListData()
    Toast.fire({
      icon: "success",
      title: "Filter Data Request",
    });
   }
  
  useEffect(() => {
    getSite()
    getArea()
    getLocation()
    getListData()
  }, [])

  return (
    <div id="wrap">
      {/* {isLoading ? <ReactSpinner/>: */}
        <div >
          <Header/>
          <BreadCumb pageRoot="Report & Analisis" name="Clearator" parent="/dashboard" subParent="/dashboard" underPage="Report Analisis" />
          <div className="container-fluid mt--6">
            <div className="card shadowTailwind">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-3">
                    <label className="form-control-label textPallete">Site</label>
                    <select
                      className="form-control text-darker"
                      id="channel"
                      name="channel"
                      data-toggle="select"
                      disabled
                      value={1}
                    >
                      {
                        site.length === 0 ? (<></>) : (
                          site.map((el, idx) => (
                            <option value={el.id}  key={idx}>{el.name}</option>
                          ))
                        )
                      }
                    </select>
                  </div>
                  <div className="col-lg-3">
                    <label className="form-control-label textPallete">Area</label>
                    <select
                      className="form-control text-darker"
                      disabled
                      id="devices"
                      name="devices"
                      value={8}
                      data-toggle="select"
                    >
                      {
                        area.length === 0 ? (<></>) : (
                          area.map((el, idx) => (
                            <option value={el.id}  key={idx}>{el.name}</option>
                          ))
                        )
                      }
                    </select>
                  </div>
                  <div className="col-lg-3">
                    <label className="form-control-label textPallete">Location</label>
                    <select
                      className="form-control text-darker"
                      id="devices"
                      name="devices"
                      data-toggle="select"
                      value={locationSelected}
                      disabled
                      onChange={(e)=> console.log(e.target.value)}
                    >
                      {/* <option value="" >-- Select Location --</option> */}
                      {
                        location.length === 0 ? (<></>) : (
                          location.map((el, idx) => (
                            <option value={el.name}  key={idx}>{el.name}</option>
                          ))
                        )
                      }
                    </select>
                  </div>
                <div className="col-lg-2">
                  <label className="form-control-label textPallete">Date Range</label>
                  <DatePicker take={(val) => setDateRangePickerValueFunc(val)} takeTimeExcel={(val) => setDateRangePickerValueExcelFunc(val)} />
                </div>
                <div className="col-lg-1 p-1">
                  <label className="form-control-label textPallete">&nbsp;</label> <br />
                  <button className="btn bg-pallete text-white" onClick={() => handleFilterSubmit()}>
                    <span className="fa fa-search mr-2" /> Filter
                  </button>
                </div>
                </div>
              </div>
            </div>
          </div>
          {/* filter */}
          {/* <div className="container-fluid mt--3">
            <div className="card shadowTailwind">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-8">
                    <label className="form-control-label textPallete">Date Range</label>
                    <DatePicker take={(val)=> setDateRangePickerValueFunc(val)} takeTimeExcel={(val) => setDateRangePickerValueExcelFunc(val)} />
                  </div>
                  <div className="col-lg-4">
                    <label className="form-control-label textPallete">&nbsp;</label> <br />
                    <button className="btn bg-pallete text-white" onClick={()=> handleFilterSubmit()}>
                      <span className="fa fa-search mr-2" /> Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* Page content */}
          <div className="container-fluid">
            <div className="row mt-3">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="mb-0 text-red">Flowrate</h4>
                  </div>
                  <div className="col-lg-12" >
                    <ChartExample streaming={chartFlowRateData} />
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="mb-0 text-red">Totalizer</h4>
                  </div>
                  <div className="col-lg-12" >
                    <ChartExample streaming={chartClearatorData} />
                  </div>
                </div>
              </div>
                <div className="col-lg-12">
                  <div className="card">
                    <DataTableExtensions
                      print={false}
                      export={false}
                      exportHeaders
                      columns={columnsListData}
                      data={listData}
                      filter={false}
                    >
                      <DataTable pagination progressPending={pending} />
                    </DataTableExtensions>
                  </div>
                </div>
            </div>
          </div>
        </div>
      {/* } */}
    </div>
  );
};

export default Clearator;
