import React, {useState, useEffect} from "react";
import ChartExample from "../../components/ChartExample";
import DatePicker from "../../components/DatePicker";
import Header from "../../components/Header";
import API from "../../api/base_url";
import moment from 'moment-timezone';
import Swal from 'sweetalert2'
import BreadCumb from "../../components/BreadCumb";
import Footer from "../../components/Footer";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import ExportListBackwash from "../../components/ExportExcelBackwash/ExportListBackwash";
import ReactSpinner from "../../components/ReactSpinner";
import Moment from 'react-moment';


 

  const BackWash = () => {
    const token = localStorage.token;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
  
  
    const Toast = Swal.mixin({
      toast: true,
      position: "bottom-end",
      background:"#c51a1a",
      color:"#ffffff",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
  
    let start =  moment().startOf('day').utc().format("YYYY-MM-DD HH:mm:ss")
    let end = moment().endOf('day').utc().format("YYYY-MM-DD HH:mm:ss")
  
    const columnsListBackwash = [
      {
        name: "No",
        cell: (row, index) => index + 1,
        width: "50px",
      },
      {
        name: "Name",
        cell: (row) => row.device_name,
      },
      {
        name: "Data & Time",
        cell: (row) => <Moment format="DD-MM-YYYY HH:mm:ss">{row.recorded_at}</Moment>,
      },
      {
        name: "Value",
        cell: (row) => `${row.value} m3`,
      },
      {
        name: "Device Parameter",
        cell: (row) => row.device_parameter,
      },
    ];
    const [site, setSite] = useState([]);
    const [pending, setPending] = useState(true);
    const [dateRangePickerValue, setDateRangePickerValue] = useState(`${start}/${end}`);
    const [dateRangePickerDisplay, setDateRangePickerDisplay] = useState(`${start}/${end}`);

    const [data, setData] = useState([])
    const [chartBackwashData, setChartBackwashData] = useState([])
    const [isLoading, setIsLoading] = useState(true)


    const getSite = () => {
    API.get("utility/master/data/site", { headers })
      .then(({ data: content }) => {
        setSite(content.data);
      })
      .catch((err) => {
        console.log(err);
      });
    };

    const getListData = () => {
      API.get(`report/backwash/${dateRangePickerValue}`, { headers })
        .then(({ data: content }) => {
         setData(content.data.backwash_1.report_device_value_history)
  
         let backwashTmps = []
  
         content.data.backwash_1.report_device_value_trend.forEach(el => {
          backwashTmps.push({x:moment.utc(el.report_device_value_trend_time).local().format('DD/MM/YYYY HH:mm:ss'), y:el.report_device_value_trend_value})
         });
  
        setChartBackwashData(backwashTmps);
        setPending(false)
        setIsLoading(false)
        })
        .catch((err) => {
          console.log(err);
        });
    }

    const handleFilterSubmit = () => {
      setData([])
      setChartBackwashData([])
      getListData()
    Toast.fire({
      icon: "success",
      title: "Filter Data Request",
    });
    }

    const setDateRangePickerValueFunc = (val) => {
    setDateRangePickerValue(val)
    }

    const setDateRangePickerValueExcelFunc = (val) => {
      setDateRangePickerDisplay(val)
     }

    useEffect(() => {
      getSite()
      getListData()
    }, [])

  return (
    <div id="wrap">
      {/* {isLoading ? <ReactSpinner/>: */}
        <div>
          <Header/>
          <BreadCumb pageRoot="Report & Analisis" name="Back Wash" parent="/dashboard" subParent="/dashboard" underPage="Report Analisis" />
          
          <div className="container-fluid mt--6">
            <div className="card shadowTailwind">
              <div className="card-body">
                <div className="row">
                <div className="col-lg-12 d-flex">
                  <div className="col-lg-6">
                    <label className="form-control-label textPallete">Site</label>
                    <select
                      className="form-control text-darker"
                      id="channel"
                      name="channel"
                      data-toggle="select"
                      disabled
                      value={1}
                    >
                      {site.length === 0 ? (
                        <></>
                      ) : (
                        site.map((el, idx) => (
                          <option value={el.id} key={idx}>
                            {el.name}
                          </option>
                        ))
                      )}
                    </select>
                  </div>
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-lg-9">
                        <label className="form-control-label textPallete">Date Range</label>
                        <DatePicker take={(val) => setDateRangePickerValueFunc(val)} takeTimeExcel={(val) => setDateRangePickerValueExcelFunc(val)} />
                      </div>
                      <div className="col-lg-3">
                        <label className="form-control-label textPallete">&nbsp;</label> <br />
                        <button className="btn bg-pallete text-white" onClick={() => handleFilterSubmit()}>
                          <span className="fa fa-search mr-2" /> Filter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>

    
          {/* Page content */}
          <div className="container-fluid">
            <div className="row mt-3">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="mb-0 text-red">Backwash 1</h4>
                    <ChartExample streaming={chartBackwashData} />
                  </div>
                  <div className="card-body">
                    <div className="mchart chartcss" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                <div className="col-lg-12 mt-5">
                  <div className="d-flex justify-content-between">
                    <h4 className="text-darker">List Total Data Backwash</h4>
                    <ExportListBackwash time={dateRangePickerDisplay} location={"Backwash 1"} data={data}/>
                  </div>
                  <DataTableExtensions
                    exportHeaders
                    print={false}
                    export={false}
                    columns={columnsListBackwash}
                    data={data}
                    filter={false}
                  >
                    <DataTable pagination progressPending={pending} />
                  </DataTableExtensions>
                </div>
              </div>
            </div>
          </div>
        </div>
      {/* } */}
    </div>
  );
};

export default BackWash;
