import React, { useState } from "react";
import Moment from 'react-moment';
import moment from 'moment'
import Helper from "../utility/helper.js";
import { useStopwatch } from 'react-timer-hook';





const CardLTE = ({ powerMeter, productionTime, intakeArea, drainRinse, overflow, backwashArea, clearatorArea, productionStatus }) => {
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: true });



  let isOverTime = false
  var now = moment().format('DD/MM/YYYY HH:mm:ss');

  const productionTimeCount = (timeFromDevice) => {
    var startFrom = moment.utc(timeFromDevice).local().format('DD/MM/YYYY HH:mm:ss');
    var ms = moment(now, "DD/MM/YYYY HH:mm:ss").diff(moment(startFrom, "DD/MM/YYYY HH:mm:ss"));
    let timeRunning = ms / 1000;
    isOverTime = timeRunning > 86400;
    var d = moment.duration(ms);
    var countUp = d.format('YY [Years]. MM [Months], DD [Days] HH [Hours] mm [Minutes] ss [Second]');
    return [countUp, isOverTime]
  }



  return (
    <>
      <div className="row no-gutters">

        {/* <div className="col-lg-1">
          <div className="pt-2 mt-30">
            <div className={productionStatus ? "card shadowTailwind bg-success" : "card shadowTailwind bg-danger"}>
              <div className="card-content d-flex align-items-center" style={{ minHeight: '10vh' }}>
                <div className="card-body">
                  <div className="media">
                    <div className="media-body text-center  p-1">
                      <span className=" font-weight-bold text-center  text-white">{productionStatus ? "ON" : "OFF"}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="col-lg-3 mt--4 mt-md-0">
          <div className="pt-2">
            <div className="card shadowTailwind text-primary">
              <div className="card-content" style={{ minHeight: '10vh' }}>
                <div>
                  <div className="media">
                    <div className="media-body px-2 py-4 text-center fontTV">
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {/* <span className={productionTimeCount(productionTime)[1] ? "text-red font-weight-bold fontTV" : "fontTV text-black"}>
                          {productionTimeCount(productionTime)[0]}
                        </span> */}
                        <span className="fontTV font-weight-bold " >Production Time</span>
                        <span className={productionTimeCount(productionTime)[1] ? "text-black font-weight-bold fontTV" : "fontTV font-weight-bold text-black"}>
                          {productionTimeCount(productionTime)[0]}
                        </span>
                        <span className="fontTV font-weight-bold text-red">{moment.utc(productionTime).local().format("DD/MM/YYYY HH:mm:ss")}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-2 mt--4 mt-md-0">
          <div className="pt-2">
            <div className="card shadowTailwind bg-secondary ">
              <div className="card-content" style={{ minHeight: '10vh' }}>
                <div>
                  <div className="media">
                    <div className="media-body px-2 py-4 text-center fontTv">
                      {/* <h5 className={productionTimeCount(intakeArea.last_update)[1] ? "text-red font-weight-bold" : "text-black font-weight-bold"}>
                        {intakeArea.debit} m<sup>3</sup>
                      </h5> */}
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <br />
                        <span className="text-black fontTV mt--4" >Intake Area</span>
                        <span className={productionTimeCount(intakeArea.last_update)[1] ? "fontTV font-weight-bold text-black" : "fontTV font-weight-bold text-black"}>
                          {intakeArea.debit} m<sup>3</sup>
                        </span>
                        <span className={productionTimeCount(intakeArea.last_update)[1] ? "text-red font-weight-bold fontTV" : "fontTV font-weight-bold text-black"}>
                          <span>{moment.utc(intakeArea.last_update).local().format("DD/MM/YYYY HH:mm:ss")}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-2 mt--4 mt-md-0">
          <div className="pt-2">
            <div className="card shadowTailwind  bg-secondary text-primary">
              <div className="card-content" style={{ minHeight: '10vh' }}>
                <div>
                  <div className="media">
                    <div className="media-body px-2 py-4 text-center">
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <br />
                        <span className="text-black fontTV  mt--4">Drain / Rinse</span>
                        <span className={productionTimeCount(drainRinse.last_update)[1] ? "fontTV font-weight-bold text-black" : "fontTV font-weight-bold text-black"}>
                          {drainRinse.debit} m<sup>3</sup>
                        </span>

                        <span className={productionTimeCount(drainRinse.last_update)[1] ? "text-red font-weight-bold fontTV" : "fontTV font-weight-bold text-black"}>
                          <span>{moment.utc(drainRinse.last_update).local().format("DD/MM/YYYY HH:mm:ss")}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-2 mt--4 mt-md-0">
          <div className="pt-2">
            <div className="card shadowTailwind bg-secondary  text-primary">
              <div className="card-content" style={{ minHeight: '10vh' }}>
                <div>
                  <div className="media">
                    <div className="media-body px-3 py-4 text-center">
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <br />
                        <span className="font-weight-bold fontTV  mt--4">BackWash Area</span>
                        <span className={productionTimeCount(backwashArea.last_update)[1] ? "fontTV font-weight-bold text-black" : "fontTV font-weight-bold text-black"}>
                          {backwashArea.debit} m<sup>3</sup>
                        </span>
                        <span className={productionTimeCount(backwashArea.last_update)[1] ? "text-red font-weight-bold fontTV" : "fontTV font-weight-bold text-black"}>
                          <span>{moment.utc(backwashArea.last_update).local().format("DD/MM/YYYY HH:mm:ss")}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-3 mt--4 mt-md-0">
          <div className="pt-2">
            <div className="card shadowTailwind bg-secondary  text-primary">
              <div className="card-content" style={{ minHeight: '10vh' }}>
                <div>
                  <div className="media">
                    <div className="media-body px-2 py-4 text-center">
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <br />
                        <span className="text-black fontTV  mt--4">Over Sludge Drain</span>
                        <span className={productionTimeCount(overflow.last_update)[1] ? "fontTV font-weight-bold text-black" : "text-black font-weight-bold"}>
                          {overflow.debit} m<sup>3</sup>
                        </span>
                        <span className={productionTimeCount(overflow.last_update)[1] ? "text-red font-weight-bold fontTV" : "fontTV text-black font-weight-bold"}>
                          <span>{moment.utc(overflow.last_update).local().format("DD/MM/YYYY HH:mm:ss")}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Coba */}
      <div>
        <div className="row p-in2">
          <div className="col-lg-12">
            <div className="card bg-secondary">
              <div
                style={{ display: "flex", flexDirection: "column" }}
                className="card-body "
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "no-wrap"
                  }}
                >
                  <div className="col-lg-8 col-sm-12 d-flex flex-rap">
                    <div className="d-flex flex-lg-row" style={{ display: "flex", flexDirection: "column" }}>
                      {powerMeter.length === 0
                        ? ""
                        : powerMeter.map((el, idx) => (
                          <div className="bg-white col-lg-6 col-sm-12  rounded border border-muted shadowTailwind p-5 mb-4" key={idx}>
                            <div className="flex flex-wrap  justify-content-between">
                              <div style={{ position: "relative" }} className="media fontTV">
                                <h4 className="text-red fontTV font-weight-bold">
                                  {el.name} {" "}
                                </h4>
                                <small style={{ position: "absolute", top: -40, right: -30, fontSize: "14px" }}>
                                  <i>{el.location}</i>
                                </small>{" "}
                              </div>
                              <span style={{ fontSize: 10 }} className={productionTimeCount(el.last_update)[1] ? "text-red " : "text-success"}>
                                Last Update  {moment.utc(el.last_update).local().format('DD/MM/YYYY HH:mm:ss')}
                              </span>
                            </div>

                            <div className="row p-2">
                              <div className="col-sm-12 col-lg-12 p-3">
                                {/* batas */}
                                <div className="row bg-primaryPallete3  powerMeterThreeLayoutCard">
                                  <div className="col-lg-12 pt-2 text-center">
                                    <span className="text-primary fontTV">Voltage</span>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="bg-primaryPallete3 text-primary">
                                      <div className="card-content">
                                        <div className="card-body overflow-hidden">
                                          <div className="media">
                                            <div className="media-body text-center">
                                              <h4 className="text-primary  fontTV font-weight-bold">
                                                {el.voltage_r}
                                              </h4>
                                              <span>R-N</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="bg-primaryPallete3 text-primary">
                                      <div className="card-content">
                                        <div className="card-body overflow-hidden">
                                          <div className="media">
                                            <div className="media-body text-center">
                                              <h4 className="fontTV text-primary font-weight-bold">
                                                {el.voltage_s}
                                              </h4>
                                              <span>S-N</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="bg-primaryPallete3 text-primary">
                                      <div className="card-content">
                                        <div className="card-body overflow-hidden">
                                          <div className="media">
                                            <div className="media-body text-center">
                                              <h4 className="text-primary fontTV font-weight-bold">
                                                {el.voltage_t}
                                              </h4>
                                              <span>T-N</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* akhir batas */}
                              </div>
                              <div className="col-sm-12 col-lg-12 p-3 ">
                                {/* batas */}
                                <div className="row bg-primaryPallete3  powerMeterThreeLayoutCard">
                                  <div className="col-lg-12 pt-2 text-center">
                                    <span className="text-primary fontTV">Current</span>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="bg-primaryPallete3 text-primary">
                                      <div className="card-content">
                                        <div className="card-body overflow-hidden">
                                          <div className="media">
                                            <div className="media-body text-center">
                                              <h4 className="text-primary  fontTV font-weight-bold">
                                                {el.current_r}
                                              </h4>
                                              <span>R-N</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="bg-primaryPallete3 text-primary">
                                      <div className="card-content">
                                        <div className="card-body overflow-hidden">
                                          <div className="media">
                                            <div className="media-body text-center">
                                              <h4 className="fontTV text-primary font-weight-bold">
                                                {el.current_s}
                                              </h4>
                                              <span>S-N</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="bg-primaryPallete3 text-primary">
                                      <div className="card-content">
                                        <div className="card-body overflow-hidden">
                                          <div className="media">
                                            <div className="media-body text-center">
                                              <h4 className="text-primary fontTV font-weight-bold">
                                                {el.current_t}
                                              </h4>
                                              <span>T-N</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* <div className="row p-2 bg-primaryPallete3 powerMeterThreeLayoutCard">
                            <div className="col-lg-12 pt-2 text-center">
                              <span className="text-primary">Current</span>
                            </div>
                            <div className="col-lg-6">
                              <div className="bg-primaryPallete3 text-primary">
                                <div className="card-content">
                                  <div className="card-body overflow-hidden">
                                    <div className="media">
                                      <div className="media-body text-center">
                                        <h4 className="text-primary fontTV font-weight-bold">
                                          {el.current_r}
                                        </h4>
                                        <span>R-N</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="bg-primaryPallete3 text-primary">
                                <div className="card-content">
                                  <div className="card-body overflow-hidden">
                                    <div className="media">
                                      <div className="media-body text-center">
                                        <h4 className="text-primary fontTV font-weight-bold">
                                          {el.current_s}
                                        </h4>
                                        <span>S-N</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="bg-primaryPallete3 text-primary">
                                <div className="card-content">
                                  <div className="card-body overflow-hidden">
                                    <div className="media">
                                      <div className="media-body text-center">
                                        <h4 className="text-primary fontTV font-weight-bold">
                                          {el.current_t}
                                        </h4>
                                        <span>T-N</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                                {/* akhir batas */}
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-lg-12'>
                                <div className="row mb-2 ">
                                  <div className="col-lg-6">
                                    <div className="card bg-primaryPallete3 text-primary">
                                      <div className="card-content">
                                        <div className="card-body overflow-hidden">
                                          <div className="media">
                                            <div className="media-body text-center">
                                              <span className="fontTV">Frequency</span>
                                              <h4 className="text-primary fontTV font-weight-bold mt-0">
                                                {el.frequency}
                                              </h4>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="card bg-primaryPallete3 text-primary">
                                      <div className="card-content">
                                        <div className="card-body ">
                                          <div className="media">
                                            <div className="media-body text-center">
                                              <span className='fontTV'>Daya</span>
                                              <h3 className="text-primary fontTV font-weight-bold mt-0">
                                                {el.power_factor}
                                              </h3>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="card bg-primaryPallete3 text-primary">
                                      <div className="card-content">
                                        <div className="card-body overflow-hidden">
                                          <div className="media">
                                            <div className="media-body text-center">
                                              <span className='fontTV'>KWH</span>
                                              <h3 className="text-primary fontTV font-weight-bold mt-0">
                                                {el.active_enegy}
                                              </h3>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="card bg-primaryPallete3 text-primary">
                                      <div className="card-content">
                                        <div className="card-body overflow-hidden">
                                          <div className="media">
                                            <div className="media-body text-center">
                                              <span className='fontTV'>Watt</span>
                                              <h3 className="text-primary fontTV font-weight-bold mt-0">
                                                {el.active_power}
                                              </h3>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Card  Control Room */}


    </>
  );
};

export default CardLTE;
