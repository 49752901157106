import React, {useState, useEffect} from "react";
import API from "../../api/base_url";
import DatePicker from "../../components/DatePicker";
import Footer from "../../components/Footer";
import Swal from 'sweetalert2'
import BreadCumb from "../../components/BreadCumb";
import Header from "../../components/Header";
import moment from 'moment-timezone';
import ExportListAllSystem from "../../components/ExportExcelAllSystem/ExportListAllSystem";
import ExportListStockOpname from "../../components/ExportExcelStockopname/ExportListStockOpname";
import ReactSpinner from "../../components/ReactSpinner";



const Test = () => {
 const token = localStorage.token;
  const headers = {
    Authorization: `Bearer ${token}`
};

   const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    background:"#c51a1a",
    color:"#ffffff",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  let start =  moment().startOf('day').utc().format("YYYY-MM-DD HH:mm:ss")
  let end = moment().endOf('day').utc().format("YYYY-MM-DD HH:mm:ss")
  
  const [site, setSite] = useState([]);
  const [dateRangePickerValue, setDateRangePickerValue] = useState(`${start}/${end}`);
  const [dateRangePickerDisplay, setDateRangePickerDisplay] = useState(`${start}/${end}`);
  const [pending, setPending] = useState(true);
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState([])
  const [detailData, setDetailData] = useState([])

  const getDetailHours = (id) => {
    getListDetailData(id)
  }
  const setDateRangePickerValueFunc = (val) => {
  setDateRangePickerValue(val)
  }

  const handleFilterSubmit = () => {
    setData([])
    getListData()
  Toast.fire({
    icon: "success",
    title: "Filter Data Request",
  });
  }
    const getListData = () => {
    API.get(`report/stock/opname/day/${dateRangePickerValue}`, { headers })
      .then(({ data: content }) => {
        setData(content.data.stock_opname)
        setPending(false)
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const setDateRangePickerValueExcelFunc = (val) => {
    setDateRangePickerDisplay(val)
   }
  const getListDetailData = (id) => {
    API.get(`report/stock/opname/hour/${id}`, { headers })
      .then(({ data: content }) => {
        setDetailData(content.data.production_time)
      })
      .catch((err) => {
        console.log(err);
      });
  }
    const getSite = () => {
    API.get("utility/master/data/site", { headers })
      .then(({ data: content }) => {
        setSite(content.data);
      })
      .catch((err) => {
        console.log(err);
      });
    };
    

     useEffect(() => {
    getSite()
    getListData()
  }, [])
 return (
    <div id="wrap">
    {/* {isLoading ? <ReactSpinner/>: */}
      <div>
        <Header/>
        <BreadCumb pageRoot="coba" name="test" parent="/dashboard" subParent="/dashboard" underPage="test" />
        <div className="container-fluid mt--6">
          <div className="card shadowTailwind">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-12">
                  <label className="form-control-label textPallete">Site</label>
                    <select
                      className="form-control text-darker"
                      id="channel"
                      name="channel"
                      data-toggle="select"
                      disabled
                      value={1}
                    >
                      {site.length === 0 ? (
                        <></>
                      ) : (
                        site.map((el, idx) => (
                          <option value={el.id} key={idx}>
                            {el.name}
                          </option>
                        ))
                      )}
                    </select>
                </div>
              </div>
            </div>
          </div>
        </div>
       
        <div className="container-fluid mt--3">
          <div className="card shadowTailwind">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-8">
                  <label className="form-control-label textPallete">Date Range</label>
                  <DatePicker take={(val)=> setDateRangePickerValueFunc(val)} takeTimeExcel={(val) => setDateRangePickerValueExcelFunc(val)} />
                </div>
                <div className="col-lg-4">
                  <label className="form-control-label textPallete">&nbsp;</label> <br />
                  <button className="btn bg-pallete text-white" onClick={()=> handleFilterSubmit()}>
                    <span className="fa fa-search mr-2" /> Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="row container-fluid mt-3">
          <div className="col-lg-12">
            <div className="card">
              <div className="d-flex justify-content-between card-header">
                  <h3 className="text-darker">
                    Test
                  </h3>
                 
              </div>
              <div className="table-responsive py-4 container-fluid">
                <table className="table table-bordered datatables">
                  <thead className="thead-light">
                    <tr>
                      <th colSpan={1} rowSpan={2}>No</th>
                      <th colSpan={1} rowSpan={2}>Day / Date</th>
                      <th colSpan={3} rowSpan={1} className="text-center text-red"><b>PAC</b></th>
                      <th colSpan={3} rowSpan={1} className="text-center text-blue"><b>Na0CI</b></th>
                      <th colSpan={3} rowSpan={1} className="text-center text-green"><b>Polymer</b></th>
                      <th colSpan={1} rowSpan={1} className="text-center">Remarks</th>
                    </tr>
                    <tr>
                      <th className="text-red">Input<br />(KG)</th>
                      <th className="text-red">Output<br />(KG)</th>
                      <th className="text-red">Balance<br />(KG)</th>
                      <th className="text-blue">Input<br />(KG)</th>
                      <th className="text-blue">Output<br />(KG)</th>
                      <th className="text-blue">Balance<br />(KG)</th>
                      <th className="text-green">Input<br />(KG)</th>
                      <th className="text-green">Output<br />(KG)</th>
                      <th className="text-green">Balance<br />(KG)</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data !== 0 && data.map((el, idx) => (
                      <tr>
                        <td>{idx + 1}</td>
                        {/* <td>{moment.utc(el.date_time).local().format('DD/MM/YYYY HH:mm:ss')} <button id="btn_add" type="button"  data-toggle="modal" data-target="#exampleModalUpdate" data-action="tambah" className="ml-3 btn btn-sm btn-primary" onClick={()=> getDetailHours(el.id)}>See Detail</button> </td> */}
                        <td className="text-red">{el.chemical_pac_input}</td>
                        <td className="text-red">{el.chemical_pac_output}</td>
                        <td className="text-red">{el.chemical_pac_balance}</td>
                        <td className="text-blue">{el.chemical_polymer_input}</td>
                        <td className="text-blue">{el.chemical_polymer_output}</td>
                        <td className="text-blue">{el.chemical_polymer_balance}</td>
                        <td className="text-green">{el.chemical_naocl_input}</td>
                        <td className="text-green">{el.chemical_naocl_output}</td>
                        <td className="text-green">{el.chemical_naocl_balance}</td>
                        <td>-</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
     </div>
    {/* } */}
  </div>

)};
export default Test;
