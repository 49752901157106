import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import DataTable from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import API from "../../api/base_url";
import Swal from "sweetalert2";
import BreadCumb from "../../components/BreadCumb";
import Footer from "../../components/Footer";



const Faq = () => {
  const [allData, setAllData] = useState([]);
  const [answer, setAnswer] = useState();
  const [question, setQuestion] = useState();
  const [category, setCategory] = useState([]);
  const [updateAnswer, setUpdateAnswer] = useState("");
  const [updateQuestion, setUpdateQuestion] = useState("");
  const [updateCategory, setUpdateategory] = useState("");
  const [faq_category_id, setFaq_category_id] = useState("");
  const [pending, setPending] = useState(true);
  const [willUpdate, setWillUpdate] = useState("")

  let mainData = allData.filter((el) => el.status === "AC");
  let token = localStorage.token;
  const headers = {
    Authorization: `Bearer ${token}`,
  };


  const columns = [
    {
      name: 'No',
      cell: (row, index) => index + 1,
      width: "50px"
      // sortable: true,                                    
    },
    {
      name: 'Question',
      cell: (row) => row.question,
      // sortable: true,                                    
    },
    {
      name: 'Answer',
      cell: (row) => row.answer,
    },
    {
      name: 'Category',
      cell: (row) => row.faq_category_id,
    },
    {
      name: "Action",
      center: true,
      cell: (row) => <div className="dropdown">
        <button
          className="btn  bg-pallete text-white dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Action
        </button>
        <div
          className="dropdown-menu"
          aria-labelledby="dropdownMenuButton"
        >
          <button
            id="btn_add"
            type="button"
            className="dropdown-item button-modal"
            data-toggle="modal"
            data-target="#exampleModalUpdate"
            data-action="tambah"
            onClick={() => handleUpdate(row.id)}
          >
            Edit
          </button>
          <button
            type="button"
            className="dropdown-item"
            href="#"
            onClick={() => handleDelete(row.id)}
          >
            Delete
          </button>
        </div>
      </div>,
    },
  ];

  // alert
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const confirm = () => {
    Swal.fire({
      title: 'Do you want to add FAQ?',
      showCancelButton: true,
      confirmButtonText: 'Add',
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          faq_category_id,
          question,
          answer,
        };
        if (!payload.faq_category_id || !payload.question || !payload.answer) {
          Swal.fire('Data is Required, please fill out the form', '', 'error')
        } else {
          API.post("dashboard/master/faq", payload, { headers })
            .then(({ data }) => {
              setAnswer("");
              setQuestion("");
              getData();
              Swal.fire('faq added successfully', '', 'success')
            })
            .catch((err) => {
              setAnswer("");
              setQuestion("");
              Swal.fire('error from server', '', 'error')
            });
        }
      }
    })
  }
  // end of Alert

  const getData = () => {
    API.get("dashboard/master/faqs", { headers })
      .then(({ data: content }) => {
        setAllData(content.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // API for Get Category Status
    API.get("dashboard/master/faq/categories/status/AC", { headers })
      .then(({ data: content }) => {
        setCategory(content.data);

        setFaq_category_id(content.data[0].id);
      })
      .catch((err) => {
        console.log(err);
      });

    // API for Get All Data
    getData();
  }, []);

  const getCategory = (e) => {
    setFaq_category_id(Number(e.target.value));
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Do you want to Delete this FAQ?',
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.isConfirmed) {
        API.patch(`dashboard/master/faq/${id}`, {}, { headers })
          .then(({ data }) => {
            getData();
            Toast.fire({
              icon: "success",
              title: "Delete FAQ successfully",
            });
          })
          .catch((err) => {
            console.log(err);
            Toast.fire({
              icon: "error",
              title: "Delete FAQ successfully",
            });
          });
      }
    })
  };

  const handleUpdate = (id) => {
    API.get(`dashboard/master/faq/${id}`, { headers })
      .then(({ data: content }) => {
        setWillUpdate(content.data.id)
        setUpdateAnswer(content.data.answer);
        setUpdateQuestion(content.data.question);
        setUpdateategory(content.data.faq_category_id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmitUpdate = () => {
    Swal.fire({
      title: 'Do you want to Update this FAQ?',
      showCancelButton: true,
      confirmButtonText: 'Update',
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          faq_category_id: Number(updateCategory),
          question: updateQuestion,
          answer: updateAnswer,
        };
        if (!payload.answer || !payload.question || !payload.faq_category_id) {
          Swal.fire('Data is Required, please fill out the form', '', 'error')
        } else {
          API.put(`dashboard/master/faq/${willUpdate}`, payload, { headers })
            .then(({ data }) => {
              setUpdateAnswer("");
              setUpdateQuestion("");
              setWillUpdate("")
              getData();
              Swal.fire('faq updated successfully', '', 'success')
            })
            .catch((err) => {
              setUpdateAnswer("");
              setUpdateQuestion("");
              Swal.fire('error from server', '', 'error')
            });
        }
      }
    })
  }

  return (
    <div id="wrap">
      <div>
        <Header />
        <BreadCumb pageRoot="Admin Panel" name="FAQ" parent="/dashboard" subParent="/dashboard" underPage="Admin Panel" />

        {/* Page content */}
        <div className="container-fluid mt--6">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-lg-6">
                      <h5 className="h3 mb-0 text-red">FAQ</h5>
                    </div>
                    <div className="col-lg-6">
                      <button
                        id="btn_add"
                        type="button"
                        className="float-right btn bg-pallete text-white button-modal"
                        data-toggle="modal"
                        data-target="#exampleModal"
                        data-action="tambah"
                      >
                        <i className="fas fa-plus mr-3" /> ADD FAQ
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <DataTableExtensions exportHeaders print={false}
                    export={false} columns={columns} data={mainData} >
                    <DataTable pagination />
                  </DataTableExtensions>
                </div>
              </div>
            </div>
          </div>

          {/* CALLING MODAL */}
          {/* Modal Add Data */}
          <div className="modal fade" id="exampleModal" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-12">
                        <h3 className="modal-title mb-5" id="exampleModalLabel">
                          Add New FAQ
                        </h3>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label className="form-control-label">
                            Question
                          </label>
                          <textarea
                            className="form-control input-sm"
                            value={question}
                            onChange={(e) => setQuestion(e.target.value)}
                          />
                        </div>
                        <div className="form-group mb-3">
                          <label className="form-control-label">
                            Category Faq
                          </label>
                          <select
                            className="custom-select"
                            id="inputGroupSelect01"
                            value={faq_category_id}
                            onChange={(e) => getCategory(e)}
                          >
                            {category.map((el, idx) => (
                              <option value={el.id} key={idx}>{el.name}</option>
                            ))}
                          </select>
                        </div>

                        <div className="form-group">
                          <label className="form-control-label">
                            Answer
                          </label>
                          <textarea
                            className="form-control input-sm"
                            value={answer}
                            onChange={(e) => setAnswer(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <button
                      className="btn btn-primary mt-5"
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => confirm()}
                    >
                      Add new FAQ
                    </button>
                    <button
                      className="btn bg-pallete text-light mt-5"
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      Cancel
                    </button>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div id="dynamicForm" />
                </div>
              </div>
            </div>
          </div>

          {/* Modal Update Data */}
          <div
            className="modal fade"
            id="exampleModalUpdate"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-12">
                        <h3 className="modal-title mb-5" id="exampleModalLabel">
                          Update FAQ
                        </h3>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label className="form-control-label">
                            Question
                          </label>
                          <textarea
                            className="form-control input-sm"
                            value={updateQuestion}
                            onChange={(e) => setUpdateQuestion(e.target.value)}
                          />
                        </div>
                        <div className="form-group mb-3">
                          <label className="form-control-label">
                            Category Faq
                          </label>
                          <select
                            className="custom-select"
                            id="inputGroupSelect02"
                            value={updateCategory}
                            onChange={(e) => setUpdateategory(e.target.value)}
                          >
                            {category.map((el, idx) => (
                              <option value={el.id} key={idx}>{el.name}</option>
                            ))}
                          </select>
                        </div>
                        <div className="form-group">
                          <label className="form-control-label">
                            Answer
                          </label>
                          <textarea
                            className="form-control input-sm"
                            value={updateAnswer}
                            onChange={(e) => setUpdateAnswer(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <button
                      className="btn btn-warning mt-5"
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => handleSubmitUpdate()}
                    >
                      Update FAQ
                    </button>
                    <button
                      className="btn btn-primary mt-5"
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      Cancel
                    </button>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div id="dynamicForm" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
