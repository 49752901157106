import React from "react";

const FaqReadOnly = ({question, answer, unique}) => {
  let idCreate = `#faq-${unique}`
  let idCall = `faq-${unique}`
  return (
    <div id="mainFAQ">
      <div className="container">
        <div className="accordion" id="faq">
          <div className="card mt--5">
            <div className="card-header" id="faqhead2"> 
              <a
                href="#"
                className="btn btn-header-link"
                data-toggle="collapse"
                data-target={idCreate} // dinamis
                aria-expanded="true"
                aria-controls="faq2"
              >
                {question}
              </a>
            </div>
            <div
              id={idCall} // dinamis
              className="collapse"
              aria-labelledby="faqhead2"
              data-parent="#faq" 
            >
              <div className="card-body">
                {answer}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqReadOnly;
