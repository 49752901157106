import { useRef } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import moment from 'moment';
import Swal from 'sweetalert2'




export default function ExportListBackwash({ time, location, data }) {
  const tableRef = useRef(null);

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    background: "#c51a1a",
    color: "#ffffff",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `Power Meter Area - ${location}`,
    sheet: "Power Meter Area"
  });

  let handleSubmit = () => {
    onDownload()

    Toast.fire({
      icon: "success",
      title: "Data has exported",
    });
  }


  return (
    <div>
      <button hidden={data.length == 0} onClick={() => handleSubmit()} className="btn bg-pallete text-white"><span className="fa fa-download mr-2" /> Export excel </button>

      <table ref={tableRef} style={{ display: "none" }}>
        <tbody>
          <tr>
            <th colSpan="17" align="center">DAILY REPORT OF PLN PT. MALIGI PERMATA INDUSTRIAL ESTATE</th>
          </tr>
          <tr>
            <th colSpan="17" align="center">{time}</th>
          </tr>
          <tr>

          </tr>
          <tr>
            <th colSpan={1} rowSpan={2}>No</th>
            <th colSpan={1} rowSpan={2}>Day / Date</th>
            <th colSpan={1} rowSpan={2}>Time (Hour)</th>
            <th colSpan={3} rowSpan={1} className="text-center text-green"><b>Voltage</b></th>
            <th colSpan={3} rowSpan={1} className="text-center text-blue"><b>Current</b></th>
            <th colSpan={1} rowSpan={2} className="text-center">Frequency (Hz)</th>
            <th colSpan={1} rowSpan={2} className="text-center">Daya (Watt)</th>
            <th colSpan={1} rowSpan={2} className="text-center">KWH (Watt)</th>
            <th colSpan={1} rowSpan={2} className="text-center">KVArH (Watt)</th>
            <th colSpan={1} rowSpan={2} className="text-center">Keterangan</th>
          </tr>
          <tr>
            <th className="text-green">R-N</th>
            <th className="text-green">S-N</th>
            <th className="text-green">T-N</th>
            <th className="text-blue">R-N</th>
            <th className="text-blue">S-N</th>
            <th className="text-blue">T-N</th>
          </tr>
            {data !== 0 && data.map((el, idx) => (
              <tr>
                <td>{idx + 1}</td>
                <td align="center">{moment.utc(el.date_time).local().format('DD/MM/YYYY')}</td>
                <td align="center">{moment.utc(el.date_time).local().format('HH:mm:ss')}</td>
                <td align="center">{el.voltage_r}</td>
                <td align="center">{el.voltage_s}</td>
                <td align="center">{el.voltage_t}</td>
                <td align="center">{el.current_r}</td>
                <td align="center">{el.current_s}</td>
                <td align="center">{el.current_t}</td>
                <td align="center">{el.frequency}</td>
                <td align="center">{el.power_factor}</td>
                <td align="center">{el.active_enegy}</td>
                <td align="center">{el.active_power}</td>
                <td align="center">-</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}
