import React from "react";
import TankCylincerSquare from "./TankCylinderSquare";
import Helper from "../../utility/helper.js"
import moment from 'moment'

const CardTank = ({ name, balance, tank }) => {
  return (
    <div className="bg-white p-5 p-md-0 paddingTV rounded border border-muted mb-2 shadowTailwind container-fluid">
      <div style={{ marginTop: 12, marginBottom: 5 }} className="d-flex justify-content-between">
        <span style={{ fontSize: 16, marginy: 4 }} className=" text-black fontTV font-weight-bold">{name}</span>
        <span style={{ fontSize: 16, marginY: 4 }} className=" text-black fontTV font-weight-bold text-right">
          Total : {balance.toLocaleString('id-ID', { minimumFractionDigits: 2 })} Kg
        </span>
      </div>
      {/* Rubah */}


      {/* <div className="container-fluid">
        <div className="col-lg-12">
          {tank.length !== 0 && tank.map((el, idx) => (
            <div className="row" key={idx}>
            <TankCylincerSquare percentage={el.percentage} name={el.name} />
            </div>
          )
          )

          }

        </div>
      </div> */}


      <div style={{ marginBottom: -15, marginTop: -9 }} className="row">
        <div className="col-lg-6 d-flex flex-column">
          <div className="row">
            <div className="col-lg-12 d-flex flex-row">
              {tank.length !== 0 &&
                tank.map((el, idx) => (
                  <div style={{ marginBottom: -25, marginTop:-15 }} className="col-lg-6 py-6" key={idx}>
                    <div
                      className="position-relative tvTank"
                      style={{ height: "12vh" }}
                    >
                      <TankCylincerSquare percentage={el.percentage} name={el.name} />
                      <div className="position-absolute top-0 right-0 d-none d-md-block">
                        <span className="font-weight-bold fontTV">
                          <small>
                            MAX{" "}
                            <span style={{ display: "block", lineHeight: "1px" }}>
                              {el.level_max}
                            </span>{" "}
                          </small>
                        </span>
                      </div>
                      <div className="position-absolute bottom-0 right-0 d-none d-md-block">
                        <span className="font-weight-bold fontTV">
                          <small>MIN</small>
                        </span>
                      </div>
                      {/* mobile */}
                      <div className="position-absolute top-0 right-6 d-block d-md-none">
                        <span className="font-weight-bold fontTV">
                          <small>
                            MAX{" "}
                            <span style={{ display: "block", lineHeight: "1px" }}>
                              {el.level_max}
                            </span>{" "}
                          </small>
                        </span>
                      </div>
                      <div className="position-absolute bottom-0 right-6 d-block d-md-none">
                        <span className="font-weight-bold fontTV">
                          <small>MIN</small>
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
            </div>

          </div>
        </div>
        <div className="col-lg-6 col-col-sm-12">
          {tank.length !== 0 &&
            tank.map((el, idx) => (
              <div className="p-0" style={{ marginTop: -15, marginBottom: 10 }}  key={idx}>
                <table className="table table-borderless table-sm my--2">
                  <thead>
                    <tr>
                      <th scope="col" className="text-black">
                        <b>{el.name}</b>
                      </th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody className="px-1">
                    <tr>
                      <th  scope="col">Level</th>
                      <th scope="col">: {el.level} cm</th>
                    </tr>
                        {/* <th scope="col">Percentage</th>
                      <th scope="col">: {el.percentage} %</th> */}
                    <tr>
                      <th scope="col">Weight</th>
                      <th scope="col" className="px-2">:   {el.weight.toLocaleString('id-ID', { minimumFractionDigits: 2 })} Kg </th>
                    </tr>
                    </tbody>
             
                    {/* <tr>
                          <th>Status Pump</th>
                          <th>
                            {el.status_pump ? (
                              <span className="badge badge-success">ON</span>
                            ) : (
                              <span className="badge badge-danger">OFF</span>
                            )}
                          </th>
                        </tr> */}
                </table>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default CardTank;
