import React from 'react'

const TermOfUse = () => {
  return (
    <div className='container-fluid pt-5 mb-5'>
      <h3>Term of Use</h3>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat nobis deserunt eaque, officiis maxime temporibus, sequi veniam rem ab inventore nulla explicabo, facilis unde recusandae placeat! Beatae consectetur repellat maxime.
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat nobis deserunt eaque, officiis maxime temporibus, sequi veniam rem ab inventore nulla explicabo, facilis unde recusandae placeat! Beatae consectetur repellat maxime.
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat nobis deserunt eaque, officiis maxime temporibus, sequi veniam rem ab inventore nulla explicabo, facilis unde recusandae placeat! Beatae consectetur repellat maxime.
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat nobis deserunt eaque, officiis maxime temporibus, sequi veniam rem ab inventore nulla explicabo, facilis unde recusandae placeat! Beatae consectetur repellat maxime.
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat nobis deserunt eaque, officiis maxime temporibus, sequi veniam rem ab inventore nulla explicabo, facilis unde recusandae placeat! Beatae consectetur repellat maxime.
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat nobis deserunt eaque, officiis maxime temporibus, sequi veniam rem ab inventore nulla explicabo, facilis unde recusandae placeat! Beatae consectetur repellat maxime.
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat nobis deserunt eaque, officiis maxime temporibus, sequi veniam rem ab inventore nulla explicabo, facilis unde recusandae placeat! Beatae consectetur repellat maxime.
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat nobis deserunt eaque, officiis maxime temporibus, sequi veniam rem ab inventore nulla explicabo, facilis unde recusandae placeat! Beatae consectetur repellat maxime.
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat nobis deserunt eaque, officiis maxime temporibus, sequi veniam rem ab inventore nulla explicabo, facilis unde recusandae placeat! Beatae consectetur repellat maxime.
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat nobis deserunt eaque, officiis maxime temporibus, sequi veniam rem ab inventore nulla explicabo, facilis unde recusandae placeat! Beatae consectetur repellat maxime.
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat nobis deserunt eaque, officiis maxime temporibus, sequi veniam rem ab inventore nulla explicabo, facilis unde recusandae placeat! Beatae consectetur repellat maxime.
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat nobis deserunt eaque, officiis maxime temporibus, sequi veniam rem ab inventore nulla explicabo, facilis unde recusandae placeat! Beatae consectetur repellat maxime.
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat nobis deserunt eaque, officiis maxime temporibus, sequi veniam rem ab inventore nulla explicabo, facilis unde recusandae placeat! Beatae consectetur repellat maxime.
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat nobis deserunt eaque, officiis maxime temporibus, sequi veniam rem ab inventore nulla explicabo, facilis unde recusandae placeat! Beatae consectetur repellat maxime.
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat nobis deserunt eaque, officiis maxime temporibus, sequi veniam rem ab inventore nulla explicabo, facilis unde recusandae placeat! Beatae consectetur repellat maxime.
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat nobis deserunt eaque, officiis maxime temporibus, sequi veniam rem ab inventore nulla explicabo, facilis unde recusandae placeat! Beatae consectetur repellat maxime.
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat nobis deserunt eaque, officiis maxime temporibus, sequi veniam rem ab inventore nulla explicabo, facilis unde recusandae placeat! Beatae consectetur repellat maxime.
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat nobis deserunt eaque, officiis maxime temporibus, sequi veniam rem ab inventore nulla explicabo, facilis unde recusandae placeat! Beatae consectetur repellat maxime.
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat nobis deserunt eaque, officiis maxime temporibus, sequi veniam rem ab inventore nulla explicabo, facilis unde recusandae placeat! Beatae consectetur repellat maxime.
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat nobis deserunt eaque, officiis maxime temporibus, sequi veniam rem ab inventore nulla explicabo, facilis unde recusandae placeat! Beatae consectetur repellat maxime.
    </div>
  )
}

export default TermOfUse