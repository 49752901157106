import React, { useEffect } from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { Link } from 'react-router-dom'
import BreadCumb from "../../components/BreadCumb";
import API from "../../api/base_url";
import DataTable from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { useState } from "react";
import Footer from "../../components/Footer";
import Moment from 'react-moment';





const columns = [
  {
    name: 'No',
    cell: (row, index) => index + 1,
    width: "50px"
    // sortable: true,                                    
  },
  {
    name: 'Log',
    cell: (row) => row.log,
    // sortable: true,                                    
  },
  {
    name: 'Created At',
    cell: (row) => <Moment format="DD-MM-YYYY HH:mm:ss">{row.created_at}</Moment>,
  },
];






const UserLog = () => {
  const [log, setLog] = useState([])

  let token = localStorage.token;
  const headers = {
    Authorization: `Bearer ${token}`,
};

  useEffect(() => {
    API.get("dashboard/account/log/all", { headers })
    .then(({ data: content }) => {
      setLog(content.data)
    })
    .catch((err) => {
      console.log(err);
    });
  }, []);

  return (
    <div id="wrap">
      <div>
        <Header/>
        <BreadCumb pageRoot="Admin Panel" name="User Log" parent="/dashboard" subParent="/dashboard" underPage="Admin Panel" />
        <div className="container-fluid mt--6">
          {/* TABLE */}
          <div className="row mt-3">
            <div className="col-lg-12">
              <div className="card">
                {/* Card header */}
                <div className="card-header">
                  <h3 className="mb-0 text-red">User Log</h3>
                </div>
                <div className="table-responsive py-4">
                  <div className="card-body">
                    <DataTableExtensions  exportHeaders   print={false}
                      export={false}  columns={columns} data={log} >
                      <DataTable pagination />
                    </DataTableExtensions>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserLog;
