import React, { useEffect, useState, useRef } from "react";
import CardTank from "../components/CardTV_Components/CardTank";
import CardLTE from "../components/CardLTE";
import CardReservoir from "../components/CardTV_Components/CardReservoir";
import CardElevated from "../components/CardTV_Components/CardElevated";
import API from "../api/base_url";
import HeaderDashboard from "../components/HeaderDashboard";
import ReactSpinner from "../components/ReactSpinner";



const Dashboard = () => {
  // Card Info
  const [powerMeter, setPowerMeter] = useState([]);
  const [productionTime, setProductionTime] = useState("00:00:00");
  const [productionStatus, setProductionStatus] = useState(false);
  const [intakeArea, setIntakeArea] = useState(0);
  const [drainRinse, setDrainRinse] = useState(0);
  const [overflow, setoverFlow] = useState(0);
  const [backwashArea, setBackWashArea] = useState(0);
  const [clearatorArea, setClearatorArea] = useState(0);
  //  Interface Info
  const [chemicalArea, setChemicalArea] = useState([]);
  const [reservoirArea, setReservoirArea] = useState({ balance: "", tank: [] });
  const [elevatedArea, setElevatedArea] = useState([]);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true)


  let token = localStorage.token;
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const getData = () => {
    API.get("utility/dashboard", { headers })
      .then(({ data: content }) => {
        setProductionTime(content.data.production_time);
        setProductionStatus(content.data.production_status)
        setIntakeArea(content.data.intake_area);
        setBackWashArea(content.data.backwash_area);
        setDrainRinse(content.data.rinse_drain_area);
        setClearatorArea(content.data.clearator_area);
        setoverFlow(content.data.overflow);
        // for card
        setPowerMeter(content.data.power_meter_area);
        setChemicalArea(content.data.chemical_area);
        setReservoirArea(content.data.reservoir_area)
        setElevatedArea(content.data.elevated_area)
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData()
  }, [])


  useEffect(() => {
    const interval = setInterval(() => {
      getData()
      setSuccess(true)
      setTimeout(() => { setSuccess(false) }, 1000)
    }, 5000);


    return () => {
      clearInterval(interval)
    }
  }, [powerMeter])



  return (
    <div id="wrap">
      {/* {isLoading ? <ReactSpinner /> : */}
        <div className="main-content" id="panel" style={{ marginTop: '90px' }}>
          <HeaderDashboard success={success} />
          <div className="bg-primary" style={{ minHeight: 350 }}>  </div>
          <div style={{ marginTop: '-340px' }}>
            <div className="container-fluid">
              <CardLTE
                powerMeter={powerMeter}
                productionTime={productionTime}
                productionStatus={productionStatus}
                intakeArea={intakeArea}
                drainRinse={drainRinse}
                overflow={overflow}
                backwashArea={backwashArea}
              />
            </div>
          </div>
          <div className="container-fluid">
            <div className="row mt-3">
              <div className="col-lg-12">
                <div className="card bg-secondary shadowTailwind">
                  <div className="card-body">
                    <div>
                      <div className="row">
                        <div className="col-lg-6">
                          <h4 className="text-black">Chemical Area</h4>
                          {
                            chemicalArea.length === 0 ? <>No Data</> : (
                              chemicalArea.map((el, idx) => (
                                <div key={idx}>
                                  <CardTank
                                    name={el.chemical}
                                    balance={el.balance}
                                    tank={el.tank}
                                  />
                                </div>
                              ))
                            )
                          }
                        </div>
                        {/* dummy */}
                        <div className="col-lg-6">
                          <div>
                            <h4 className="text-black">Reservoir Area</h4>
                            <CardReservoir reservoirArea={reservoirArea} />
                          </div>
                          <div style={{ marginTop: 6 }}>
                            <h4 className="text-black">Elevated Area</h4>
                            <CardElevated elevatedArea={elevatedArea} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      {/* } */}
    </div>
  );
};

export default Dashboard;
