import React, { useState, useEffect } from "react";
import API from "../../api/base_url";
import DatePicker from "../../components/DatePicker";
import Footer from "../../components/Footer";
import Swal from 'sweetalert2'
import BreadCumb from "../../components/BreadCumb";
import Header from "../../components/Header";
import moment from 'moment-timezone';
import ExportListAllSystem from "../../components/ExportExcelAllSystem/ExportListAllSystem";
import ExportListStockOpname from "../../components/ExportExcelStockopname/ExportListStockOpname";
import ReactSpinner from "../../components/ReactSpinner";




const StockOfName = () => {
  const token = localStorage.token;
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    background: "#c51a1a",
    color: "#ffffff",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  let start = moment().startOf('month').utc().format("YYYY-MM-01 00:00:00")
  let end = moment().endOf('day').utc().format("YYYY-MM-DD HH:mm:ss")

  const [site, setSite] = useState([]);
  const [dateRangePickerValue, setDateRangePickerValue] = useState(`${start}/${end}`);
  const [dateRangePickerDisplay, setDateRangePickerDisplay] = useState(`${start}/${end}`);
  const [pending, setPending] = useState(true);
  const [isLoading, setIsLoading] = useState(true)


  const [data, setData] = useState([])
  const [detailData, setDetailData] = useState([])



  const getSite = () => {
    API.get("utility/master/data/site", { headers })
      .then(({ data: content }) => {
        setSite(content.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getListData = () => {
    const [startDate, endDate] = dateRangePickerValue.split('/');
    API.get(`report/stock/opname/day?date_start=${startDate}&date_finish=${endDate}`, { headers })
      .then(({ data: content }) => {
        setData(content.data.stock_opname)
        setPending(false)
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getListDetailData = (id) => {
    API.get(`report/stock/opname/hour/${id}`, { headers })
      .then(({ data: content }) => {
        setDetailData(content.data.production_time)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleFilterSubmit = () => {
    setData([])
    getListData()
    Toast.fire({
      icon: "success",
      title: "Filter Data Request",
    });
  }

  const setDateRangePickerValueFunc = (val) => {
    setDateRangePickerValue(val)
  }

  const setDateRangePickerValueExcelFunc = (val) => {
    setDateRangePickerDisplay(val)
  }

  const getDetailHours = (id) => {
    getListDetailData(id)
  }

  useEffect(() => {
    getSite()
    getListData()
  }, [])

  return (
    <div id="wrap">
      {/* {isLoading ? <ReactSpinner/>: */}
      <div>
        <Header />
        <BreadCumb pageRoot="Report & Analisis" name="Stock Opname" parent="/dashboard" subParent="/dashboard" underPage="Report Analisis" />
        <div className="container-fluid mt--6">
          <div className="card shadowTailwind">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-12 d-flex">
                  <div className="col-lg-6">
                    <label className="form-control-label textPallete">Site</label>
                    <select
                      className="form-control text-darker"
                      id="channel"
                      name="channel"
                      data-toggle="select"
                      disabled
                      value={1}
                    >
                      {site.length === 0 ? (
                        <></>
                      ) : (
                        site.map((el, idx) => (
                          <option value={el.id} key={idx}>
                            {el.name}
                          </option>
                        ))
                      )}
                    </select>
                  </div>
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-lg-9">
                        <label className="form-control-label textPallete">Date Range</label>
                        <DatePicker take={(val) => setDateRangePickerValueFunc(val)} takeTimeExcel={(val) => setDateRangePickerValueExcelFunc(val)} />
                      </div>
                      <div className="col-lg-3">
                        <label className="form-control-label textPallete">&nbsp;</label> <br />
                        <button className="btn bg-pallete text-white" onClick={() => handleFilterSubmit()}>
                          <span className="fa fa-search mr-2" /> Filter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        {/* page comtent */}
        <div className="row container-fluid mt-3">
          <div className="col-lg-12">
            <div className="card">
              <div className="d-flex justify-content-between card-header">
                <h4 className="text-darker">
                  Stock Opname
                </h4>
                <ExportListStockOpname time={dateRangePickerDisplay} location={"Stock Opname"} data={data} />
              </div>
              <div className="table-responsive py-4 container-fluid">
                <table className="table table-bordered datatables">
                  <thead className="thead-light">
                    <tr>
                      <th colSpan={1} rowSpan={2} className="text-center"  style={{ textAlign: "center", verticalAlign: "middle" }}>No</th>
                      <th colSpan={1} rowSpan={2} className="text-center " style={{ textAlign: "center", verticalAlign: "middle" }}>Day / Date</th>
                      <th colSpan={3} rowSpan={1} className="text-center "><b>PAC</b></th>
                      <th colSpan={3} rowSpan={1} className="text-center "><b>Polymer</b></th>
                      <th colSpan={3} rowSpan={1} className="text-center "><b>Na0CI</b></th>
                      <th colSpan={1} rowSpan={2} className="text-center" style={{ textAlign: "center", verticalAlign: "middle" }}>Remarks</th>
                      {/* <th colSpan={1} rowSpan={2} className="text-center" style={{ textAlign: "center", verticalAlign: "middle" }}>Action</th> */}
                    </tr>
                    <tr>
                      <th className="">Input<br />(KG)</th>
                      <th className="">Output<br />(KG)</th>
                      <th className="">Balance<br />(KG)</th>
                      <th className="">Input<br />(KG)</th>
                      <th className="">Output<br />(KG)</th>
                      <th className="">Balance<br />(KG)</th>
                      <th className="">Input<br />(KG)</th>
                      <th className="">Output<br />(KG)</th>
                      <th className="">Balance<br />(KG)</th>
                  
                    </tr>
                  </thead>
                  <tbody>
                    {data !== 0 && data.map((el, idx) => (
                      <tr>
                        <td>{idx + 1}</td>
                        <td className="text-left">{moment.utc(el.datetime).local().format('DD/MM/YYYY ')}</td>
                        {/* <td>{moment.utc(el.date_time).local().format('DD/MM/YYYY HH:mm:ss')} <button id="btn_add" type="button"  data-toggle="modal" data-target="#exampleModalUpdate" data-action="tambah" className="ml-3 btn btn-sm btn-primary" onClick={()=> getDetailHours(el.id)}>See Detail</button> </td> */}
                        <td className="text-left">{el.chemical_pac_input}</td>
                        <td className="text-left">{el.chemical_pac_output}</td>
                        <td className="text-left">{el.chemical_pac_balance}</td>
                        <td className="text-left">{el.chemical_polymer_input}</td>
                        <td className="text-left">{el.chemical_polymer_output}</td>
                        <td className="text-left">{el.chemical_polymer_balance}</td>
                        <td className="text-left">{el.chemical_naocl_input}</td>
                        <td className="text-left">{el.chemical_naocl_output}</td>
                        <td className="text-left">{el.chemical_naocl_balance}</td>
                        <td>-</td>
                        {/* <td style={{ display: "flex", justifyContent: "center" }}>
                          <button id="btn_edit" type="button" data-toggle="modal" data-target="#exampleModalUpdate" data-action="tambah" className="ml-3 btn btn-sm btn-danger" >Edit Remaks</button>
                          <button id="btn_hours" className="ml-3 btn btn-sm bg-green text-white" ><a href="./gethours/stockopname" className="text-white">See Detail</a></button>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                  {/* <tfoot>
                    <tr>
                      <th colSpan={2} className="text-center">Total</th>
                      <th>23.548</th>
                      <th>23.548</th>
                      <th>23.548</th>
                      <th>23.548</th>
                      <th>23.548</th>
                      <th>23.548</th>
                      <th>23.548</th>
                      <th>23.548</th>
                      <th>23.548</th>
                      <th />
                    </tr>
                  </tfoot> */}
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* Modal Update */}
        <div className="modal fade" id="exampleModalUpdate" aria-hidden="true">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12">
                      <h4 className="modal-title mb-5" id="exampleModalLabel">
                        Update Remarks
                      </h4>
                    </div>
                    <div className="col-lg-12">
                      <form>
                        <div className="form-row align-items-center">
                          <div class="col-1">
                            <label for="exampleInputPassword1">Remarks</label>
                          </div>
                          <div className="col-11">
                            <textarea type="password" class="form-control" id="exampleInputPassword1" placeholder="Remaks" />
                          </div>
                        </div>
                        <br />
                        <button type="submit" class="btn w-100 btn-success">Simpan</button>
                      </form>
                    </div>
                  </div>
                  {/* <button
                    className="btn btn-primary mt-5"
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    Close
                  </button> */}
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div id="dynamicForm" />
              </div>
            </div>
          </div>
        </div>
        {/* End of Modal Update */}
      </div>
      {/* } */}
    </div>
  )
};


export default StockOfName;








