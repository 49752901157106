import React from "react";
import TankGauge from "./TankGauge";
import MeasureMeter from "./MeasureMeter";
import TankCylincerSquare from "./TankCylinderSquare";
import moment from 'moment'
import Helper from "../../utility/helper.js";

const CardElevated = ({ balance, elevatedArea }) => {
  return (
    <div className="bg-white p-5 p-md-0 paddingTV rounded border border-muted mb-2 shadowTailwind container-fluid" style={{ marginBottom: -15, marginTop: -19 }}>
      <div style={{ marginBottom: 5, marginTop: 2, justifyContent: "flex-start" }} className="d-flex" >
        {/* <span style={{fontSize:16}} className=" text-red font-weight-bold fontTV">{"Elevated"}</span> */}
        {/* <span style={{ fontSize: 16 }} className=" text-white font-weight-bold fontTV">
          Balance : {balance} Kg
        </span> */}
      </div>

      <div  className="row">
        <div className="col-lg-6 py-2 d-flex flex-column">
          <div className="row">
            <div className="col-lg-12 d-flex flex-row">
              {elevatedArea.length !== 0 &&
                elevatedArea.map((el, idx) => (
                  <div style={{ marginBottom: -25, marginTop: 30 }} className="col-lg-6" key={idx}>
                    <div
                      className="position-relative tvTank"
                      style={{ height: "14vh" }}
                    >
                      <TankCylincerSquare percentage={el.percentage} name={el.name} />
                      <div className="position-absolute top-0 right-0 d-none d-md-block">
                        <span className="font-weight-bold fontTV">
                          <small>
                            MAX{" "}
                            <span style={{ display: "block", lineHeight: "1px" }}>
                              {el.level_max}
                            </span>{" "}
                          </small>
                        </span>
                      </div>
                      <div className="position-absolute bottom-0 right-0 d-none d-md-block">
                        <span className="font-weight-bold fontTV">
                          <small>MIN</small>
                        </span>
                      </div>
                      {/* mobile */}
                      <div className="position-absolute top-0 right-6 d-block d-md-none">
                        <span className="font-weight-bold fontTV">
                          <small>
                            MAX{" "}
                            <span style={{ display: "block", lineHeight: "1px" }}>
                              {el.level_max}
                            </span>{" "}
                          </small>
                        </span>
                      </div>
                      <div className="position-absolute bottom-0 right-6 d-block d-md-none">
                        <span className="font-weight-bold fontTV">
                          <small>MIN</small>
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
            </div>

          </div>
        </div>
        <div className="col-lg-6">
          {elevatedArea.length !== 0 &&
            elevatedArea.map((el, idx) => (
              <div style={{ marginTop: -15, marginBottom: 10 }} key={idx}>
                <table className="table table-borderless table-sm my-0">
                  <thead>
                    <tr>
                      <th scope="col" className="text-black">
                        <b>{el.name}</b>
                      </th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Level</th>
                      <th>: {el.level} cm</th>
                    
                      <th scope="row">Flow Rate</th>
                      <th>: {el.flow_rate} m<sup>3</sup> </th>
                    </tr>
                    <tr>
                      <th scope="row">Totalizer</th>
                      <th>: {el.totalizer} m<sup>3</sup> </th>
                    </tr>
                    {/* <tr>
                          <th scope="row">Status Pump</th>
                          <th>
                            {el.status_pump ? (
                              <span className="badge badge-success">ON</span>
                            ) : (
                              <span className="badge badge-danger">OFF</span>
                            )}
                          </th>
                        </tr> */}
                  </tbody>
                </table>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default CardElevated;
