import React ,{useState, useEffect} from "react";
import Header from "../../components/Header";
import BreadCumb from "../../components/BreadCumb";
import Footer from "../../components/Footer";
import API from "../../api/base_url";
import DataTable from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions';
import moment from 'moment'
import Helper from "../../utility/helper.js";
import myToken from "../../utility/getToken"
import Moment from "react-moment";


const PowerMeter = () => {

  // header provide choice
  const [site, setSite] = useState([]);
  const [area, setArea] = useState([]);
  const [device, setDevice] = useState([]);
  // idChoosed
  const [areaSelected, setAreaSelected] = useState(1);
  const [deviceSelected, setDeviceSelected] = useState(1);
  const [idWillRender, setIdWilRender] = useState(18)

  const [valuePowerMeter, setValuePowerMeter] = useState([]);
  const [listDataTable, setListDataTable] = useState([]);

  const token = localStorage.token;
  const headers = {
      Authorization: `Bearer ${token}`,
   };
  
  const columnsListData = [
    {
      name: 'No',
      cell: (row, index) => index + 1,
      width: "50px"
    },
    {
      name: 'Power Meter Name',
      cell: (row) => row.name,
    },
    {
      name: 'Date & Time',
      cell: (row) => <Moment format="DD-MM-YYYY HH:mm:ss">{row.recorded_at}</Moment>,
    },
    {
      name: 'Voltage',
      cell: (row) => 
      <span  className="text-center">R - S - T<br /> <span className="badge badge-success">{row.voltage_r}</span> <span className="badge badge-primary">{row.voltage_s}</span> <span className="badge badge-danger">{row.voltage_t}</span> </span>
    },
    {
      name: 'Current',
      cell: (row) =>
        <span className="text-center">R - S - T<br /> <span className="badge badge-success">{row.current_r}</span> <span className="badge badge-primary">{row.current_s}</span> <span className="badge badge-danger">{row.current_t}</span> </span>
    },
    {
      name: 'Frequency',
      cell: (row) => row.frequency,
    },
    {
      name: 'Daya',
      cell: (row) => row.power_factor,
    },
    {
      name: 'KWH',
      cell: (row) => row.active_enegy,
    },
    {
      name: 'Watt',
      cell: (row) => row.active_power,
    },
  ];

  /** Function for populated data */
   const getSite = () => {
    API.get("utility/master/data/site", { headers })
      .then(({ data: content }) => {
        setSite(content.data);
      })
      .catch((err) => {
        console.log(err);
      });
   }; // output WTP 1 as default

   const getArea = () => {
    API.get("utility/master/data/areas/powermeter", { headers })  // output between 1 & 7
      .then(({ data: content }) => {
        setArea(content.data);
      })
      .catch((err) => {
        console.log("err")
      })
   }; // output Control room or Intake

   const startGetDevice = () => {
    API.get(`utility/master/data/devices/powermeter/${deviceSelected}`, { headers }) // output between 1 & 7
      .then(({ data: content }) => {
        setDevice(content.data);
      })
      .catch((err) => {
        console.log("err")
      }) 
   } // output based on getArea

   const start_handle_get_device_and_render_data = (val) => {
    API.get(`utility/powermeter/${idWillRender}`, { headers }) // output between 1 & 7
    .then(({ data: content }) => {
      setValuePowerMeter(content.data.power_meter);
      setListDataTable(content.data.list_data);
    })
    .catch((err) => {
      console.log("err")
    }) 
  }

   const getDevice = (val) => {
    API.get(`utility/master/data/devices/powermeter/${val}`, { headers }) // output between 1 & 7
      .then(({ data: content }) => {
        setDevice(content.data);
      })
      .catch((err) => {
        console.log("err")
      }) 
   } // output based on getArea


  //  work in onchange
  const handleGetArea = (val) => {
    setAreaSelected(val)
    setDeviceSelected(val)
    getDevice(val)

    if (val == 7) {
      handle_get_device_and_render_data(2)
    } else if (val == 1) {
      handle_get_device_and_render_data(18)
    }
  }

  const handle_get_device_and_render_data = (val) => {
    API.get(`utility/powermeter/${val}`, { headers }) // output between 1 & 7
    .then(({ data: content }) => {
      setDeviceSelected(val)
      setIdWilRender(val)
      setValuePowerMeter(content.data.power_meter);
      setListDataTable(content.data.list_data);
    })
    .catch((err) => {
      console.log("err")
    }) 
  }

   
   useEffect(() => {
     getSite()
     getArea()
     startGetDevice()
     start_handle_get_device_and_render_data()
   }, [])
   

   
  //  interval with param deviceSelected for render Data
    useEffect(() => {
      const interval = setInterval(() => {
        handle_get_device_and_render_data(idWillRender)
      }, 3000);

      return () => {
        clearInterval(interval);
      };
    }, [listDataTable]);
  

  


  return (
    <div id="wrap">
      <div>
        <Header />
        <BreadCumb pageRoot="Dashboard" name="Power Meter" parent="/dashboard" subParent="/dashboard"  underPage="Utility"   />
        <div className="container-fluid mt--6">
          <div className="card shadowTailwind">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-4">
                  <label className="form-control-label textPallete">Site</label>
                  <select
                    className="form-control text-darker"
                    id="channel"
                    name="channel"
                    data-toggle="select"
                    disabled
                    value={1}
                  >
                    {
                      site.length === 0 ? (<></>) : (
                        site.map((el, idx) => (
                          <option value={el.id}  key={idx}>{el.name}</option>
                        ))
                      )
                    }
                  </select>
                </div>
                <div className="col-lg-4">
                  <label className="form-control-label textPallete">Area</label>
                  <select
                    className="form-control text-darker"
                    id="area"
                    name="area"
                    value={areaSelected}
                    data-toggle="select"
                    onChange={(e)=> handleGetArea(e.target.value)}
                  >
                    {
                      area.length === 0 ? (<></>) : (
                        area.map((el, idx) => (
                          <option value={el.id}  key={idx}>{el.name}</option>
                        ))
                      )
                    }
                  </select>
                </div>
                <div className="col-lg-4">
                  <label className="form-control-label textPallete">Device</label>
                  <select
                    className="form-control text-darker"
                    id="devices"
                    name="devices"
                    data-toggle="select"
                    value={deviceSelected}
                    onChange={(e)=> handle_get_device_and_render_data(e.target.value)}
                    >
                    {
                      device.length === 0 ? (<></>) : (
                        device.map((el, idx) => (
                          <option value={el.id}  key={idx}>{el.location_name}</option>
                        ))
                      )
                    }
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Page content */}
        <div className="container-fluid">
          <div className="row mt-3">
            <div className="col-lg-12">
              <div className="card shadowTailwind">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className=" p-3 border border-muted shadowTailwind mb-4">
                        <div  style={{display:'flex', justifyContent:'space-between'}}>
                          <div className="media " >
                            <h4 className="text-red font-weight-bold">
                              {valuePowerMeter.name} {" "}
                              <small style={{ fontSize: "14px" }}>
                                <i>{valuePowerMeter.location}</i>
                              </small>{" "}
                            </h4>
                          </div>
                          <span className={Helper(valuePowerMeter.last_update)[1] ? "text-red font-weight-bold":"text-success"}>
                              Last Update  {moment.utc(valuePowerMeter.last_update).local().format('DD/MM/YYYY HH:mm:ss')}
                          </span>
                        </div>
                        <div className="row p-2">
                          <div className="col-lg-6 p-3">
                            <div className="row bg-primaryPallete3 mr-2 powerMeterThreeLayoutCard">
                              <div className="col-lg-12 pt-2 text-center">
                                <span className="text-primary">Voltage</span>
                              </div>
                              <div className="col-lg-4">
                                <div className="bg-primaryPallete3 text-primary">
                                  <div className="card-content">
                                    <div className="card-body overflow-hidden">
                                      <div className="media">
                                        <div className="media-body text-center">
                                          <h4 className="text-primary font-weight-bold">
                                            {valuePowerMeter.voltage_r}
                                          </h4>
                                          <span>R-N</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="bg-primaryPallete3 text-primary">
                                  <div className="card-content">
                                    <div className="card-body overflow-hidden">
                                      <div className="media">
                                        <div className="media-body text-center">
                                          <h4 className="text-primary font-weight-bold">
                                            {valuePowerMeter.voltage_s}
                                          </h4>
                                          <span>S-N</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="bg-primaryPallete3 text-primary">
                                  <div className="card-content">
                                    <div className="card-body overflow-hidden">
                                      <div className="media">
                                        <div className="media-body text-center">
                                          <h4 className="text-primary font-weight-bold">
                                            {valuePowerMeter.voltage_t}
                                          </h4>
                                          <span>T-N</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 p-3">
                            <div className="row bg-primaryPallete3 powerMeterThreeLayoutCard">
                              <div className="col-lg-12 pt-2 text-center">
                                <span className="text-primary">Current</span>
                              </div>
                              <div className="col-lg-4">
                                <div className="bg-primaryPallete3 text-primary">
                                  <div className="card-content">
                                    <div className="card-body overflow-hidden">
                                      <div className="media">
                                        <div className="media-body text-center">
                                          <h4 className="text-primary font-weight-bold">
                                            {valuePowerMeter.current_r}
                                          </h4>
                                          <span>R-N</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="bg-primaryPallete3 text-primary">
                                  <div className="card-content">
                                    <div className="card-body overflow-hidden">
                                      <div className="media">
                                        <div className="media-body text-center">
                                          <h4 className="text-primary font-weight-bold">
                                            {valuePowerMeter.current_s}
                                          </h4>
                                          <span>S-N</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="bg-primaryPallete3 text-primary">
                                  <div className="card-content">
                                    <div className="card-body overflow-hidden">
                                      <div className="media">
                                        <div className="media-body text-center">
                                          <h4 className="text-primary font-weight-bold">
                                            {valuePowerMeter.current_t}
                                          </h4>
                                          <span>T-N</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-4 ">
                            <div className="col-lg-3">
                              <div className="card bg-primaryPallete3 text-primary">
                                <div className="card-content">
                                  <div className="card-body overflow-hidden">
                                    <div className="media">
                                      <div className="media-body text-center">
                                        <span>Frequency</span><br />
                                        <h4 className="text-primary font-weight-bold mt-0">
                                          {valuePowerMeter.frequency}
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="card bg-primaryPallete3 text-primary">
                                <div className="card-content">
                                  <div className="card-body overflow-hidden">
                                    <div className="media">
                                      <div className="media-body text-center">
                                        <span>Daya</span><br />
                                        <h4 className="text-primary font-weight-bold mt-0">{valuePowerMeter.power_factor}</h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="card bg-primaryPallete3 text-primary">
                                <div className="card-content">
                                  <div className="card-body overflow-hidden">
                                    <div className="media">
                                      <div className="media-body text-center">
                                        <span>KWH</span><br />
                                        <h4 className="text-primary font-weight-bold mt-0">{valuePowerMeter.active_enegy}</h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="card bg-primaryPallete3 text-primary">
                                <div className="card-content">
                                  <div className="card-body overflow-hidden">
                                    <div className="media">
                                      <div className="media-body text-center">
                                        <span>Watt</span><br />
                                        <h4 className="text-primary font-weight-bold mt-0">
                                          {valuePowerMeter.active_power}
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                         
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <h4 className="text-darker">List Data Power Meter</h4>
                      <DataTableExtensions print={false} export={false}  exportHeaders  columns={columnsListData} data={listDataTable} filter={false} >
                        <DataTable pagination  />
                      </DataTableExtensions>
                        <span className="badge badge-warning mt-3">
                        NB : Data ini akan direset 24 jam
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PowerMeter;
