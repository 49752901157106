import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import { useStopwatch } from 'react-timer-hook';
import BreadCumb from "../../components/BreadCumb";
import DataTable from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions';
import API from "../../api/base_url";
import moment from 'moment'
import Moment from 'react-moment';
import ReactSpinner from "../../components/ReactSpinner";


const token = localStorage.token;
const headers = {
  Authorization: `Bearer ${token}`,
};


const columnsDaily = [
  {
    name: 'No',
    cell: (row, index) => index + 1,
    width: "50px"
  },
  {
    name: 'Date & Time Start',
    cell: (row) => <Moment format="DD-MM-YYYY HH:mm:ss">{row.date_time_start}</Moment>,
  },
  {
    name: 'Date & Time Stop',
    cell: (row) => <Moment format="DD-MM-YYYY HH:mm:ss">{row.date_time_stop}</Moment>
  },
  {
    name: 'Date & Time Total',
    cell: (row) => row.date_time_total
  },
];


const ProductionTime = () => {
  const [dataList, setDataList] = useState([]);
  const [productionTime, setProductionTime] = useState("");
  const [productionStatus, setProductionStatus] = useState(false)
  const [isLoading, setIsLoading] = useState(true)


  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: true });

  const getData = () => {
    API.get("utility/production/time", { headers })
      .then(({ data: content }) => {
        setDataList(content.data.data_list)
        setProductionTime(content.data.production_time);
        setProductionStatus(content.data.production_status);
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  let isOverTime = false
  var now = moment().format('DD/MM/YYYY HH:mm:ss');

  const productionTimeCount = (timeFromDevice) => {
    var startFrom = moment.utc(timeFromDevice).local().format('DD/MM/YYYY HH:mm:ss');
    var ms = moment(now, "DD/MM/YYYY HH:mm:ss").diff(moment(startFrom, "DD/MM/YYYY HH:mm:ss"));
    let timeRunning = ms / 1000;
    isOverTime = timeRunning > 86400;
    var d = moment.duration(ms);
    var countUp = d.format('YY [Years]. MM [Months], DD [Days] HH [Hours] mm [Minutes] ss [Second]');
    return [countUp, isOverTime]
  }

  useEffect(() => {
    getData()
  }, [])


  return (
    <div id="wrap" >
      {/* {isLoading ? <ReactSpinner /> : */}
        <>
          <Header />
          <BreadCumb pageRoot="Dashboard" name="Production Time" parent="/dashboard" subParent="/dashboard" underPage="Utility" />

          <div className="container-fluid mt--6">
            <div className="row">
              {/* <div className="col-lg-1">
                <div className="pt-2">
                  <div className={productionStatus ? "card shadowTailwind bg-success" : "card shadowTailwind bg-danger"}>
                    <div className="card-content d-flex align-items-center" style={{ minHeight: '10vh' }}>
                      <div className="card-body">
                        <div className="media">
                          <div className="media-body text-center   p-1">
                            <span className=" font-weight-bold text-white">{productionStatus ? "ON" : "OFF"}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-lg-12 mt--4 mt-md-0">
                <div className="pt-2">
                  <div className="card shadowTailwind text-primary">
                    <div className="card-content" style={{ minHeight: '5vh' }}>
                      <div>
                        <div className="media">
                          <div className="media-body  pt-3 text-center">
                            <div>
                              <h4 className=' text-darker font-weight-bold'>
                                {productionTimeCount(productionTime)[0]}
                              </h4>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid ">
            <div className="row mt-3">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-12">
                        <h4 className=" text-darker">Log Activity </h4>
                        <DataTableExtensions print={false} export={false} exportHeaders columns={columnsDaily} data={dataList} filter={false} >
                          <DataTable pagination />
                        </DataTableExtensions>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      {/* } */}
    </div>
  );
};

export default ProductionTime;
