import React from "react";
import HeaderSearch from "../../components/HeaderSearch";
import { Link } from 'react-router-dom'
import BreadCumb from "../../components/BreadCumb";



const CanalIntake = () => {
 
  return (
    <div id="wrap">
      {/* Sidenav */}
      {/* Main content */}
      <div >
        {/* Topnav */}
        <HeaderSearch/>
        {/* ENDSIDEBAR */}
        <BreadCumb pageRoot="Early Warning System" name="Stock opname" parent="/dashboard" subParent="/dashboard" underPage="Early Warning" />
        
        {/* DEBUG-VIEW ENDED 2 APPPATH/Views/template/sidebar_v.php */}
        
        {/* Page content */}
        
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12 mt-5">
                <h4>Stoke Of Name</h4>
                <table className="table table-flush datatables">
                  <thead className="thead-light">
                    <tr>
                      <th>No</th>
                      <th>Date &amp; Time</th>
                      <th>WTP</th>
                      <th>Area</th>
                      <th>Parameter</th>
                      <th>Value</th>
                      <th>Message</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>2022-05-09 03:00</td>
                      <td>Canal Intake</td>
                      <td>Stage 1</td>
                      <td>m <sup>3</sup></td>
                      <td>50</td>
                      <td>melebihi batas normal</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* DEBUG-VIEW START 6 APPPATH/Views/template/footer_v.php */}
        {/* Footer */}
        <div className="container-fluid">
          <footer className="footer pt-0">
            <div className="row align-items-center justify-content-lg-between">
              <div className="col-lg-6">
                <div className="copyright text-center  text-lg-left  text-muted">
                  © 2022{" "}
                  <a href="#" className="font-weight-bold ml-1" target="_blank">
                    Telkomsel IoT
                  </a>
                </div>
              </div>
              <div className="col-lg-6">
                <ul className="nav nav-footer justify-content-center justify-content-lg-end">
                  <li className="nav-item">
                    <a href="#" className="nav-link" target="_blank">
                      Telkomsel IoT
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link" target="_blank">
                      About Us
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link" target="_blank">
                      License
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default CanalIntake;
