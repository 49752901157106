import React from "react";
import TankGauge from "../TankGauge";
import MeasureMeter from "../MeasureMeter";
import TankCylincerSquare from "../TankCylinderSquare";
import moment from 'moment'
import Helper from "../../utility/helper.js";

const CardElevated = ({ elevatedArea }) => {
    return (
        <div className="bg-white p-5 p-md-0  position-relative paddingTV rounded border border-muted mb-2 shadowTailwind container-fluid">
            <div style={{ marginBottom: 5, marginTop: 15, justifyContent: "flex-start" }} className="d-flex position-relative" >
                <span style={{fontSize:16}} className=" font-weight-bold fontTV">{"Elevated"}</span>
                <span style={{ fontSize: 16, position: "absolute", right: 10, top: 8 }} className=" text-black font-weight-bold ">
                    Total : 0 m<sup>3</sup>{" "}
                </span>
            </div>

            <div style={{ marginBottom: 25, marginTop: 25 }} className="row position-relative">
                {/* <div className="col-lg-12 d-flex flex-column p-4"> */}
                <div className="card-content" style={{ minHeight: '15vh' }}>
                    {elevatedArea.length !== 0 &&
                        elevatedArea.map((el, idx) => (
                            <div className="tv px-4" key={idx}>
                                <table className="table table-borderless table-sm">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="text-black">
                                                <b>{el.name}</b>
                                            </th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {/* <tr>
                                            <th scope="row">Averange</th>
                                            <th>: {el.average.toLocaleString('id-ID', { minimumFractionDigits: 2 })} m<sup>3</sup> </th>
                                        </tr> */}
                                        <tr>
                                            <th scope="row">Totalizer</th>
                                            <th>: {el.totalizer} m<sup>3</sup> </th>
                                            {/* <th>: {el.totalizer.toLocaleString('id-ID', { minimumFractionDigits: 2 })} m<sup>3</sup> </th> */}
                                        </tr>
                                        <tr>
                                            <th scope="row">Level</th>
                                            {/* <th>: 0 cm</th> */}
                                            <th>: {el.level.toLocaleString('id-ID', { minimumFractionDigits: 2 })} cm</th>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default CardElevated;
