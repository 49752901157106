import React from "react";
import { useState, useEffect } from "react";
import ChartExample from "../../components/ChartExample";
import DatePicker from "../../components/DatePicker";
import Header from "../../components/Header";
import Swal from 'sweetalert2'
import BreadCumb from "../../components/BreadCumb";
import Footer from "../../components/Footer";
import API from "../../api/base_url";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import moment from 'moment-timezone';
import ExportReservoir from "../../components/ExportExcelReservoir/ExportReservoir";
import ExportTotalReservoir from "../../components/ExportExcelReservoir/ExportTotalReservoir";
import ReactSpinner from "../../components/ReactSpinner";
import Moment from 'react-moment';



const Reservoir = () => {
  const token = localStorage.token;
  const headers = {
    Authorization: `Bearer ${token}`,
};

const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  background:"#c51a1a",
  color:"#ffffff",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

let start =  moment().startOf('day').utc().format("YYYY-MM-DD HH:mm:ss")
let end = moment().endOf('day').utc().format("YYYY-MM-DD HH:mm:ss")

const columnsListDataReservoir = [
  {
    name: "No",
    cell: (row, index) => index + 1,
    width: "50px",
  },
  {
    name: "Reservoir Name",
    cell: (row) => row.name,
  },
  {
    name: "Data & Time",
    cell: (row) => <Moment format="DD-MM-YYYY HH:mm:ss">{row.recorded_at}</Moment>,
  },
  // {
  //   name: "Device Parameter",
  //   cell: (row) => row.device_parameter
  // },
  {
    name: "Level",
    cell: (row) => `${row.level} cm`,
  },
  {
    name: "Volume",
    cell: (row) => `${row.volume} m3`,
  },
];

const columnsListTotalDataReservoir = [
  {
    name: "No",
    cell: (row, index) => index + 1,
    width: "50px",
  },
  {
    name: "Tank A",
    cell: (row) => `${row.volume_tank_a} m3`,
  },
  {
    name: "Tank B",
    cell: (row) => `${row.volume_tank_b} m3`,
  },
  {
    name: "Tank C",
    cell: (row) => `${row.volume_tank_c} m3`,
  },
  {
    name: "Tank D",
    cell: (row) => `${row.volume_tank_d} m3`,
  },
  {
    name: "Total",
    cell: (row) => `${row.volume_total} m3`,
  },
  {
    name: "Data & Time",
    cell: (row) => <Moment format="DD-MM-YYYY HH:mm:ss">{row.recorded_at}</Moment>,
  },
];

  const [site, setSite] = useState([]);
  const [area, setArea] = useState([]);
  const [location, setLocation] = useState([]);
  const [dateRangePickerValue, setDateRangePickerValue] = useState(`${start}/${end}`);
  const [dateRangePickerDisplay, setDateRangePickerDisplay] = useState(`${start}/${end}`);
  const [pending, setPending] = useState(true);
  const [isLoading, setIsLoading] = useState(true)

  // above is tmp for each filter
  
  const [locationSelected, setLocationSelected] = useState("");
  const [selectedLocationValue, setSelectedLocationValue] = useState([]);

  // data 
  const [data, setData] = useState([])
  const [totalData, setTotalData] = useState([])
  const [chartTankA, setChartTankA] = useState([])
  const [chartTankB, setChartTankB] = useState([])
  const [chartTankC, setChartTankC] = useState([])
  const [chartTankD, setChartTankD] = useState([])


  const getSite = () => {
    API.get("utility/master/data/site", { headers })
      .then(({ data: content }) => {
        setSite(content.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getArea = () => {
    API.get("utility/master/data/area/1", { headers })
      .then(({ data: content }) => {
        setArea(content.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLocation = () => {
    API.get(`utility/master/data/location/3`, { headers })
      .then(({ data: content }) => {
        setLocation(content.data);
        setSelectedLocationValue(content.data[0].name);
        setLocationSelected(content.data[0].id);
        getDevice(content.data[0].id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDevice = (id) => {
    API.get(`utility/master/data/device/${id}`, { headers })
      .then(({ data: content }) => {
        if (content.data.length !== 0) {
          setSelectedLocationValue(content.data[0].location_name);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }; 

  const getListData = () => {
    API.get(`report/reservoir/${dateRangePickerValue}`, { headers })
    .then(({ data: content }) => {
       setData(content.data.list_data)
       setTotalData(content.data.list_total)

       let tankATmps = []
       let tankBTmps = []
       let tankCTmps = []
       let tankDTmps = []

       content.data.tank_a.report_device_value_trend.forEach(el => {
        tankATmps.push({x:moment.utc(el.report_device_value_trend_time).local().format('DD/MM/YYYY HH:mm:ss'), y:el.report_device_value_trend_value})
       });

       content.data.tank_b.report_device_value_trend.forEach(el => {
        tankBTmps.push({x:moment.utc(el.report_device_value_trend_time).local().format('DD/MM/YYYY HH:mm:ss'), y:el.report_device_value_trend_value})
       });

       content.data.tank_c.report_device_value_trend.forEach(el => {
        tankCTmps.push({x:moment.utc(el.report_device_value_trend_time).local().format('DD/MM/YYYY HH:mm:ss'), y:el.report_device_value_trend_value})
       });

       content.data.tank_d.report_device_value_trend.forEach(el => {
        tankDTmps.push({x:moment.utc(el.report_device_value_trend_time).local().format('DD/MM/YYYY HH:mm:ss'), y:el.report_device_value_trend_value})
       });

       setChartTankA(tankATmps)
       setChartTankB(tankBTmps)
       setChartTankC(tankCTmps)
       setChartTankD(tankDTmps)

       setPending(false)
       setIsLoading(false)
      })
      .catch((err) => {
        console.log(err);
      });
   }

  const handleFilterSubmit = () => {
    setData([])
    setChartTankA([])
    setChartTankB([])
    setChartTankC([])
    setChartTankD([])
    getListData()
    Toast.fire({
      icon: "success",
      title: "Filter Data Request",
    });
   }
  
   const setDateRangePickerValueFunc = (val) => {
    setDateRangePickerValue(val)
   }

   const setDateRangePickerValueExcelFunc = (val) => {
    setDateRangePickerDisplay(val)
   }

  useEffect(() => {
    getSite();
    getArea();
    getLocation();
    getListData();
  }, []);

  return (
    <div id="wrap">
      {/* {isLoading ? <ReactSpinner/>: */}
        <div>
          <Header />
          <BreadCumb pageRoot="Report & Analisis" name="Reservoir" parent="/dashboard" subParent="/dashboard" underPage="Report Analisis" />
          <div className="container-fluid mt--6">
            <div className="card shadowTailwind">
              <div className="card-body">
                <div className="row">
                <div className="col-lg-3">
                    <label className="form-control-label textPallete">Site</label>
                    <select
                      className="form-control text-darker"
                      id="channel"
                      name="channel"
                      data-toggle="select"
                      disabled
                      value={1}
                    >
                      {site.length === 0 ? (
                        <></>
                      ) : (
                        site.map((el, idx) => (
                          <option value={el.id}  key={idx}>
                            {el.name}
                          </option>
                        ))
                      )}
                    </select>
                  </div>
                  <div className="col-lg-3">
                    <label className="form-control-label textPallete">Area</label>
                    <select
                      className="form-control text-darker"
                      disabled
                      id="devices"
                      name="devices"
                      value={3}
                      data-toggle="select"
                    >
                      {area.length === 0 ? (
                        <></>
                      ) : (
                        area.map((el, idx) => (
                          <option value={el.id}  key={idx}>
                            {el.name}
                          </option>
                        ))
                      )}
                    </select>
                  </div>
                  <div className="col-lg-3">
                    <label className="form-control-label textPallete">
                      Location
                    </label>
                    <select
                      className="form-control text-darker"
                      id="devices"
                      name="devices"
                      data-toggle="select"
                      disabled
                      value={locationSelected}
                    >
                      {location.length === 0 ? (
                        <></>
                      ) : (
                        location.map((el, idx) => (
                          <option value={el.id} key={idx}>
                            {el.name}
                          </option>
                        ))
                      )}
                    </select>
                  </div>
                <div className="col-lg-2">
                  <label className="form-control-label textPallete">Date Range</label>
                  <DatePicker take={(val) => setDateRangePickerValueFunc(val)} takeTimeExcel={(val) => setDateRangePickerValueExcelFunc(val)} />
                </div>
                <div className="col-lg-1 p-1">
                  <label className="form-control-label textPallete">&nbsp;</label> <br />
                  <button className="btn bg-pallete text-white" onClick={() => handleFilterSubmit()}>
                    <span className="fa fa-search mr-2" /> Filter
                  </button>
                </div>
                </div>
              </div>
            </div>
          </div>
          {/* filter */}
          {/* <div className="container-fluid mt--3">
            <div className="card shadowTailwind">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-8">
                    <label className="form-control-label textPallete">Date Range</label>
                    <DatePicker take={(val)=> setDateRangePickerValueFunc(val)} takeTimeExcel={(val) => setDateRangePickerValueExcelFunc(val)} />
                  </div>
                  <div className="col-lg-4">
                    <label className="form-control-label textPallete">&nbsp;</label> <br />
                    <button className="btn bg-pallete text-white" onClick={()=> handleFilterSubmit()}>
                      <span className="fa fa-search mr-2" /> Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* Page content */}
          <div className="container-fluid">
      
            <div className="row mt-3">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="mb-0 text-red">Resevoir</h4>
                  </div>

                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="card card-stats">
                            <div className="card-body">
                              <div className="row">
                                <div className="col text-center">
                                  <h5 className="card-title text-uppercase text-muted mb-0">
                                    Resevoir A
                                  </h5>
                                </div>
                              </div>
                              <div className="mt-3 mb-0 text-sm text-center">
                                <ChartExample streaming={chartTankA}/>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="card card-stats">
                            <div className="card-body">
                              <div className="row">
                                <div className="col text-center">
                                  <h5 className="card-title text-uppercase text-muted mb-0">
                                    Resevoir B
                                  </h5>
                                </div>
                              </div>
                              <div className="mt-3 mb-0 text-sm text-center">
                                <ChartExample streaming={chartTankB} />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="card card-stats">
                            <div className="card-body">
                              <div className="row">
                                <div className="col text-center">
                                  <h5 className="card-title text-uppercase text-muted mb-0">
                                    Volume C
                                  </h5>
                                </div>
                              </div>
                              <div className="mt-3 mb-0 text-sm text-center">
                                <ChartExample streaming={chartTankC}/>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="card card-stats">
                            <div className="card-body">
                              <div className="row">
                                <div className="col text-center">
                                  <h5 className="card-title text-uppercase text-muted mb-0">
                                    Volume D
                                  </h5>
                                </div>
                              </div>
                              <div className="mt-3 mb-0 text-sm text-center">
                                <ChartExample streaming={chartTankD} />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                        <div className="d-flex justify-content-between">
                          <h4 className="text-darker">
                              List Data {selectedLocationValue}
                          </h4>
                          <ExportReservoir time={dateRangePickerDisplay} location={selectedLocationValue} data={data}/>
                        </div>
                        <DataTableExtensions
                          print={false}
                          export={false}
                          exportHeaders
                          columns={columnsListDataReservoir}
                          data={data}
                          filter={false}
                        >
                          <DataTable pagination progressPending={pending} />
                        </DataTableExtensions>
                        </div>

                        {/* table 2 */}
                        <div className="col-lg-6">
                        <div className="d-flex justify-content-between">
                          <h4 className="text-darker">
                              Total Data {selectedLocationValue}
                          </h4>
                          <ExportTotalReservoir time={dateRangePickerDisplay} location={selectedLocationValue} data={totalData}/>
                        </div>
                        <DataTableExtensions
                          print={false}
                          export={false}
                          exportHeaders
                          columns={columnsListTotalDataReservoir}
                          data={totalData}
                          filter={false}
                        >
                          <DataTable pagination progressPending={pending} />
                        </DataTableExtensions>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      {/* } */}
    </div>
  );
};

export default Reservoir;
