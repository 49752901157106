import React, { useEffect, useState } from "react";
import API from "../api/base_url";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom"
import Footer from "../components/Footer";


const MyProfile = () => {
  const history = useNavigate();
  const [name, setName] = useState("")
  const [nameAPI, setNameAPI] = useState("")
  const [roleName, setRoleName] = useState(null)
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [photo, setPhoto] = useState("")
  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [profilePicture, setProfilePicture] = useState(null)
  const [fileName, setFileName] = useState('Choose File')
  const [blobImage, setBlobImage] = useState() // <= add

  let token = localStorage.token;
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const getData = () => {
    API.get("dashboard/account/profile", { headers })
    .then(({ data:content }) => {
      setNameAPI(content.data.name)
      setName(content.data.name)
      setRoleName(content.data.role_name)
      setEmail(content.data.email)
      setPhone(content.data.phone)
      setPhoto(content.data.photo)
    })
    .catch((err) => {
      console.log(err);
    });
  }

 


  const handleSubmitUpdate = () => {
    Swal.fire({
      title: 'Do you want to Update this Account?',
      showCancelButton: true,
      confirmButtonText: 'Update',
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          name,
          email,
          phone
        };
        if ( !name || !email || !phone) {
          Swal.fire('Data is Required, please fill out the form', '', 'error')
        } else {
          API.put(`/dashboard/account/profile`, payload, { headers })
            .then(({ data }) => {
              getData()
              Swal.fire('Information updated successfully', '', 'success')
            })
            .catch((err) => {
              getData()
              Swal.fire('error from server', '', 'error')
            });
        }
      }
    })
  }

  const handleChangePassword = () => {
    Swal.fire({
      title: 'Do you want to Update Password?',
      showCancelButton: true,
      confirmButtonText: 'Update',
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          password_old: oldPassword,
          password_new: newPassword
        };
        if (!payload.password_old || !payload.password_new) {
          Swal.fire('Data is Required, please fill out the form', '', 'error')
        } else {
          API.patch(`dashboard/account/changepassword`, payload, { headers })
            .then(({ data }) => {
              if (data.message === 'SUCCESS') {
                Swal.fire('Password updated successfully', '', 'success')
                setOldPassword("")
                setNewPassword("")
                localStorage.removeItem('token')
                history('/');
              } else {
                setOldPassword("")
                setNewPassword("")
                Swal.fire(data.message, 'We are sorry, it looks like you entered your old password incorrectly', 'error')
              }
            })
            .catch((err) => {
              setOldPassword("")
              setNewPassword("")
              Swal.fire('error from server', '', 'error')
            });
        }
      }
    })
  }

  const handleSetProfilePicture = (e) => {
    setProfilePicture(e.target.files[0])
    setFileName(e.target.files[0].name)
    setBlobImage(URL.createObjectURL(e.target.files[0])) // <= add
  }

  const handleUpdateProfile = async (e) => {
    e.preventDefault()

    let formData = new FormData();

    formData.append('photo', profilePicture);
    formData.append('name', profilePicture.name);

  

    const config = {     
      headers: { 
        'content-type': 'multipart/form-data',
         Authorization:  `Bearer ${token}`
      }
    }

    const payload = {
      photo: formData
    }

    let photo = formData


    
    Swal.fire({
      title: 'Do you want to Update Photo?',
      showCancelButton: true,
      confirmButtonText: 'Update',
    }).then((result) => {
     
      if (result.isConfirmed) {
        if (!profilePicture) {
          Swal.fire('Data is Required, please fill out the form', '', 'error')
        } else {
          API.put(`dashboard/account/profile/photo`, photo, config )
          .then(({ data }) => {
            Swal.fire('Profile Picture has updated', 'success')
            setProfilePicture(null)
          })
          .catch((err) => {
            setProfilePicture(null)
            Swal.fire(JSON.stringify(err), 'error from server',  'error')
          });
        }
      }
    })
  }

  useEffect(() => {
    getData()
  }, []);


  return (
    <div>
      {/* Main content */}
      <div className="main-content" id="panel">
        <div
          className="header pb-6 d-flex align-items-center"
          style={{
            minHeight: 350,
            backgroundImage: "url(../../assets/img/theme/factory.png)",
            backgroundSize: "cover",
            backgroundPosition: '50% 50%'
          }}
        >
          {/* Mask */}
          <span className="mask bg-gradient-default opacity-4" />
          {/* Header container */}
          <div className="container-fluid d-flex align-items-center">
            <div className="row">
              <div className="col-lg-7 col-md-10">
                <h1 className="display-2 text-white">Hallo , {nameAPI}</h1>
                <p className="text-white mt-0 mb-5">
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Page content */}
        <div className="container-fluid mt--6">
          <div className="row">
            <div className="col-xl-4 order-xl-2">
              <div className="card card-profile">
                <img
                  src="../../assets/img/theme/factory.png"
                  alt="Image placeholder"
                  className="card-img-top"
                />
                <div className="row justify-content-center">
                  <div className="col-lg-3 order-lg-2">
                    <div className="card-profile-image">
                      <button 
                      id="btn_add"
                      type="button"
                      data-toggle="modal"
                      data-target="#exampleModalUpdatePhoto"
                      data-action="tambah"
                      >
                        <img
                          src={photo}
                          className="rounded-circle"
                          style={{height:150, width:150}}
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"></div>
                <div className="card-body pt-0">
                  <div className="text-center mt-5">
                    <h5 className="h3">
                      {roleName||"Unknow"}
                    </h5>
                    <div className="h5 font-weight-300">
                      <i className="ni location_pin mr-2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8 order-xl-1">
              <div className="card">
                <div className="card-header">
                  <div className="row align-items-center">
                    <div className="col-8">
                      <h3 className="mb-0">Edit profile </h3>
                    </div>
                    <div className="col-4 text-right">
                      <button 
                      className="btn btn-primary btn-sm"
                      id="btn_add"
                      type="button"
                      data-toggle="modal"
                      data-target="#exampleModalUpdate"
                      data-action="tambah"
                      >
                        Ubah Password
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                    <h6 className="heading-small text-muted mb-4">
                      User information
                    </h6>
                    <div className="pl-lg-4">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Username
                            </label>
                            <input
                              type="text"
                              id="input-username"
                              className="form-control"
                              value={name}
                              onChange={(e)=> setName(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Email address
                            </label>
                            <input
                              type="email"
                              id="input-email"
                              className="form-control"
                              value={email}
                              onChange={(e)=> setEmail(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Phone
                            </label>
                            <input
                              type="text"
                              id="input-first-name"
                              className="form-control"
                              value={phone}
                              onChange={(e)=> setPhone(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <button 
                      onClick={()=> handleSubmitUpdate()}
                      className="btn btn-primary w-100 mt-5">UPDATE MY DATA</button>
                    </div>
                </div>
              </div>
            </div>


            {/* modal for change password */}
            <div className="modal fade" id="exampleModalUpdate"  aria-hidden="true" >
              <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-lg-12">
                          <h3 className="modal-title mb-4" id="exampleModalLabel">
                            Perubahan Password
                          </h3>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form-control-label">Password Lama</label>
                            <input
                              type="text"
                              className="form-control input-sm"
                              value={oldPassword}
                              onChange={(e)=> setOldPassword(e.target.value)}
                            />
                          </div>
                          <div className="form-group">
                            <label className="form-control-label">Password Baru</label>
                            <input
                              type="text"
                              className="form-control input-sm"
                              value={newPassword}
                              onChange={(e)=> setNewPassword(e.target.value)}
                            />
                          </div>
                          <p className="text-danger font-italic font-weight-bold">Note: Setelah berhasil merubah password anda akan diarahkan ke login untuk memulai dengan credential baru</p>
                        </div>
                      </div>
                      <button 
                        className="btn btn-success mt-5"
                          type="button"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={()=> handleChangePassword()}
                        >
                          CHANGE PASSWORD
                      </button>
                      <button
                        className="btn btn-primary mt-5"
                        type="button"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        Cancel
                      </button>
                    </div>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div id="dynamicForm" />
                  </div>
                </div>
              </div>
            </div>

            {/* modal for change photo */}
            <div className="modal fade" id="exampleModalUpdatePhoto"  aria-hidden="true" >
              <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-lg-12">
                          <h3 className="modal-title mb-4" id="exampleModalLabel">
                            Perubahan Ava
                          </h3>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form-control-label">Foto Baru</label>
                            <input
                              type="file"
                              onChange={handleSetProfilePicture}
                              className="form-control input-sm"
                            />
                          </div>
                        </div>
                      </div>
                      <button 
                      className="btn btn-success mt-5"
                        type="button"
                        data-dismiss="modal"
                        aria-label="Close"
                        value={profilePicture}
                        onClick={handleUpdateProfile}
                      >
                        CHANGE
                      </button>
                      <button
                        className="btn btn-primary mt-5"
                        type="button"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        Cancel
                      </button>
                    </div>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div id="dynamicForm" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Footer */}
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
