import React from "react";
import TankCylincerSquare from "./TankCylinderSquare";
import moment from 'moment'
import Helper from "../../utility/helper.js"

const CardReservoir = ({ reservoirArea }) => {
  return (
    <div className="bg-white  p-5 p-md-0 paddingTV shadowTailwind  rounded  border border-muted container-fluid ">
      <div className="row ">
        <div className="col-lg-12 ">
          <div style={{ marginTop: 0 }} className="d-flex justify-content-between">
            {/* <span style={{fontSize:16}}  className=" text-red font-weight-bold ">
              Reservoir
            </span> */}
            <span style={{ fontSize: 16, position: "absolute", right: 0 }} className=" text-black font-weight-bold ">
              Balance : {reservoirArea.balance} m<sup>3</sup>{" "}
            </span>
          </div>

          <div className="row no-gutter">
            <div className="col-lg-6 py-2">
              <div className="row d-flex  flex-lg-row flex-wrap">
                {reservoirArea.length !== 0 &&
                  reservoirArea.tank.map((el, idx) => (
                    <div className="col-6 mt-4 mb-md-0" key={idx}>
                      <div
                        className="position-relative tvTank"
                        style={{ height: "14vh" }}
                      >
                        <TankCylincerSquare percentage={el.percentage} name={el.name} />
                        <div className="position-absolute top-0 right-0  d-none d-md-block">
                          <span className="font-weight-bold fontTV">
                            <small>
                              MAX{" "}
                              <span
                                style={{ display: "block", lineHeight: "1px" }}
                              >
                                {el.level_max}
                              </span>{" "}
                            </small>
                          </span>
                        </div>
                        <div className="position-absolute bottom-0 right-0  d-none d-md-block">
                          <span className="font-weight-bold fontTV">
                            <small>MIN</small>
                          </span>
                        </div>
                        {/* mobile */}
                        <div className="position-absolute top-0 right-6  d-block d-md-none">
                          <span className="font-weight-bold fontTV">
                            <small>
                              MAX{" "}
                              <span
                                style={{ display: "block", lineHeight: "1px" }}
                              >
                                {el.level_max}
                              </span>{" "}
                            </small>
                          </span>
                        </div>
                        <div className="position-absolute bottom-0 right-6  d-block d-md-none">
                          <span className="font-weight-bold fontTV">
                            <small>MIN</small>
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}

              </div>
            </div>
            <div className="col-lg-6">
              {reservoirArea.length !== 0 &&
                reservoirArea.tank.map((el, idx) => (
                  <div style={{ marginTop: -1, marginBottom: -1 }} key={idx}>
                    <table className="table table-borderless table-sm my-0">
                      <thead>
                        <tr>
                          <th scope="col" className="text-black">
                            <b>{el.name}</b>
                          </th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">Level</th>
                          <th>: {el.level.toLocaleString('id-ID', { minimumFractionDigits: 2 })} cm</th>
                        
                          <th scope="row">Volume</th>
                          <th>
                            : {el.volume.toLocaleString('id-ID', { minimumFractionDigits: 2 })} m<sup>3</sup>{" "}
                          </th>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardReservoir;
