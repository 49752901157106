import React, { useEffect, useState } from "react";
import API from "../../api/base_url";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import FaqReadOnly from "../../components/FaqReadOnly";


const Faq = () => {
  const [data, setData] = useState([])

  let token = localStorage.token;
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    API.get("dashboard/master/faqs", { headers })
      .then(({ data:content }) => {
        setData(content.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }, [])
  


  return (
    <div id="wrap">
      <div className="main-content" id="panel">
        <Header />
        {/* Page content */}
        <div className="bg-primary" style={{ minHeight: 200 }}>
          <div className="container-fluid">
            <h2 className="text-white">FAQ</h2>
          </div>
        </div>
        <div className="container-fluid mt--9">
   
          <div className="row mt-3">
            <div className="col-lg-12">
              <div className="card" style={{ minHeight: '60vh' }} >
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-12">
                      <p className="customeText text-center text-red py-5">
                        Daftar Pertanyaan
                      </p>
                      {
                        data.length === 0 ? (<></>) : (
                          data.map((el, idx) => (
                            <div key={idx}>
                              <FaqReadOnly question={el.question} answer={el.answer} unique={idx}/>
                            </div>
                          ))
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
