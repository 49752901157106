import React from "react";
import TankCylincerSquare from "../TankCylinderSquare";
import Helper from "../../utility/helper.js"
import moment from 'moment'

const CardTank = ({ tank, name, balance }) => {
    return (
        <div className="bg-white px-4 p-md-0  paddingTV rounded border border-muted mb-2 shadowTailwind container-fluid">
            <div style={{ marginTop: 12, marginBottom: 4 ,paddingBottom:4}} className="d-flex justify-content-between">
                <span style={{ fontSize: 16, marginy: 4 }} className=" text-black fontTV font-weight-bold">{name}</span>
                <span style={{ fontSize: 16, marginY: 4 }} className=" text-black fontTV font-weight-bold text-right">
                    {/* Total : 0 Kg */}
                    Total : {balance.toLocaleString('id-ID', { minimumFractionDigits: 2 })} Kg
                </span>
            </div>

            <div style={{ marginBottom: 24, marginTop: 25 }} className="row">
                {/* <div className="col-lg-4 d-lg-flex px-4 py-4"> */}
                <div className="col-lg-4 d-lg-flex py-1" style={{ minHeight: '10vh' }}>
                    {tank.length !== 0 &&
                        tank.map((el, idx) => (
                            <div className="tv px-4 py-4" key={idx}>
                                <table className="  table table-borderless table-sm">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="text-black">
                                                <b>{el.name}</b>
                                            </th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* <tr>
                                            <th scope="row">Averange</th>
                                            <th>: {el.average} Kg</th>
                                        </tr> */}
                                        <tr>
                                            <th scope="row">Level</th>
                                            {/* <th>: 0 cm</th> */}
                                            <th>: {el.level.toLocaleString('id-ID', { minimumFractionDigits: 2 })} cm</th>
                                        </tr>
                                        <tr>
                                            <th scope="row">Weight</th>
                                            {/* <th>: 0 Kg</th> */}
                                            <th>: {el.weight.toLocaleString('id-ID', { minimumFractionDigits: 2 })} Kg</th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default CardTank;
