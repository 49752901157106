const token = localStorage.token;


const myToken = () => {
    const headers = {
        Authorization: `Bearer ${token}`,
        };

        return headers
}

export default myToken;