import React, { useEffect, useState } from "react";
import CardTank from "../components/CardTV_Components/CardTank";
import CardLTE from "../components/CardTV_Components/CardLTE";
import CardReservoir from "../components/CardTV_Components/CardReservoir";
import CardElevated from "../components/CardTV_Components/CardElevated";
import API from "../api/base_url";
import moment from 'moment'
import HeaderDashboard from "../components/CardTV_Components/HeaderDashboard";
import { useStopwatch } from 'react-timer-hook';
import ReactSpinner from "../components/ReactSpinner";



const DashboardTv = () => {
  // Card Info
  const [powerMeter, setPowerMeter] = useState([]);
  const [productionTime, setProductionTime] = useState("00:00:00");
  const [productionStatus, setProductionStatus] = useState(false)
  const [intakeArea, setIntakeArea] = useState(0);
  const [drainRinse, setDrainRinse] = useState(0);
  const [overflow, setoverFlow] = useState(0);
  const [backwashArea, setBackWashArea] = useState(0);
  const [clearatorArea, setClearatorArea] = useState(0);
  //  Interface Info
  const [intake, setIntake] = useState();
  const [chemicalArea, setChemicalArea] = useState([]);
  const [reservoirArea, setReservoirArea] = useState([]);
  const [elevatedArea, setElevatedArea] = useState([]);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true)
  const [powerStageSatu, setpowerStageSatu] = useState([]);
  const [powerStageDua, setpowerStageDua] = useState([]);
  const [lastUpdate, setlastUpdate] = useState("00:00:00");




  let token = localStorage.token;
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const getData = () => {
    API.get("/utility/dashboard-real", { headers })
      .then(({ data: content }) => {
        setProductionTime(content.data.production_time);
        setProductionStatus(content.data.production_status);
        setlastUpdate(content.data.last_update)
        setIntakeArea(content.data.intake_area);
        setBackWashArea(content.data.backwash_area);
        setDrainRinse(content.data.rinse_drain_area);
        setClearatorArea(content.data.clearator_area);
        setoverFlow(content.data.overflow);
        // for card'
        const findStageSatu = content.data.power_meter_area.find(item => item.name === 'Stage 1 Power Meter 3 Phase')
        setpowerStageSatu(findStageSatu)
        const findStageDua = content.data.power_meter_area.find(item => item.name === 'Stage 2 Power Meter 3 Phase')
        setpowerStageDua(findStageDua)
        const findIntake = content.data.power_meter_area.find(item => item.location === 'Intake')
        setIntake(findIntake)
        setPowerMeter(content.data.power_meter_area);
        setChemicalArea(content.data.chemical_area);
        setReservoirArea(content.data.reservoir_area)
        setElevatedArea(content.data.elevated_area)
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData()
  }, [])


  useEffect(() => {
    const interval = setInterval(() => {
      getData()
      setSuccess(true)
      setTimeout(() => { setSuccess(false) }, 1000)
    }, 5000);


    return () => {
      clearInterval(interval)
    }
  }, [powerMeter])


  let timeStarted = new Date(lastUpdate);
  let getSecond = Math.floor(timeStarted.getTime() / 1000)


  let stopwatchOffset = new Date(); stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + 600);

  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: true, offsetTimestamp: stopwatchOffset });


  let isOverTime = false
  var now = moment().format("DD/MM/YYYY HH:mm:ss");

  const productionTimeCount = (timeFromDevice) => {
    var startFrom = moment.utc(timeFromDevice).local().format("DD/MM/YYYY HH:mm:ss");
    var ms = moment(now, "DD/MM/YYYY HH:mm:ss").diff(moment(startFrom, "DD/MM/YYYY HH:mm:ss"));
    let timeRunning = ms / 1000;
    isOverTime = timeRunning > 86400;
    var d = moment.duration(ms);
    var countUp = d.format("YY [years]. MM [months], DD [days] HH [hours] mm [minutes] ss [second]");
    return [countUp, isOverTime]
  }

  return (
    <div id="wrap" style={{ minHeight: '100vh' }}>
      {/* {isLoading ? <ReactSpinner /> : */}
      <div className="main-content" id="panel">
        <HeaderDashboard success={success} />
        <div>
          <div className="container-fluid m-10" style={{ minHeight: '50%' }}>
            <CardLTE
              powerMeter={powerMeter}
              productionStatus={productionStatus}
              productionTime={productionTime}
              IntakeArea={intakeArea}
              drainRinse={drainRinse}
              overflow={overflow}
              backwashArea={backwashArea}
              clearatorArea={clearatorArea}
            />
          </div>
        </div>
        <div className="container-fluid mt-2 mt-md--0">
          <div className="row no-gutter">
            <div className="col-lg-12">
              <div className=" " >
                <div>
                  <div>
                    <div className="row ">
                      <div className="col-lg-6  ">
                        <div class="row">
                          <div class="col-6">                      
                        <h4 className="container jarakjudul text-left ">
                          Chemical Area
                        </h4>
                          </div>
                            <div class="col-6 d-flex flex-lg-row  justify-content-end py-2">
                              <div class="dot p-1  bg-green mx-2">
                                
                              </div>
                            </div>
                        </div>
                        {
                          chemicalArea.length === 0 ? (
                            <p>No Data</p>
                          ) : (
                            chemicalArea.map((el, idx) => (
                              <div key={idx}>
                                <CardTank
                                  name={el.chemical}
                                  balance={el.balance}
                                  tank={el.tank}
                                />
                              </div>
                            ))
                          )
                        }
                      </div>
                      {/* dummy */}
                      <div className='col-lg-6 '>
                        <div className="row ">
                          {/* WTP stage 1 */}
                          <div className="col-lg-6 my-0">
                            <div>
                              {powerStageSatu ? (
                                <div className="bg-white shadowTailwind text-primary">
                                  <div className="card-content pb-2" style={{ minHeight: '1vh' }}>
                                    <div>
                                      <div className="media">
                                        <div className="media-body px-2 pt-4 text-center" style={{ marginBottom: 0, marginTop: -10 }}>
                                          <span className="text-black fontTV font-weight-bold">
                                            {powerStageSatu.name}
                                          </span>
                                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span >
                                              {powerStageSatu.kwh} kwh
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : <p>No Data</p>
                              }
                            </div>

                          </div>
                          {/* Wtp Stage 2 */}
                          <div className="col-lg-6 my-0">
                            <div >
                              {powerStageDua ? (
                                <div className="bg-white shadowTailwind text-primary">
                                  <div className="card-content pb-2" style={{ minHeight: '1vh'  }}>
                                    <div>
                                      <div className="media">
                                        <div className="media-body px-2 pt-4 text-center" style={{ marginBottom: 0, marginTop: -10 }}>
                                          <span className="text-black fontTV font-weight-bold">
                                            {powerStageDua.name}
                                          </span>
                                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span >
                                              {powerStageDua.kwh} kwh
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : <p>No Data</p>
                              }
                            </div>

                          </div>
                          {/* Intake */}
                          <div className="col-lg-6 mt-2">
                            <div >
                              {
                                intake ? (
                                  <div className="bg-white  shadowTailwind text-primary">
                                    <div className="card-content pb-2 " style={{ minHeight: '1vh' }}>
                                      <div>
                                        <div className="media">
                                          <div className="media-body px-2 pt-1 text-center">
                                            <span className="text-black fontTV font-weight-bold"style={{ marginBottom: 0, marginTop: -10 }}>
                                              Powermeter {intake.location}
                                            </span>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                              <span >
                                                {intake.kwh} kwh
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (<p>Data Kosong</p>)}
                            </div>
                          </div>
                          {/* Intake */}

                          <div className="col-lg-6 mt-2">
                            <div >
                              
                                
                                  <div className="bg-white  shadowTailwind text-primary">
                                    <div className="card-content pb-2" style={{ minHeight: '1vh' }}>
                                      <div>
                                        <div className="media">
                                          <div className="media-body px-2 text-center" style={{ marginBottom: 0, marginTop: -10, paddingTop: 15 }}>
                                            <span className="text-black fontTV font-weight-bold">
                                             Last Update
                                            </span>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                                                                    <span className="fontTV">{moment.utc(lastUpdate).local().format('DD/MM/YYYY HH:mm:ss')}</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                {/* ) : (<p>Data Kosong</p>)} */}
                            </div>
                          </div>
                        </div>
                        <div className="cola no-padding  ">
                          <div class="row">
                            <div class="col-6 py-2">
                          <h4 className="container jarakjudul  text-left ">Reservoir Area</h4>
                            </div>
    
                               <div class="col-6 d-flex flex-lg-row  justify-content-end py-3">
                              <div class="dot p-1  bg-green mx-2">
                                
                              </div>
                              <div class="dot p-1 bg-red" >
                                
                              </div>
                            </div>
                          </div>
                          <CardReservoir reservoirArea={reservoirArea} powerMeterArea={powerMeter} />
                        </div>
                        <div className="cola no-padding ">
                          <div class="row py-2">
                            <div class="col-6">
                          <h4 className="container jarakjudul text-left">Elevated Area</h4>
                            </div>
                              <div class="col-6 d-flex flex-lg-row  justify-content-end py-3">
                              <div class="dot p-1  bg-green mx-2">
                              </div>
                              <div class="dot p-1  bg-green mx-2">
                              </div>
                              <div class="dot p-1 bg-red mx-2" >
                              </div>
                            </div>
                          </div>
                          <CardElevated elevatedArea={elevatedArea} powerMeterArea={powerMeter} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* } */}
    </div>
  );
};

export default DashboardTv;
